import { Timestamp } from '@firebase/firestore';

export interface AlgoliaSearchResponse {
  hits: any[];
  hitsPerPage: number;
  nbHits: number;
  nbPages: number;
  page: number;
  lastName: string;
  query: string;
}
export interface Member {
  idNumber: string;
  firstName: string;
  lastName: string;
  memberTypeId: string;
  status: string;
  waitingDate: any;
}

export interface PolicyHit {
  objectID: string;
  policyNumber: string;
  members: Member[];
  planId: string;
  status: string;
  payAtNumber: string;
}

export interface MemberPolicySearch {
  policyId: string;
  policyNumber?: string;
  idNumber?: string;
  firstName?: string;
  lastName?: string;
  plan?: string;
  memberTypeId?: string;
  planStatus?: string;
  memberStatus?: string;
  waitingDate?: any;
  payAtNumber?: string;
  exclude?: boolean;
}

export interface LogHit {
  policyId: string;
  events: Events[];
}

export interface Events {
  type: String;
  createdBy: string;
  createdOn: Timestamp;
  referenceNumber: string;
  event: string;
}

export interface LogSearch {
  type: String;
  createdBy: string;
  createdOn: Timestamp | null;
  referenceNumber: string;
  policyId: string;
}

export interface UsersHit {
  displayName: string;
  email: string;
  status: string;
  roleId: string;
}

export enum MemberStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CLAIMED = 'CLAIMED',
  REQUESTED = 'REQUESTED',
}
