import { Timestamp } from '@firebase/firestore';
import { FileData } from './file.model';
import { TransactionStatus } from './transaction.model';
import { CreatedBy, UpdatedBy } from './general.model';
export interface Policy {
  id?: string;
  planId?: string;
  policyNumber?: string;
  status?: string;
  inceptionDate?: Timestamp;
  comments?: Comment[];
  members?: Member[];
  memberIdNumbers?: string[];
  addOns?: PolicyAddOn[];
  files?: FileData[];
  hasRequest?: boolean;
  productsPaymentStatus?: ProductPaymentStatus[];
  intendedPaymentDay?: number;
  unallocatedBalance?: number;
  transactionCount?: {
    [key in TransactionStatus]?: number;
  };
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
  payAtNumber?: string;
  debitOrder?: DebitOrder;
}

export type PolicyKeys = keyof Policy;

export interface Member {
  id?: string;
  memberTypeId?: string;
  status?: string;
  previousStatus?: string;
  inceptionDate?: Timestamp;
  requestReason?: string;
  waitingDate?: Timestamp;
  requestedWaitingDate?: Timestamp;
  email?: string;
  firstName?: string;
  lastName?: string;
  cellNumber?: string;
  altNumber?: string;
  idNumber?: string;
  memberIdNumbers?: string[];
  memberIdAndCellNumbers?: string[];
  address?: Address;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export interface Address {
  street?: string;
  city?: string;
  province?: string;
  postalCode?: string;
}

export interface PolicyAddOn {
  id?: string;
  addOnId?: string;
  inceptionDate?: Timestamp;
  waitingDate?: Timestamp;
  requestedWaitingDate?: Timestamp;
  status?: string;
  previousStatus?: string;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export interface DebitOrder {
  bank?: string;
  branchCode?: string;
  accountNumber?: string;
  accountType?: number;
  accountName?: string;
  accountHolderId?: string;
  deductionDay?: number;
  changeImmediately?: boolean;
  status?: string;
  referenceNumber?: string;
  latestDeduction?: LatestDeduction;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export interface LatestDeduction {
  transactionDate?: Timestamp;
  success?: boolean;
  reason?: string;
}

export interface Comment {
  comment?: string;
  createdOn?: Timestamp;
  createdBy?: CreatedBy;
}

export interface ProductPaymentStatus {
  id?: string;
  lastPeriodPaid?: Timestamp;
}

export enum PolicyStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  IN_PROGRESS = 'IN PROGRESS',
  ARREARS = 'ARREARS',
  LAPSED = 'LAPSED',
  PENDING = 'PENDING',
  REQUESTED = 'REQUESTED',
  UNCONFIRMED = 'UNCONFIRMED',
  UNALLOCATED = 'UNALLOCATED',
}

export enum MemberStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REQUESTED = 'REQUESTED',
  CLAIMED = 'CLAIMED',
  WAITING_PERIOD = 'WAITING PERIOD',
}

export interface PolicyCount {
  id?: string;
  count?: {
    [key in PolicyStatus]?: number;
  };
}

export const DebitOrderDeductionsDays = [1, 5, 15, 20, 25];
/**
 * BankAccountTypes Constant
 *
 * This constant defines the various types of bank accounts supported. It is used in
 * the Angular component template to dynamically generate `mat-option` elements for each
 * account type. The value assigned to each `mat-option` is the index of the account type
 * in this array, incremented by 1.
 *
 * This index-based value assignment is crucial because it aligns with how account types
 * are stored in the Firestore database. For instance, in the database, the account type
 * 'SAVINGS' is represented by the value 2. This representation is consistent with its
 * position in this array (second position), incremented by 1.
 *
 * Example:
 * - The 'CHEQUE/CURRENT' type, being the first in the array, is represented by the value 1.
 * - The 'SAVINGS' type, being the second in the array, is represented by the value 2.
 *
 * This pattern ensures a standardized and predictable way to link the UI selections with
 * the database representations of account types.
 */
export const BankAccountTypes = ['CHEQUE/CURRENT', 'SAVINGS'];
export const MandateBankAccountTypes = ['CURRENT', 'SAVINGS', 'TRANSMISSION'];

export const bankBranchMap: { [key: string]: string } = {
  ABSA: '632005',
  'AFRICAN BANK LIMITED': '430000',
  'BANK OF ATHENS': '410506',
  'BARCLAYS BANK': '590000',
  'BIDVEST BANK LIMITED': '679000',
  'CAPITEC BANK LIMITED': '470010',
  'DISCOVERY BANK LIMITED': '679000',
  'FIRST NATIONAL BANK': '250655',
  'FIRSTRAND BANK LIMITED': '201419',
  'HSBC BANK': '587000',
  'INVESTEC BANK LIMITED': '580105',
  'MERCANTILE BANK LIMITED': '450905',
  NEDBANK: '198765',
  'NEDBANK CORPORATE SAVER ACCOUNT': '720026',
  'OLD MUTUAL': '462005',
  'RAND MERCHANT BANK': '261251',
  'RMB PRIVATE BANK': '222026',
  'SASFIN BANK LIMITED': '683000',
  'SA POST BANK (POST OFFICE)': '460005',
  'STANDARD BANK': '051001',
  'STANDARD CHARTERED BANK': '730020',
  'TYME BANK': '678910',
};

export const branchCodeBankMap: { [key: string]: string[] } = {
  '632005': ['ABSA'],
  '430000': ['AFRICAN BANK LIMITED'],
  '410506': ['BANK OF ATHENS'],
  '590000': ['BARCLAYS BANK'],
  '679000': ['DISCOVERY BANK LIMITED', 'BIDVEST BANK LIMITED'],
  '470010': ['CAPITEC BANK LIMITED'],
  '250655': ['FIRST NATIONAL BANK'],
  '201419': ['FIRSTRAND BANK LIMITED'],
  '587000': ['HSBC BANK'],
  '580105': ['INVESTEC BANK LIMITED'],
  '450905': ['MERCANTILE BANK LIMITED'],
  '198765': ['NEDBANK'],
  '720026': ['NEDBANK CORPORATE SAVER ACCOUNT'],
  '462005': ['OLD MUTUAL'],
  '261251': ['RAND MERCHANT BANK'],
  '222026': ['RMB PRIVATE BANK'],
  '683000': ['SASFIN BANK LIMITED'],
  '460005': ['SA POST BANK (POST OFFICE)'],
  '051001': ['STANDARD BANK'],
  '730020': ['STANDARD CHARTERED BANK'],
  '678910': ['TYME BANK'],
};

export enum LastPeriodPaidAmendmentReasons {
  POLICY_REINSTATEMENT = 'POLICY REINSTATEMENT',
}
