<ng-container
  *ngIf="showStepper && rolesRightsService.currentUserRole?.policies?.update"
>
  <h1 class="pageSideMargins policyNumber">
    {{ policyService.selectedPolicy?.policyNumber ?? "&#8203;"
    }}{{
      policyService.selectedPolicy?.policyNumber &&
      policyLogService.currentRefNum
        ? " - "
        : ""
    }}{{ policyLogService.currentRefNum ?? "" }}
  </h1>

  <custom-stepper
    [steps]="[
      { label: 'Policy Details' },
      { label: 'Member Details' },
      { label: 'Confirm Policy' }
    ]"
    [(selectedIndex)]="selectedIndex"
    [enabledSteps]="[
      true,
      !!policyService.selectedPolicy,
      !!policyService.selectedPolicy
    ]"
    (routeChange)="onRouteChange($event)"
  ></custom-stepper>
</ng-container>
