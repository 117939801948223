import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  Preset,
  User,
  UserCount,
  UserPreset,
  UserStatus,
} from '../models/user.model';
import {
  getAuth,
  signInWithEmailAndPassword,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  updatePassword,
  signOut,
  UserCredential,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { Router } from '@angular/router';
import { SnackBarService } from './snack-bar.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  filter,
  fromEvent,
  interval,
  map,
  merge,
  takeUntil,
  throttleTime,
} from 'rxjs';
import { RolesRightsService } from './roles-rights.service';
import { Timestamp } from '@firebase/firestore';
import { MainService } from './main.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../components/miscellaneous/dialog/dialog.component';
import { environment } from 'src/environments/environment';
import { FilterService } from './filter.service';
import {
  LocationStatus,
  UpdatedBy,
  UserLocation,
} from '../models/general.model';
import { Transaction } from '../models/transaction.model';
import { Policy } from '../models/policy.model';
import { DateTimeService } from './date-time.service';
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  Query,
  query,
  QuerySnapshot,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private dbModular = getFirestore();
  private fireFunctions = getFunctions(undefined, 'europe-west3');
  private auth = getAuth();

  userData: User | undefined;
  loadedUsers: User[];
  userCount: UserCount | undefined;
  allUsers: User[];
  allActiveLocations: UserLocation[] = [];
  allLocations: UserLocation[] = [];

  selectedUser: User | undefined;
  selectedUserLocationId: string | undefined;
  userPresets: UserPreset[] | undefined;
  dailyTransactionSelectedUser: User | undefined;
  userLocationSearchText: string = '';

  timeLimit = 10 * 60 * 1000; // 10 minutes of inactivity
  // timeLimit = 1 * 15 * 1000; // 15 seconds of inactivity
  checkInterval = 5 * 60 * 1000; // check every 5 minutes
  // checkInterval = 1 * 5 * 1000; // check every 5 seconds
  private bc: BroadcastChannel;

  isSignUpPassword: boolean = false;
  isForgotPassword: boolean = false;
  isSelectingLocation: boolean = false;
  sent: boolean = false;
  loading: boolean = true;
  initialLoadingDone: boolean = false;
  loggingIn: boolean = false;
  isPaginatingUsers: boolean = false;
  isUsersBehind: boolean = false;
  usersLoading: boolean = false;

  dataSourceUsers: MatTableDataSource<any>;
  dataSourceLocations: MatTableDataSource<any>;

  userDoc: DocumentReference<User>;
  presetDoc: DocumentReference<Preset>;
  userCountDoc: DocumentReference<UserCount>;
  selectedUser$: Observable<User | undefined>;
  userPresets$: Observable<Preset | undefined>;
  dailyTransactionSelectedUser$: Observable<User | undefined>;
  userCount$: Observable<UserCount | undefined>;
  locations$: Observable<{ locations: UserLocation[] } | undefined>;

  unsubscribeFromUsersSnapshot: (() => void) | undefined = undefined;

  selectedUserUpdated = new EventEmitter<void>();
  userPresetsUpdated = new EventEmitter<void>();
  @Output() loadedUsersUpdated = new EventEmitter<void>();
  @Output() loadedLocationsUpdated = new EventEmitter<void>();

  public authState$ = new BehaviorSubject<boolean | null>(null);
  isCurrentlyLoggedIn: boolean = false;

  lastLoadedUserDoc: DocumentReference<User> | null = null;

  private locationsSubscription: Subscription;

  // BehaviorSubject to hold the users data
  private usersSubject = new BehaviorSubject<User[]>([]);
  public users$ = this.usersSubject.asObservable();

  destroy$ = new Subject<void>();

  ngUnsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private rolesRightsService: RolesRightsService,
    private mainService: MainService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    public dateTimeService: DateTimeService,
    private filterService: FilterService,
    private cookieService: CookieService
  ) {
    /* Saving user data in local storage when 
    logged in and setting up null when logged out */

    this.bc = new BroadcastChannel('activity_channel');

    const lastHeartbeat =
      Number(this.cookieService.get('lastHeartbeat')) || Date.now();

    const timeDifference = (Date.now() - lastHeartbeat) / 1000;

    if (timeDifference > this.timeLimit / 1000) {
      this.signOut();
    } else {
      this.startActivityCheckTimer();
    }

    this.bc.onmessage = (ev) => {
      if (ev.data === 'activity') {
        this.updateHeartbeat();
      }
    };

    onAuthStateChanged(this.auth, async (user) => {
      try {
        if (user) {
          this.isCurrentlyLoggedIn = true;
          localStorage.setItem('user', JSON.stringify(user));
          const storedUser = JSON.parse(localStorage.getItem('user')!);

          const userDocRef = doc(this.dbModular, 'users', user.uid);
          const userSubscription = onSnapshot(
            userDocRef,
            async (docSnapshot) => {
              const userData = { ...docSnapshot.data(), uid: user.uid } as User;
              if (userData && userData.uid === storedUser?.uid) {
                this.userData = userData;
                if (this.userData?.status === 'INACTIVE') {
                  this.signOut();
                  this.snackBarService.openRedSnackBar(
                    'THIS ACCOUNT IS CURRENTLY INACTIVE'
                  );
                } else {
                  if (
                    (this.userData?.roleId &&
                      !this.rolesRightsService.currentUserRole) ||
                    this.rolesRightsService.currentUserRole?.id !==
                      this.userData?.roleId
                  ) {
                    await this.rolesRightsService.setCurrentUserRole(
                      this.userData.roleId
                    );
                  }
                  this.authState$.next(true);
                  if (this.allActiveLocations.length === 0) {
                    await this.loadUserLocations();
                  }
                  if (this.userData.currentUserLocationId) {
                    this.selectedUserLocationId =
                      this.userData.currentUserLocationId;
                  } else {
                    this.isSelectingLocation = true;
                  }
                }
                this.initialLoadingDone = true;
              }
            }
          );

          // Unsubscribe when destroy$
          this.destroy$.subscribe(() => userSubscription());
        } else {
          this.isCurrentlyLoggedIn = false;
          this.initialLoadingDone = false;
          localStorage.setItem('user', 'null');
          this.authState$.next(false);
        }
      } catch (error) {
        console.error('Error in onAuthStateChanged: ', error);
        if (error instanceof Error) {
          this.snackBarService.latestError = error.message;
          this.snackBarService.openRedSnackBar('ERROR IN AUTH STATE CHANGE');
        }
      }
    });

    if (isSignInWithEmailLink(this.auth, window.location.href)) {
      signOut(this.auth)
        .then(() => {
          this.isSignUpPassword = true;
          setTimeout(() => (this.loading = false), 1000);
        })
        .catch((err) => {
          if (err instanceof Error)
            this.snackBarService.latestError = err.message;
          this.snackBarService.openRedSnackBar(
            'THERE WAS AN ERROR SIGNING OUT THE RECENT USER'
          );
        });
    } else {
      this.isSignUpPassword = false;
      this.loading = false;
    }

    if (
      !this.isLoggedIn() &&
      !isSignInWithEmailLink(this.auth, window.location.href) &&
      !this.isForgotPassword
    ) {
      this.router.navigate(['/login']);
    }
  }

  monitorUserActivity() {
    const activityEvents = merge(
      fromEvent(window, 'load'),
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'click'),
      fromEvent(document, 'touchstart'),
      fromEvent(document, 'keydown'),
      fromEvent(window, 'scroll')
    );

    activityEvents.pipe(throttleTime(2000)).subscribe(() => {
      this.bc.postMessage('activity');
      this.updateHeartbeat();
    });
  }

  updateHeartbeat() {
    const now = Date.now();
    this.cookieService.set('lastHeartbeat', now.toString(), { path: '/' });
  }

  startActivityCheckTimer() {
    this.updateHeartbeat();
    this.monitorUserActivity();

    interval(this.checkInterval)
      .pipe(
        map(
          () => Number(this.cookieService.get('lastHeartbeat')) || Date.now()
        ),
        filter(
          (lastHeartbeat) =>
            this.isCurrentlyLoggedIn &&
            Date.now() - lastHeartbeat > this.timeLimit
        )
      )
      .subscribe(() => {
        this.signOut();
      });
  }

  async setSelectedUser(userId: string): Promise<void> {
    try {
      const userDocRef = doc(this.dbModular, 'users', userId);
      this.selectedUser$ = new Observable<User>((observer) => {
        const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            observer.next({
              id: docSnapshot.id,
              ...docSnapshot.data(),
            } as User);
          } else {
            observer.error(new Error('No user found'));
          }
        });
        return unsubscribe;
      });

      return new Promise<void>((resolve, reject) => {
        this.selectedUser$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (user) => {
            this.selectedUser = user;
            this.selectedUserUpdated.emit();
            resolve();
          },
          error: (err) => {
            if (err instanceof Error) {
              this.snackBarService.latestError = err.message;
              this.snackBarService.openRedSnackBar(
                'SETTING THE SELECTED USER FAILED!'
              );
              reject(err);
            }
          },
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar(
          'SETTING THE SELECTED USER FAILED!'
        );
        throw err;
      }
    }
  }

  async setDailyTransactionSelectedUser(userId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (userId === 'all-users') {
        this.dailyTransactionSelectedUser = {
          uid: 'all-users',
          email: 'ALL USERS',
          displayName: 'ALL USERS',
          photoURL: '',
          emailVerified: false,
          status: '',
          roleId: undefined,
          updatedOn: undefined,
          updatedBy: undefined,
        };
        resolve();
        return;
      }

      try {
        const userDocRef = doc(this.dbModular, 'users', userId);
        this.dailyTransactionSelectedUser$ = new Observable<User>(
          (observer) => {
            const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
              if (docSnapshot.exists()) {
                observer.next({
                  id: docSnapshot.id,
                  ...docSnapshot.data(),
                } as User);
              } else {
                observer.error(new Error('No user found'));
              }
            });
            return unsubscribe;
          }
        );

        this.dailyTransactionSelectedUser$
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (user) => {
              this.dailyTransactionSelectedUser = user;
              resolve();
            },
            error: (err) => {
              if (err instanceof Error) {
                this.snackBarService.latestError = err.message;
                this.snackBarService.openRedSnackBar(
                  'SETTING THE SELECTED USER FAILED!'
                );
                reject(err);
              }
            },
          });
      } catch (err) {
        if (err instanceof Error) {
          this.snackBarService.latestError = err.message;
          this.snackBarService.openRedSnackBar(
            'SETTING THE SELECTED USER FAILED!'
          );
          reject(err);
        }
      }
    });
  }

  async loadAllUsers() {
    return new Promise<void>((resolve, reject) => {
      const usersCollectionRef = collection(this.dbModular, 'users');
      const usersQuery = query(
        usersCollectionRef,
        orderBy('updatedOn', 'desc')
      );

      const usersSubscription = onSnapshot(usersQuery, {
        next: (querySnapshot: QuerySnapshot<User>) => {
          this.allUsers = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          resolve();
        },
        error: (err) => {
          this.snackBarService.latestError = err.message;
          this.snackBarService.openRedSnackBar('LOADING ALL USERS FAILED!');
          reject(err);
        },
      });

      this.destroy$.subscribe(() => usersSubscription());
    });
  }

  loadUsers(pageIndex: number = 0, pageSize: number = 20): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.loading = true;

      if (this.usersSubject.closed && this.isLoggedIn()) {
        this.usersSubject = new BehaviorSubject<User[]>([]);
      }

      const usersCollectionRef = collection(this.dbModular, 'users');

      let q: Query = query(
        usersCollectionRef,
        orderBy('status'),
        orderBy('updatedOn', 'desc')
      );

      if (this.filterService.userFilter !== 'ALL') {
        q = query(q, where('status', '==', this.filterService.userFilter));
      }

      if (this.lastLoadedUserDoc) {
        q = query(q, startAfter(this.lastLoadedUserDoc));
      }

      q = query(q, limit(pageSize));

      this.unsubscribeFromUsersSnapshot = onSnapshot(
        q,
        async (querySnapshot) => {
          try {
            if (
              !querySnapshot.metadata.hasPendingWrites &&
              !querySnapshot.metadata.fromCache
            ) {
              const isIntentionalPagination =
                this.loadedUsers &&
                pageIndex > 0 &&
                (pageIndex + 1) * pageSize > this.loadedUsers.length;

              if (isIntentionalPagination) {
                this.isPaginatingUsers = true;
              }

              if (!this.userCount) await this.loadUserCount();

              let users: User[] = [];
              let lastDoc = null;

              querySnapshot.forEach((doc) => {
                const userData = doc.data() as User;
                userData.uid = doc.id;
                users.push(userData);
                lastDoc = doc;
              });

              if (
                this.filterService.userFilter === UserStatus.ALL ||
                !users.some(
                  (user) => user.status !== this.filterService.userFilter
                )
              ) {
                if (this.isPaginatingUsers && !isIntentionalPagination) {
                  this.isUsersBehind = true;
                  this.loading = false;
                  resolve();
                  return;
                }
                this.usersLoading = true;

                this.lastLoadedUserDoc = lastDoc;

                this.loadedUsers = [
                  ...(this.loadedUsers || []).slice(0, pageIndex * pageSize),
                  ...users,
                ];

                const startIndex = pageIndex * pageSize;
                const endIndex = startIndex + pageSize;
                const usersForPage = this.loadedUsers.slice(
                  startIndex,
                  endIndex
                );

                this.usersSubject.next(this.loadedUsers);

                this.updateCurrentUsers(usersForPage);
                this.loadedUsersUpdated.emit();
              }
            }
            this.usersLoading = false;
            this.loading = false;
            resolve();
          } catch (err) {
            console.error('Error in loadUsers:', err);
            this.usersLoading = false;
            this.loading = false;
            reject(err);
          }
        },
        (error) => {
          console.error('Error fetching snapshot:', error);
          this.loading = false;
          reject(error);
        }
      );
    });
  }

  updateCurrentUsers(users: User[]) {
    if (!this.dataSourceUsers) {
      this.dataSourceUsers = new MatTableDataSource<User>(users);
    } else {
      this.dataSourceUsers.data = users;
      this.dataSourceUsers._updateChangeSubscription();
    }
  }

  async updateUserLocation(userLocationData: UserLocation) {
    const metaDataDocRef = doc(this.dbModular, 'metaData', 'locations');

    try {
      const docSnapshot = await getDoc(metaDataDocRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data() as { locations: UserLocation[] };
        let locations = data.locations;

        const index = locations.findIndex(
          (location) => location.id === userLocationData.id
        );

        if (index !== -1) {
          locations[index] = { ...locations[index], ...userLocationData };
        } else {
          locations.push(userLocationData);
        }

        await updateDoc(metaDataDocRef, { locations });
        this.snackBarService.openBlueSnackBar('LOCATION UPDATED SUCCESSFULLY');
      }
      this.loadedLocationsUpdated.emit();
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR UPDATING LOCATION');
    }
  }

  // Checks if a user location is in use across users, transactions, and policies
  async canDeleteUserLocation(locationId: string): Promise<boolean> {
    const userCollectionRef = collection(this.dbModular, 'users');
    const transactionCollectionRef = collection(this.dbModular, 'transaction');
    const policyCollectionRef = collection(this.dbModular, 'policy');

    const usersSnapshot = await getDocs(userCollectionRef);
    const isLocationUsedInUsers = usersSnapshot.docs.some((doc) => {
      const user = doc.data() as User;
      return user.userLocationIds?.includes(locationId);
    });
    if (isLocationUsedInUsers) {
      return false;
    }

    const transactionsSnapshot = await getDocs(transactionCollectionRef);
    const isLocationUsedInTransactions = transactionsSnapshot.docs.some(
      (doc) => {
        const transaction = doc.data() as Transaction;
        return transaction.createdBy?.userLocationId === locationId;
      }
    );
    if (isLocationUsedInTransactions) {
      return false;
    }

    const policiesSnapshot = await getDocs(policyCollectionRef);
    const isLocationUsedInPolicies = policiesSnapshot.docs.some((doc) => {
      const policy = doc.data() as Policy;
      return policy.updatedBy?.userLocationId === locationId;
    });
    if (isLocationUsedInPolicies) {
      return false;
    }

    return true;
  }

  async deleteUserLocationById(locationId: string): Promise<void> {
    const metaDataDocRef = doc(this.dbModular, 'metaData', 'locations');
    const userCollectionRef = collection(this.dbModular, 'users');

    try {
      const docSnapshot = await getDoc(metaDataDocRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data() as { locations: UserLocation[] };
        let locations = data.locations;

        const index = locations.findIndex(
          (location) => location.id === locationId
        );

        if (index !== -1) {
          locations.splice(index, 1);
          await updateDoc(metaDataDocRef, { locations });

          const usersSnapshot = await getDocs(userCollectionRef);
          usersSnapshot.forEach(async (userDoc) => {
            const user = userDoc.data() as User;
            if (
              user.uid &&
              user.userLocationIds &&
              user.userLocationIds.includes(locationId)
            ) {
              const updatedUserLocationIds = user.userLocationIds.filter(
                (id) => id !== locationId
              );
              const userDocRef = doc(this.dbModular, 'users', user.uid);
              await updateDoc(userDocRef, {
                userLocationIds: updatedUserLocationIds,
              });
            }
          });

          this.snackBarService.openBlueSnackBar(
            'LOCATION DELETED SUCCESSFULLY'
          );
        } else {
          this.snackBarService.openRedSnackBar('LOCATION NOT FOUND');
        }
      }
    } catch (err) {
      console.error('Error deleting location: ', err);
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR DELETING LOCATION');
    }
  }

  async loadUserLocations(): Promise<void> {
    const metaDataDocRef = doc(this.dbModular, 'metaData', 'locations');

    this.locations$ = new Observable<{ locations: UserLocation[] }>(
      (observer) => {
        const unsubscribe = onSnapshot(
          metaDataDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              observer.next(
                docSnapshot.data() as { locations: UserLocation[] }
              );
            } else {
              this.snackBarService.openRedSnackBar(
                'LOCATIONS DOCUMENT NOT FOUND!'
              );
              observer.error(new Error('LOCATIONS DOCUMENT NOT FOUND!'));
            }
          },
          (error) => observer.error(error)
        );

        return unsubscribe;
      }
    );

    await new Promise<void>((resolve, reject) => {
      this.locations$.pipe(takeUntil(this.destroy$)).subscribe({
        next: (locationDoc) => {
          this.allLocations = locationDoc?.locations ?? [];
          this.dataSourceLocations = new MatTableDataSource<UserLocation>(
            this.allLocations
          );
          this.allActiveLocations = this.allLocations.filter(
            (location) => location.status !== 'INACTIVE'
          );
          this.loadedLocationsUpdated.emit();
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  getUserLocationById(id?: string): UserLocation | undefined {
    return this.allLocations?.find((location) => location.id === id);
  }

  getUserLocationNameById(id?: string): string {
    return this.getUserLocationById(id)?.name ?? '';
  }

  async loadUserPresets() {
    const presetDocRef = doc(this.dbModular, 'metaData', 'preset');

    this.userPresets$ = new Observable<Preset>((observer) => {
      const unsubscribe = onSnapshot(
        presetDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data() as Preset;
            observer.next({ ...data, id: docSnapshot.id } as Preset);
          } else {
            this.snackBarService.openRedSnackBar('PRESET DOCUMENT NOT FOUND!');
            observer.error(new Error('PRESET DOCUMENT NOT FOUND!'));
          }
        },
        (error) => observer.error(error)
      );

      return unsubscribe;
    });

    await new Promise<void>((resolve, reject) => {
      this.userPresets$.pipe(takeUntil(this.destroy$)).subscribe({
        next: (presets) => {
          if (presets) {
            this.userPresets = presets.users; // Ensure `users` property is available in `Preset`
            this.userPresetsUpdated.emit();
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  async loadUserCount() {
    const userCountDocRef = doc(this.dbModular, 'metaData', 'user');

    this.userCount$ = new Observable<UserCount>((observer) => {
      const unsubscribe = onSnapshot(
        userCountDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            observer.next({
              id: docSnapshot.id,
              ...docSnapshot.data(),
            } as UserCount);
          } else {
            this.snackBarService.openRedSnackBar(
              'USER COUNT DOCUMENT NOT FOUND!'
            );
            observer.error(new Error('USER COUNT DOCUMENT NOT FOUND!'));
          }
        },
        (error) => observer.error(error)
      );

      return unsubscribe;
    });

    await new Promise<void>((resolve, reject) => {
      this.userCount$.pipe(takeUntil(this.destroy$)).subscribe({
        next: (user) => {
          if (user) {
            this.userCount = user;
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  // Sign in with email/password
  async logIn(email: string, password: string): Promise<void> {
    this.loggingIn = true;
    try {
      await this.signInOrTimeout(email, password);
      this.signInSuccess();
    } catch (error) {
      this.loggingIn = false;
      this.signInError(error);
    } finally {
      this.mainService.setLoading(false);
    }
  }

  private async signInOrTimeout(
    email: string,
    password: string
  ): Promise<UserCredential> {
    const auth = getAuth();
    const signInPromise = signInWithEmailAndPassword(auth, email, password);
    const timeoutPromise = this.timeoutPromise();

    return Promise.race([signInPromise, timeoutPromise]);
  }

  private timeoutPromise(): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('TIMEOUT')), 30000);
    });
  }

  private async signInSuccess(): Promise<void> {
    this.startActivityCheckTimer();
    if (!this.allActiveLocations || this.allActiveLocations.length === 0) {
      await this.loadUserLocations();
    }
    // Filter userLocationIds to include only those that are active
    const activeUserLocationIds = this.userData?.userLocationIds?.filter(
      (userLocationId: string) =>
        this.allActiveLocations.some(
          (location) => location.id === userLocationId
        )
    );

    // Now activeUserLocationIds contains only the IDs of active locations
    if (
      (activeUserLocationIds && activeUserLocationIds.length > 1) ||
      this.userData?.currentUserLocationId == undefined ||
      this.getUserLocationById(this.userData?.currentUserLocationId)?.status ==
        LocationStatus.INACTIVE
    ) {
      this.isSelectingLocation = true;
    } else {
      this.isSelectingLocation = false;
    }
    this.loggingIn = false;
  }

  private signInError(err: any): void {
    let errorMessage: string;
    if (err.message === 'TIMEOUT') {
      errorMessage =
        'LOG IN IS TAKING TOO LONG. CHECK YOUR INTERNET CONNECTION';
    } else {
      switch (err.code) {
        case 'auth/invalid-email':
          errorMessage = 'The email address provided is invalid.';
          break;
        case 'auth/user-disabled':
          errorMessage = 'Your account has been disabled.';
          break;
        case 'auth/user-not-found':
          errorMessage = 'No account found with this email address.';
          break;
        case 'auth/wrong-password':
          errorMessage = 'The password provided is incorrect.';
          break;
        case 'auth/network-request-failed':
          errorMessage = 'A network error occurred. Please try again.';
          break;
        default:
          errorMessage = err.message;
      }
    }
    this.snackBarService.latestError = err;

    this.snackBarService.openRedSnackBar(errorMessage);
  }

  // Sign up with email/password
  async signUpWithEmailAndPass(user: any) {
    try {
      const result = await createUserWithEmailAndPassword(
        this.auth,
        user.email,
        user.password
      );

      await this.sendVerificationMail();
      if (result.user) {
        const newUser = user;
        newUser.uid = result.user.uid;
        await this.setUserData(newUser);
      }
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar(
        'THERE WAS AN ERROR WITH CREATING YOUR ACCOUNT'
      );
    }
  }

  // Send the passwordless sign-in email
  async createUserWithEmailLink(user: any) {
    const actionCodeSettings = {
      // url: 'http://localhost:4200/',
      url: environment.url,
      handleCodeInApp: true,
    };
    const auth = getAuth();
    await sendSignInLinkToEmail(auth, user.email, actionCodeSettings)
      .then(async () => {
        this.sent = true;
        window.localStorage.setItem('emailForSignIn', user.email);
        await this.newUserPreset(user);
        setTimeout(() => {
          this.sent = false;
        }, 3000);
      })
      .catch((err) => {
        if (err instanceof Error)
          this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar(
          'THERE WAS AN ERROR SENDING A SIGN UP LINK TO: ' + user.email
        );
      });
  }

  // Call this method to complete the passwordless sign-in
  async completeSignInWithPassword(email: string, password: string) {
    const auth = getAuth();
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      this.mainService.setLoading(true);
      this.mainService.setLoadingInfo('CHECKING USER DETAILS...');
      signInWithEmailLink(auth, email!, window.location.href)
        .then(async (result) => {
          if (result.user.uid && result.user.email) {
            const firebaseUserData: Partial<User> = {
              uid: result.user.uid,
              email: result.user.email,
              emailVerified: result.user.emailVerified,
            };
            this.mainService.setLoadingInfo('CREATING USER ACCOUNT...');
            const [_, userPreset] = await Promise.all([
              updatePassword(result.user, password),
              this.getUserPreset(result.user),
            ]);
            const mergeUserData = (
              user: Partial<User>,
              preset?: UserPreset
            ): Partial<User> => {
              return {
                ...user,
                ...preset,
                status: UserStatus.ACTIVE,
              };
            };

            const userData = mergeUserData(firebaseUserData, userPreset);
            this.mainService.setLoadingInfo('UPDATING ROLES AND RIGHTS...');
            await this.setUserData(userData);
            this.mainService.setLoading(false);
            this.isSignUpPassword = false;
            this.openAccountCreatedSuccessfullyDialog();
          }
        })
        .catch((err) => {
          let errorMessage: string;

          switch (err.code) {
            case 'auth/email-already-in-use':
              errorMessage = 'An account with this email already exists.';
              break;
            case 'auth/invalid-email':
              errorMessage = 'The email address provided is invalid.';
              break;
            case 'auth/operation-not-allowed':
              errorMessage = 'Email and password accounts are not enabled.';
              break;
            case 'auth/weak-password':
              errorMessage = 'The password provided is too weak.';
              break;
            case 'auth/network-request-failed':
              errorMessage = 'A network error occurred. Please try again.';
              break;
            case 'auth/expired-action-code':
              errorMessage = 'Your sign-up link has expired.';
              break;
            default:
              errorMessage = err.message;
          }

          this.snackBarService.latestError = err;
          this.mainService.setLoading(false);
          this.snackBarService.openRedSnackBar(errorMessage);
        });
    }
  }

  async getUserPreset(user: any) {
    try {
      if (!this.userPresets) await this.loadUserPresets();

      if (this.userPresets) {
        return (
          this.userPresets.find(
            (preset) =>
              preset.email?.toLowerCase() === user.email?.toLowerCase()
          ) ?? undefined
        );
      } else {
        return undefined;
      }
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      return undefined;
    }
  }

  private async newUserPreset(formValues: any) {
    try {
      if (!this.userPresets) await this.loadUserPresets();

      const userPresets = this.userPresets;
      if (userPresets) {
        const index = userPresets.findIndex(
          (preset) => preset.email === formValues.email
        );

        const createdBy = {
          uid: this.userData?.uid,
          displayName: this.userData?.displayName,
          email: this.userData?.email,
          userLocationId: this.userData?.currentUserLocationId,
        };
        const createdOn = Timestamp.now();

        const preset = { ...formValues, createdBy, createdOn };

        if (index !== undefined && index >= 0) {
          userPresets[index] = preset;
        } else {
          if (userPresets.length >= 100) {
            userPresets[99] = preset;
          } else {
            userPresets.push(preset);
          }
        }

        userPresets.sort((a, b) => {
          const aMillis = a.createdOn?.toMillis() ?? 0;
          const bMillis = b.createdOn?.toMillis() ?? 0;

          if (aMillis > bMillis) return -1;
          if (aMillis < bMillis) return 1;
          return 0;
        });

        const presetDocRef = doc(this.dbModular, 'metaData', 'preset');
        await updateDoc(presetDocRef, { users: userPresets });
      }
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      throw err;
    }
  }

  // Send email verification when new user is created
  async sendVerificationMail() {
    const user = this.auth.currentUser;
    if (user) {
      await sendEmailVerification(user);
    }
  }

  // Reset Forgot password
  async forgotPassword(passwordResetEmail: string) {
    try {
      await sendPasswordResetEmail(this.auth, passwordResetEmail);
      this.snackBarService.openBlueSnackBar(
        'PASSWORD RESET EMAIL SENT, CHECK YOUR INBOX'
      );
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar(
        'THERE WAS AN ERROR SENDING THE PASSWORD RESET EMAIL'
      );
    }
  }

  // Returns true when user is logged in and email is verified
  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Sign in with Google
  googleAuth() {
    const provider = new GoogleAuthProvider();
    return this.authLogin(provider);
  }

  // Auth logic to run auth providers
  async authLogin(provider: any) {
    try {
      const result = await signInWithPopup(this.auth, provider);
      this.router.navigateByUrl('/home');

      if (result.user) {
        await this.setUserData(result.user);
      }
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('THERE WAS AN ERROR SIGNING IN');
    }
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  async setUserData(user?: User) {
    if (!user) return;

    const userRef = doc(this.dbModular, `users/${user.uid}`);

    const updatedBy: UpdatedBy = {
      uid: this.isSignUpPassword ? user.uid : this.userData?.uid,
      displayName: this.isSignUpPassword
        ? user.displayName
        : this.userData?.displayName,
      email: this.isSignUpPassword
        ? user.email?.toLowerCase()
        : this.userData?.email?.toLowerCase(),
      cellNumber: this.isSignUpPassword
        ? user.cellNumber
        : this.userData?.cellNumber,
    };
    const updatedOn = Timestamp.now();

    if (this.userData?.currentUserLocationId) {
      updatedBy.userLocationId = this.userData.currentUserLocationId;
    }

    const userData: User = {
      uid: user.uid,
      email: user.email?.toLowerCase(),
      displayName: user.displayName,
      photoURL: user.photoURL || '',
      emailVerified: user.emailVerified,
      userLocationIds: user.userLocationIds || [],
      cellNumber: user.cellNumber,
      roleId: user.roleId || '',
      status: user.status || '',
      dailyTotals: user.dailyTotals || false,
      netcashAuth: user.netcashAuth || false,
      importResult: user.importResult || false,
      updatedBy,
      updatedOn,
    };

    if (user.currentUserLocationId || this.userData?.currentUserLocationId) {
      userData.currentUserLocationId =
        user.currentUserLocationId ?? this.userData?.currentUserLocationId;
    }

    try {
      await setDoc(userRef, userData, { merge: true });

      if (!this.isSignUpPassword) {
        this.snackBarService.openBlueSnackBar(
          'USER ACCOUNT UPDATED SUCCESSFULLY!'
        );
      } else {
        this.userData = userData;

        if (this.allActiveLocations.length === 0) {
          await this.loadUserLocations();
        }
      }
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar(
        'THERE WAS AN ERROR WITH UPDATING THE USER ACCOUNT'
      );
    }
  }

  openAccountCreatedSuccessfullyDialog() {
    if (this.userData) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        dialogType: 'accountCreatedSuccessfullyDialog',
      };

      const dialogRef = this.dialog.open(DialogComponent, dialogConfig);

      this.mainService.setDisabled(true);

      setTimeout(() => {
        this.mainService.setDisabled(false);
      }, 5000);

      dialogRef.afterClosed().subscribe(() => {
        this.isSelectingLocation = true;
      });
    }
  }

  getAuthState(): Observable<boolean | null> {
    return this.authState$.asObservable();
  }

  get totalUserCount(): number {
    if (!this.userCount?.count) return 0;
    return Object.entries(this.userCount.count).reduce(
      (a, [, value]) => a + (value || 0),
      0
    );
  }

  // Sign out
  async signOut() {
    const auth = getAuth();
    try {
      this.dailyTransactionSelectedUser = undefined;

      this.destroy$.next();
      this.rolesRightsService.cleanUp();
      if (this.locationsSubscription) {
        this.locationsSubscription.unsubscribe();
      }

      this.mainService.setPreviousRoute(this.router.url);
      await new Promise((resolve) => setTimeout(resolve, 100));
      await signOut(auth);
      localStorage.removeItem('user');
      this.router.navigateByUrl('/login');
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar(
        'THERE WAS AN ERROR SIGNING YOU OUT'
      );
    }
  }

  async toggleUserStatus(): Promise<void> {
    if (
      this.rolesRightsService?.currentUserRole?.users?.delete &&
      this.selectedUser
    ) {
      this.mainService.setLoading(true);
      const callable = httpsCallable(this.fireFunctions, 'toggleUserStatus');

      const uid = this.selectedUser.uid;
      const action =
        this.selectedUser.status === 'ACTIVE' ? 'disable' : 'enable';

      try {
        const response: any = await callable({ uid, action });

        if (response.data.success) {
          this.snackBarService.openBlueSnackBar(
            'USER SUCCESSFULLY ' +
              (action === 'enable' ? 'ENABLED' : 'DISABLED')
          );
        } else {
          this.snackBarService.latestError = response.data.error;
          this.mainService.setLoading(false);
          this.snackBarService.openRedSnackBar(
            `ERROR ${action === 'enable' ? 'ENABLING' : 'DISABLING'} USER`
          );
          throw new Error(
            `ERROR ${action === 'enable' ? 'ENABLING' : 'DISABLING'} USER` +
              response.data.error
          );
        }
      } catch (err) {
        if (err instanceof Error) {
          this.snackBarService.latestError = err.message;
          this.mainService.setLoading(false);
          this.snackBarService.openRedSnackBar('ERROR INVOKING CLOUD FUNCTION');
          throw new Error('ERROR INVOKING CLOUD FUNCTION: ' + err.message);
        }
      }
      this.mainService.setLoading(false);
    }
  }

  async deleteUser(uid: string): Promise<void> {
    if (this.userData?.email?.endsWith('@ioio.co.za')) {
      this.mainService.setLoading(true);
      const callable = httpsCallable(this.fireFunctions, 'deleteUser');

      try {
        const response: any = await callable({ uid });

        if (response.data.success) {
          this.snackBarService.openBlueSnackBar('USER SUCCESSFULLY DELETED');
        } else {
          this.snackBarService.latestError = response.data.error;
          this.mainService.setLoading(false);
          this.snackBarService.openRedSnackBar('ERROR DELETING USER');
          throw new Error('ERROR DELETING USER: ' + response.data.error);
        }
      } catch (err) {
        if (err instanceof Error) {
          this.snackBarService.latestError = err.message;
          this.mainService.setLoading(false);
          this.snackBarService.openRedSnackBar('ERROR INVOKING CLOUD FUNCTION');
          throw new Error('ERROR INVOKING CLOUD FUNCTION: ' + err.message);
        }
      }
      this.mainService.setLoading(false);
    }
  }

  applyUserLocationFilter() {
    this.dataSourceLocations.filterPredicate = (
      data: UserLocation,
      filter: string
    ) => {
      const name = data.name || '';
      const code = data.code || '';
      const status = data.status || '';

      return (
        name.toLowerCase().includes(filter.toLowerCase()) ||
        code.toLowerCase().includes(filter.toLowerCase()) ||
        status.toLowerCase().includes(filter.toLowerCase())
      );
    };

    this.dataSourceLocations.filter = this.userLocationSearchText;
  }

  public toInternationalFormat(phoneNumber: string): string {
    if (phoneNumber.startsWith('0')) {
      return '+27' + phoneNumber.substring(1);
    }
    return phoneNumber;
  }

  resetSelectedUser() {
    this.selectedUser = undefined;
  }

  resetCurrentUser() {
    this.userData = undefined;
  }

  unsubscribe() {
    if (this.unsubscribeFromUsersSnapshot) {
      this.unsubscribeFromUsersSnapshot();
      this.unsubscribeFromUsersSnapshot = undefined;
      this.lastLoadedUserDoc = null;
    }
  }

  cleanUp() {
    this.userCount = undefined;
    this.unsubscribe();
    this.resetCurrentUser();
    this.resetSelectedUser();
    this.destroy$.next();
  }
}
