<div class="custom-stepper">
  <div
    *ngFor="let step of steps; let i = index"
    [class.disabled]="!enabledSteps[i]"
    [class.active]="selectedIndex === i"
    (click)="stepClicked(i)"
    class="step"
  >
    {{ step.label.toUpperCase() }}
  </div>
  <div class="back-button">
    <button
      mat-fab
      color="primary"
      routerLink="{{ mainService.returnRoute ?? '/policies' }}"
      routerLinkActive="is-active"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
</div>
