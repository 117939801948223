import { Injectable } from '@angular/core';
import { FileData } from '../models/file.model';
import { PolicyService } from './policy.service';
import { SnackBarService } from './snack-bar.service';
import axios from 'axios';
import { ImportResult, ImportType } from '../models/import.model';
import { RolesRightsService } from './roles-rights.service';
import * as Papa from 'papaparse';
import { Timestamp } from '@firebase/firestore';
import { UserService } from './user.service';
import { ImportService } from './import.service';
import { DateTimeService } from './date-time.service';
import { MainService } from './main.service';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private dbModular = getFirestore();
  private fireFunctions = getFunctions(undefined, 'europe-west3');

  selectedFile: File | undefined;
  progressPercentage: number | undefined;
  uploadingFile: File | undefined;
  isUploading: boolean = false;
  uploadObject: any;

  constructor(
    private mainService: MainService,
    private policyService: PolicyService,
    private snackBarService: SnackBarService,
    public rolesRightsService: RolesRightsService,
    private importService: ImportService,
    private dateTimeService: DateTimeService,
    private userService: UserService
  ) {}

  async initiateFileUpload(customName: string, importType?: ImportType) {
    if (this.importService.isImportInProgress) {
      this.snackBarService.openRedSnackBar('IMPORT IN PROGRESS!');
      return;
    }

    if (importType) {
      const invalidImportMessage = await this.isValidImport(importType);
      if (invalidImportMessage) {
        this.snackBarService.latestError = invalidImportMessage;
        this.snackBarService.openRedSnackBar('INVALID IMPORT ATTEMPTED!');
        return;
      }
    }

    if (this.selectedFile) {
      this.isUploading = true;
      this.uploadingFile = this.selectedFile;

      try {
        const callable = httpsCallable(this.fireFunctions, 'getUploadUrl');

        const signedUrlResponse = await callable({
          action: importType ? this.importTypeToAction(importType) : 'update',
          mimeType: this.selectedFile.type,
          resource: importType ? 'imports' : 'policies',
        });

        if (
          typeof signedUrlResponse.data === 'object' &&
          signedUrlResponse.data !== null
        ) {
          const { uploadUrl, tempFilePath, id } = signedUrlResponse.data as {
            uploadUrl: string;
            tempFilePath: string;
            id: string;
          };

          const uploadResponse = await this.uploadFileToSignedUrl(
            uploadUrl,
            this.selectedFile
          );

          if (uploadResponse.status === 'success') {
            const processCallable = httpsCallable(
              this.fireFunctions,
              'processUploadedFile'
            );
            const processResponse = await processCallable({
              tempFilePath: tempFilePath,
              newFilePath: importType
                ? this.importService.getFilePathFromImportType(importType)
                : undefined,
            });

            if (
              typeof processResponse.data === 'object' &&
              processResponse.data !== null
            ) {
              const { url: downloadUrl } = processResponse.data as {
                url: string;
              };
              this.isUploading = false;

              if (!importType) {
                if (this.policyService.selectedPolicy) {
                  const fileData = {
                    id: id,
                    name: this.selectedFile.name,
                    url: downloadUrl,
                    policyId: this.policyService.selectedPolicy.id,
                    customName: customName,
                  };
                  await this.policyService.updatePolicyFiles(
                    this.policyService.selectedPolicy,
                    fileData,
                    -1
                  );
                }
              } else {
                await this.importService.addImportDoc(
                  id,
                  this.selectedFile.name,
                  customName,
                  importType,
                  downloadUrl
                );
              }

              this.selectedFile = undefined;
              this.uploadingFile = undefined;
              this.progressPercentage = undefined;
            } else {
              throw new Error('FILE PROCESSING FAILED.');
            }
          } else {
            throw new Error('FILE UPLOAD FAILED.');
          }
        } else {
          throw new Error('INVALID SIGNED URL RESPONSE.');
        }
      } catch (err) {
        if (err instanceof Error)
          this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('ERROR UPLOADING FILE');
        this.selectedFile = undefined;
        this.uploadingFile = undefined;
        this.progressPercentage = undefined;
        this.isUploading = false;
      }
    }
  }

  private async uploadFileToSignedUrl(
    signedUrl: string,
    file: File
  ): Promise<any> {
    try {
      const headers = {
        'Content-Type': file.type,
      };

      const config = {
        headers: headers,
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent.total) {
            this.progressPercentage = Math.round(
              (100 * progressEvent.loaded) / progressEvent.total
            );
            this.userService.updateHeartbeat();
          }
        },
      };

      this.progressPercentage = 0;
      await axios.put(signedUrl, file, config);
      return { status: 'success' };
    } catch (err) {
      console.log(err);
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR UPLOADING FILE');
      if (err instanceof Error) return { status: 'failed', error: err.message };
    }
  }

  async downloadFile(file: FileData): Promise<void> {
    try {
      if (file.url) {
        const fileBlob = await fetch(file.url).then((response) =>
          response.blob()
        );

        const url = URL.createObjectURL(fileBlob);
        const link = document.createElement('a');
        link.href = url;

        link.download = file.name ?? 'downloaded-file.ext';

        link.click();
        URL.revokeObjectURL(url);
      }
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR DOWNLOADING FILE');
    }
  }

  downloadRetryCSV() {
    if (
      this.importService.selectedImportResult?.retryCsvFileData?.id &&
      this.importService.selectedImportResult?.retryCsvFileData?.name &&
      this.importService.selectedImportResult?.retryCsvFileData?.url
    ) {
      const baseName =
        this.importService.selectedImportResult.retryCsvFileData.name;
      const extensionIndex = baseName.lastIndexOf('.');
      const fileNameWithoutExtension = baseName.substring(0, extensionIndex);
      const fileExtension = baseName.substring(extensionIndex);
      const fileData: FileData = {
        ...this.importService.selectedImportResult?.retryCsvFileData,
        name: `${fileNameWithoutExtension} FAILED LINES${fileExtension}`,
      };
      this.downloadFile(fileData);
    } else {
      this.snackBarService.openRedSnackBar('NO RETRY CSV URL FOUND');
    }
  }

  async deleteFile(file: FileData | undefined, index?: number) {
    if (file?.id) {
      const filePath = `documents/${file.id}`;
      const callable = httpsCallable(this.fireFunctions, 'deleteFile');

      try {
        if (this.policyService.selectedPolicy && index !== undefined) {
          await this.policyService.updatePolicyFiles(
            this.policyService.selectedPolicy,
            {},
            index,
            true
          );
        }

        await callable({
          action: 'delete',
          filePath: filePath,
          resource: 'policies',
        });

        this.snackBarService.openBlueSnackBar('FILE DELETED SUCCESSFULLY');
      } catch (err) {
        if (err instanceof Error)
          this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('ERROR DELETING FILE');
      }
    }
  }

  async deleteImport(importResult: ImportResult): Promise<void> {
    if (
      !importResult?.csvFileData?.id ||
      !importResult?.csvFileData?.name ||
      !importResult?.csvFileData?.url ||
      !importResult.id
    ) {
      this.snackBarService.openRedSnackBar('IMPORT DATA NOT FOUND');
      return;
    }

    try {
      this.mainService.setLoadingInfo('DELETING IMPORT DATA...');
      this.mainService.setLoading(true);

      const importDocRef = doc(this.dbModular, 'imports', importResult.id);
      await deleteDoc(importDocRef);

      const promises = [this.deleteFile(importResult.csvFileData)];

      if (importResult.retryCsvFileData) {
        promises.push(this.deleteFile(importResult.retryCsvFileData));
      }

      this.mainService.setLoadingInfo('DELETING CSV FILES...');
      await Promise.all(promises);

      this.mainService.setLoading(false);
      this.snackBarService.openBlueSnackBar(`IMPORT DELETED SUCCESSFULLY`);
    } catch (error) {
      if (error instanceof Error) {
        this.snackBarService.latestError = error.message;
        this.snackBarService.openRedSnackBar('FAILED TO DELETE IMPORT');
      }
    }
  }

  onFileSelected(event: Event) {
    const allowedExtensions = [
      'doc',
      'docx',
      'txt',
      'xls',
      'xlsx',
      'csv',
      'ppt',
      'pptx',
      'pdf',
      'jpeg',
      'jpg',
      'png',
      'gif',
      'svg',
      'vnd.ms-excel',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'vnd.ms-powerpoint',
      'vnd.openxmlformats-officedocument.presentationml.presentation',
      'vnd.ms-word',
      'vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList)[0];

    if (file) {
      const fileExtension = file.name?.split('.').pop()?.toLowerCase() ?? '';
      const fileType = file.type?.split('/')[1]?.toLowerCase() ?? '';

      if (
        allowedExtensions.includes(fileExtension) ||
        allowedExtensions.includes(fileType)
      ) {
        this.selectedFile = file;
      } else {
        target.value = '';
        this.selectedFile = undefined;
        this.snackBarService.openRedSnackBar('INVALID FILE TYPE');
      }
    }
  }

  async isValidImport(importType: ImportType): Promise<string | undefined> {
    const invalidImportMessage = await this.isValidImportFile(importType);
    if (invalidImportMessage) {
      return invalidImportMessage;
    }

    const { currentUserRole } = this.rolesRightsService;

    if (
      importType === ImportType.MEMBER ||
      importType === ImportType.ADD_ON ||
      importType === ImportType.POL360_TRANSACTION
    ) {
      if (!this.userService.userData?.email?.endsWith('@ioio.co.za')) {
        return 'User does not have permission to import POL360 data';
      }
    } else if (importType === ImportType.NETCASH_TRANSACTION) {
      if (!currentUserRole?.imports?.netcash) {
        return 'User does not have permission to import Netcash transactions';
      }
    } else if (importType === ImportType.EFT_TRANSACTION) {
      if (!currentUserRole?.imports?.eft) {
        return 'User does not have permission to import EFT transactions';
      }
    } else if (importType === ImportType.PAY_AT_TRANSACTION) {
      if (!currentUserRole?.imports?.payAt) {
        return 'User does not have permission to import Pay@ transactions';
      }
    }

    return undefined;
  }

  private isValidImportFile(
    importType: ImportType
  ): Promise<string | undefined> {
    if (!this.selectedFile) return Promise.resolve('No file selected');
    if (
      this.selectedFile.type !== 'text/csv' &&
      this.selectedFile.type !== 'text/tsv'
    )
      return Promise.resolve('Invalid file type');

    const requiredFields: string[] = [];
    switch (importType) {
      case ImportType.MEMBER:
        requiredFields.push(
          'Type',
          'Policy Number',
          'Title',
          'First Name',
          'Last Name',
          'ID Number',
          'Relation',
          'Gender',
          'Birth Date',
          'Entry Age',
          'Current Age',
          'Date Captured',
          'Inception Date',
          'Waiting Period',
          'Policy From Date',
          'Policy Code',
          'Policy Description',
          'Policy Age From',
          'Policy Age To',
          'Premium',
          'U/W Premium',
          'Cover',
          'Underwriter',
          'Status',
          'Status From Date',
          'Broker',
          'Branch',
          'Agent',
          'Cell Number',
          'Payment Type',
          'Employee Number',
          'No Of Payments',
          'First Fiscal Period',
          'Last Fiscal Period',
          'First Transaction Date',
          'Last Transaction Date',
          'Running Balance',
          'Deduction Date',
          'Addtional Products',
          'Pay@Number',
          'Premium Escalation %',
          'Cover Escalation %',
          'Res Address 1',
          'Res Address 2',
          'Res Address 3',
          'Res Code'
        );
        break;
      case ImportType.ADD_ON:
        requiredFields.push(
          'Policy Number',
          'First Name',
          'Last Name',
          'ID Number',
          'Capture Date Time',
          'Inception Date',
          'Broker Name',
          'Branch Name',
          'Additional Product From Date',
          'Additional Product To Date',
          'Additional Product Description',
          'Additional Product Premium',
          'Additional Product Benefit Amount',
          'Payment Type',
          'Status',
          'User Name',
          'Benefit Number Comment',
          'Benefit Branch Comment'
        );
        break;
      case ImportType.POL360_TRANSACTION:
        requiredFields.push(
          'Policy Number',
          'Member Type',
          'First Name',
          'Last Name',
          'ID Number',
          'Member Age',
          'Date Captured',
          'Inception Date',
          'Fiscal Period',
          'Transaction Type',
          'Total Amount Paid',
          'Base Premium',
          'Add Prod Base Premium',
          'Total Base Premium',
          'Total Underwriter Amount Paid',
          'Underwriter Base Premium',
          'Underwriter Add Prod Base Premium',
          'Total Underwriter Base Premium',
          'Cover',
          'Transaction Date',
          'Narration',
          'Num Payments',
          'Total Paid',
          'Running Balance',
          'Cell Number',
          'Email',
          'Inception',
          'Broker Code',
          'Branch',
          'Agent',
          'Status',
          'Payment Type',
          'Ded Day / Emp Num',
          'Policy Code',
          'Policy Description',
          'Policy Age From',
          'Policy Age To',
          'Lives Insured',
          'Last Status Reason',
          'Employer',
          'Bank Name',
          'Branch Code',
          'Account Number',
          'Account Type',
          'DebiCheck ref.',
          'Underwriter',
          'Client'
        );
        break;
    }

    if (importType.startsWith('POL360')) {
      return new Promise<string | undefined>((resolve) => {
        Papa.parse(this.selectedFile as any, {
          header: true,
          preview: 1,
          skipEmptyLines: true,
          complete: (results) => {
            const actualFields = results.meta.fields;
            const missingFields = requiredFields.filter(
              (field) => !actualFields?.includes(field)
            );
            if (missingFields.length > 0) {
              resolve(`Missing required fields: ${missingFields.join(', ')}`);
            } else {
              resolve(undefined);
            }
          },
          error: (error) => {
            console.error('Error parsing file:', error);
            resolve(`Error parsing file: ${error.message}`);
          },
        });
      });
    } else {
      return new Promise<string | undefined>((resolve) => {
        Papa.parse(this.selectedFile as any, {
          header: false,
          preview: 2,
          skipEmptyLines: true,
          complete: (results) => {
            if (importType === ImportType.PAY_AT_TRANSACTION) {
              const isFileNameValid = this.selectedFile?.name
                ? this.isValidPayAtCSVName(this.selectedFile.name)
                : false;
              const result: any = results.data[1];
              const isTypeValid = result[0] === 'D';
              const isPayAtNumberValid = result[3].startsWith(
                environment.payAtPrefix
              );
              const isAmountValid = !isNaN(parseFloat(result[5]));
              const isPaymentMethodValid =
                result[10] === 'Cash' ||
                result[10] === 'CreditCard' ||
                result[10] === 'DebitCard';

              if (!isFileNameValid) {
                resolve('Invalid file name');
              } else if (!isTypeValid) {
                resolve('Invalid type in file');
              } else if (!isPayAtNumberValid) {
                resolve('Invalid Pay@ number');
              } else if (!isAmountValid) {
                resolve('Invalid amount');
              } else if (!isPaymentMethodValid) {
                resolve('Invalid payment method');
              } else {
                resolve(undefined);
              }
            } else if (importType === ImportType.EFT_TRANSACTION) {
              const result: any = results.data[0];
              const isAmountValid = !isNaN(parseFloat(result[2]));
              let isValidTransactionDate = false;

              try {
                isValidTransactionDate =
                  this.dateTimeService.stringToTimestamp(result[5]) instanceof
                  Timestamp;
              } catch (err) {
                isValidTransactionDate = false;
              }

              if (!isAmountValid) {
                resolve('Invalid amount');
              } else if (!isValidTransactionDate) {
                resolve('Invalid transaction date');
              } else {
                resolve(undefined);
              }
            } else if (importType === ImportType.NETCASH_TRANSACTION) {
              let result: any = results.data[1];

              if (results.data.length === 1) {
                result = results.data[0];
              }

              const isValidCode = result[1]?.length === 3;
              const isAmountValid = !isNaN(parseFloat(result[4]));
              const isValidSymbol = result[5] === '+' || result[5] === '-';
              let isValidTransactionDate = false;

              try {
                isValidTransactionDate =
                  this.dateTimeService.stringToTimestamp(result[0]) instanceof
                  Timestamp;
              } catch (err) {
                isValidTransactionDate = false;
              }

              if (!isValidCode) {
                resolve('Invalid code length');
              } else if (!isAmountValid) {
                resolve('Invalid amount');
              } else if (!isValidTransactionDate) {
                resolve('Invalid transaction date');
              } else if (!isValidSymbol) {
                resolve('Invalid symbol');
              } else {
                resolve(undefined);
              }
            }
          },
          error: (error) => {
            console.error('Error parsing file:', error);
            resolve(`Error parsing file: ${error.message}`);
          },
        });
      });
    }
  }

  isValidPayAtCSVName(fileName: string): boolean {
    const payAtPrefix = environment.payAtPrefix;
    const regex = new RegExp(
      `^UPLOAD-${payAtPrefix}-\\d{4}(0[1-9]|1[0-2])(0[1-9]|[12]\\d|3[01]).*`
    );
    return regex.test(fileName);
  }

  importTypeToAction(importType: ImportType): string {
    switch (importType) {
      case ImportType.MEMBER:
        return '@ioio.co.za';
      case ImportType.ADD_ON:
        return '@ioio.co.za';
      case ImportType.POL360_TRANSACTION:
        return '@ioio.co.za';
      case ImportType.NETCASH_TRANSACTION:
        return 'netcash';
      case ImportType.EFT_TRANSACTION:
        return 'eft';
      case ImportType.PAY_AT_TRANSACTION:
        return 'payAt';
    }
  }
}
