export interface CreatedBy {
  uid?: string | null;
  displayName?: string | null;
  email?: string | null;
  cellNumber?: string;
  userLocationId?: string;
}

export interface UpdatedBy {
  uid?: string | null;
  displayName?: string | null;
  email?: string | null;
  cellNumber?: string;
  userLocationId?: string;
}

export interface UserLocation {
  id?: string;
  name?: string;
  code?: string;
  status?: string;
}

export interface CRUD {
  read?: boolean;
  update?: boolean;
  delete?: boolean;
}

export enum LocationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum LocationName {
  HQS = 'HQS',
  TZN = 'TZN',
  NLA = 'NLA',
  SIM = 'SIM',
  COF = 'COF',
  LUS = 'LUS',
  TDL = 'TDL',
  MLM = 'MLM',
  GYO = 'GYO',
  GYC = 'GYC',
  PHB = 'PHB',
  SMK = 'SMK',
  SMO = 'SMO',
  LTT = 'LTT',
  LMO = 'LMO',
  MAN = 'MAN',
  DAL = 'DAL',
  ELM = 'ELM',
  IND = 'IND',
  PLK = 'PLK',
  PLP = 'PLP',
  MTB = 'MTB',
  MAF = 'MAF',
  BFO = 'BFO',
  BFC = 'BFC',
  BBR = 'BBR',
  GRS = 'GRS',
  HAZ = 'HAZ',
  THL = 'THL',
  ACR = 'ACR',
  CTR = 'CTR',
  ROS = 'ROS',
}

export enum LocationCode {
  HEAD_QUARTERS = 'HEAD QUARTERS',
  TZANEEN_OFFICE = 'TZANEEN OFFICE',
  NLA_OFFICE = 'NLA OFFICE',
  SIMBA_COMPLEX = 'SIMBA COMPLEX',
  COFFIN_FACTORY = 'COFFIN FACTORY',
  LUSHOF_DEPOT = 'LUSHOF DEPOT',
  TRICHARDTSDAL = 'TRICHARDTSDAL',
  MALAMULELE = 'MALAMULELE',
  GIYANI_OFFICE = 'GIYANI OFFICE',
  GIYANI_CBD = 'GIYANI CBD',
  PHALABORWA_OFFICE = 'PHALABORWA OFFICE',
  SOEKMEKAAR_OFFICE = 'SOEKMEKAAR OFFICE',
  SOEKMEKAAR_MORTUARY = 'SOEKMEKAAR MORTUARY',
  LOUIS_TRICHARD_OFFICE = 'LOUIS TRICHARD OFFICE',
  LOUIS_TRICHARD_MORTUARY = 'LOUIS TRICHARD MORTUARY',
  MANKWENG = 'MANKWENG',
  DALMADA_PLOT = 'DALMADA PLOT',
  ELIM_OFFICE = 'ELIM OFFICE',
  POLOKWANE_IND = 'POLOKWANE IND',
  POLOKWANE_CBD = 'POLOKWANE CBD',
  POLOKWANE_PLOT = 'POLOKWANE PLOT',
  MATHABATHA_OFFICE = 'MATHABATHA OFFICE',
  MAFEFE_OFFICE = 'MAFEFE OFFICE',
  BURGERSFORT_OFFICE = 'BURGERSFORT OFFICE',
  BURGERSFORT_CBD = 'BURGERSFORT CBD',
  BUSHBUCKRIDGE_OFFICE = 'BUSHBUCKRIDGE OFFICE',
  GRASKOP_OFFICE = 'GRASKOP OFFICE',
  HAZYVIEW_OFFICE = 'HAZYVIEW OFFICE',
  THULAMAHASHE_OFFICE = 'THULAMAHASHE OFFICE',
  ACORNHOEK_OFFICE = 'ACORNHOEK OFFICE',
  CONTROL_ROOM = 'CONTROL ROOM',
  ROSSLYN = 'ROSSLYN',
}
