import { EventEmitter, Injectable } from '@angular/core';
import {
  Role,
  RoleRights,
  RoleWithRightsIndex,
  User,
} from '../models/user.model';
import { SnackBarService } from './snack-bar.service';
import { Observable, Subject } from 'rxjs';
import { MainService } from './main.service';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class RolesRightsService {
  private dbModular = getFirestore();

  selectedRole: RoleWithRightsIndex | undefined;
  allRoles: Role[] | undefined;
  currentUserRole: RoleWithRightsIndex | undefined;

  roleDoc: DocumentReference<RoleWithRightsIndex>;
  selectedRole$: Observable<RoleWithRightsIndex | undefined>;

  userRoleDoc: DocumentReference<RoleWithRightsIndex>;
  currentUserRole$: Observable<RoleWithRightsIndex | undefined>;

  selectedRoleUpdated = new EventEmitter<void>();
  currentRoleUpdated = new EventEmitter<void>();

  destroy$ = new Subject<void>();

  // Form state
  loading = false;
  success = false;
  connected = true;

  constructor(
    private snackBarService: SnackBarService,
    private mainService: MainService
  ) {}

  ngOnInit() {}

  // Sets the selectedRole property to the given role
  async setSelectedRole(docId?: string): Promise<void> {
    try {
      if (!docId) {
        throw new Error('No Role ID provided');
      }

      sessionStorage.setItem('selectedRoleId', docId);

      const roleDocRef = doc(this.dbModular, 'roles', docId);

      return new Promise<void>((resolve, reject) => {
        const unsubscribe = onSnapshot(
          roleDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              this.selectedRole = {
                ...(docSnapshot.data() as RoleWithRightsIndex),
                id: docSnapshot.id,
              };
              this.selectedRoleUpdated.emit();
              resolve();
            } else {
              this.snackBarService.openRedSnackBar('ROLE NOT FOUND');
              reject(new Error('ROLE NOT FOUND'));
            }
          },
          (error) => {
            if (error instanceof Error) {
              this.snackBarService.latestError = error.message;
              this.snackBarService.openRedSnackBar(
                'SETTING THE SELECTED ROLE FAILED!'
              );
              reject(error);
            }
          }
        );

        this.destroy$.subscribe(() => unsubscribe());
      });
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar(
          'SETTING THE SELECTED ROLE FAILED!'
        );
      }
      throw err;
    }
  }

  async loadRoles(): Promise<void> {
    try {
      const rolesCollectionRef = collection(this.dbModular, 'roles');

      return new Promise<void>((resolve, reject) => {
        const unsubscribe = onSnapshot(
          rolesCollectionRef,
          (snapshot) => {
            const roles = snapshot.docs.map((doc) => ({
              ...(doc.data() as Role),
              id: doc.id,
            }));
            this.setAllRoles(roles);
            resolve();
          },
          (error) => {
            if (error instanceof Error) {
              this.snackBarService.latestError = error.message;
              this.snackBarService.openRedSnackBar('LOADING ROLES FAILED!');
              reject(error);
            }
          }
        );

        this.destroy$.subscribe(() => unsubscribe());
      });
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('LOADING ROLES FAILED!');
        throw err;
      }
    }
  }

  // Sets the allRoles property to the given array of roles
  setAllRoles(roles: Role[]) {
    this.allRoles = roles;
  }

  // Returns the ID of the role with the given name.
  getRoleIdByName(roleName: string) {
    if (this.allRoles) {
      return this.allRoles.find((role) => role.name === roleName)?.id;
    } else return undefined;
  }

  // Returns the ID of the role with the given name.
  getRoleNameById(roleId?: string) {
    let roleName;
    if (this.allRoles && roleId) {
      roleName = this.allRoles.find((role) => role.id === roleId)?.name;
      if (roleName) return roleName;
    }
    return '-';
  }

  copyEmailDomain(email?: string | null) {
    if (email === null || email === undefined) {
      return '';
    }

    let atPosition = email.indexOf('@');
    if (atPosition !== -1) {
      let copiedText = email.substring(atPosition);
      return copiedText;
    } else {
      return '';
    }
  }

  // Sets the current user's role and subscribes to changes
  async setCurrentUserRole(docId?: string): Promise<void> {
    try {
      if (!docId) {
        throw new Error('No current user role ID provided');
      }

      sessionStorage.setItem('currentUserRoleId', docId);

      const userRoleDocRef = doc(this.dbModular, 'roles', docId);

      return new Promise<void>((resolve, reject) => {
        const unsubscribe = onSnapshot(
          userRoleDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const role = {
                ...(docSnapshot.data() as RoleWithRightsIndex),
                id: docSnapshot.id,
              };

              if (role.status !== 'ACTIVE') {
                this.currentUserRole = this.createInactiveRole(role);
              } else {
                this.currentUserRole = role;
                this.currentRoleUpdated.emit();
              }
              resolve();
            } else {
              this.snackBarService.openRedSnackBar(
                'No matching role found for current user'
              );
              reject(new Error('No matching role found for current user'));
            }
          },
          (error) => {
            if (error instanceof Error) {
              this.snackBarService.latestError = error.message;
              reject(error);
            }
          }
        );

        this.destroy$.subscribe(() => unsubscribe());
      });
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        if (!this.currentUserRole) {
          this.mainService.initError = 'YOU CURRENTLY HAVE NO ROLES/RIGHTS';
        }
        this.snackBarService.openRedSnackBar(
          'SETTING THE CURRENT USER ROLE FAILED!'
        );
        throw err;
      }
    }
  }

  async updateRole(role: Role) {
    this.loading = true;
    try {
      if (!role.id) {
        throw new Error('Role ID not provided');
      }

      const roleDocRef = doc(this.dbModular, 'roles', role.id);
      await setDoc(roleDocRef, role);
      this.snackBarService.openBlueSnackBar('ROLE UPDATED SUCCESSFULLY!');
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('ROLE UPDATE FAILED!');
      }
    } finally {
      this.loading = false;
    }
  }

  async createRole(newRole: any) {
    this.loading = true;
    try {
      const rolesCollectionRef = collection(this.dbModular, 'roles');
      const docRef = await addDoc(rolesCollectionRef, newRole);

      await this.setSelectedRole(docRef.id);
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('ROLE CREATION FAILED!');
      }
    } finally {
      this.loading = false;
    }
  }

  async deleteRole(roleId: string | undefined) {
    if (roleId) {
      this.loading = true;

      try {
        const isRoleAssigned = await this.roleIsAssigned();
        if (isRoleAssigned) {
          this.snackBarService.openRedSnackBar(
            'ROLE CANNOT BE DELETED! IT IS CURRENTLY ASSIGNED TO A USER.'
          );
        } else {
          const roleDocRef = doc(this.dbModular, 'roles', roleId);
          await deleteDoc(roleDocRef);
          this.snackBarService.openBlueSnackBar('ROLE DELETED SUCCESSFULLY!');

          if (this.currentUserRole?.id === roleId) {
            this.currentUserRole = undefined;
          }
          if (this.selectedRole?.id === roleId) {
            this.selectedRole = undefined;
            this.selectedRoleUpdated.emit();
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          this.snackBarService.latestError = err.message;
          this.snackBarService.openRedSnackBar('ROLE DELETION FAILED!');
        }
      } finally {
        this.loading = false;
      }
    }
  }

  async roleIsAssigned(): Promise<boolean> {
    if (!this.selectedRole?.id) {
      return false;
    }

    try {
      const usersCollectionRef = collection(this.dbModular, 'users');
      const q = query(
        usersCollectionRef,
        where('roleId', '==', this.selectedRole.id)
      );
      const usersSnapshot = await getDocs(q);

      return !usersSnapshot.empty;
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar(
          'CHECKING ROLE ASSIGNMENT FAILED!'
        );
      }
      return false;
    }
  }

  userRoleNameExists(roleName: string) {
    return this.allRoles?.some((role) => role.name === roleName);
  }

  hasAccess(url: string, currentUserData?: User): boolean {
    if (!currentUserData) {
      return false;
    }

    const currentUserRole = this.currentUserRole;

    // Check for reading policies
    if (
      (url.startsWith('/policies') ||
        url.startsWith('/policy-summary') ||
        url.startsWith('/comments') ||
        url.startsWith('/messages') ||
        url.startsWith('/policy-pdf') ||
        url.startsWith('/files') ||
        url.startsWith('/policy-logs')) &&
      currentUserRole?.policies?.read
    ) {
      return true;
    }

    // Check for reading products
    if (
      (url.startsWith('/products') ||
        url.startsWith('/plan-member-management') ||
        url.startsWith('/add-on-management') ||
        url.startsWith('/settings')) &&
      currentUserRole?.products?.read
    ) {
      return true;
    }

    // Check for reading users
    if (
      (url.startsWith('/users') || url.startsWith('/settings')) &&
      currentUserRole?.users?.read
    ) {
      return true;
    }
    // Check for users location management
    if (
      url.startsWith('/location-management') &&
      currentUserRole?.users?.locationManagement
    ) {
      return true;
    }

    // Check for reading transactions
    if (
      (url.startsWith('/transactions') ||
        url.startsWith('/transaction-history-pdf') ||
        url.startsWith('/transaction-summary') ||
        url.startsWith('/transaction-logs') ||
        url.startsWith('/daily-transactions') ||
        url.startsWith('/transaction-history')) &&
      currentUserRole?.transactions?.read
    ) {
      return true;
    }

    // Check for updating transactions
    if (
      url.startsWith('/offline-transactions') &&
      currentUserRole?.transactions?.update
    ) {
      return true;
    }

    // Check for reading debit orders
    if (
      url.startsWith('/debit-order-history') &&
      currentUserRole?.debitOrders?.read
    ) {
      return true;
    }

    // Check for updating policies
    if (
      (url.startsWith('/policy-details') ||
        url.startsWith('/policy-member-details') ||
        // url.startsWith('/policy-files') ||
        url.startsWith('/policy-confirm')) &&
      currentUserRole?.policies?.update
    ) {
      return true;
    }

    // Check for updating users
    if (
      (url.startsWith('/user-management/') ||
        url.startsWith('/roles-rights-management')) &&
      currentUserRole?.users?.update
    ) {
      return true;
    }

    // Check for exporting transactions or policies
    if (
      (url.startsWith('/report-extraction') ||
        url.startsWith('/report-details') ||
        url.startsWith('/report-fields') ||
        url.startsWith('/report-extracts') ||
        url.startsWith('/reports')) &&
      (currentUserRole?.transactions?.export ||
        currentUserRole?.policies?.export)
    ) {
      return true;
    }

    // Special check for import rights
    if (
      url.startsWith('/import') &&
      (currentUserRole?.imports?.eft ||
        currentUserRole?.imports?.netcash ||
        currentUserRole?.imports?.payAt)
    ) {
      return true;
    }

    // Special check for backup rights
    if (
      url.startsWith('/backups') &&
      this.copyEmailDomain(currentUserData.email) === '@ioio.co.za'
    ) {
      return true;
    }

    // Default to false if none of the conditions are met
    return false;
  }

  createInactiveRole(role: RoleWithRightsIndex): RoleWithRightsIndex {
    const inactiveRole = { ...role };

    const categories: (keyof RoleRights)[] = [
      'users',
      'policies',
      'products',
      'transactions',
      'debitOrders',
      'mobile',
    ];
    categories.forEach((category) => {
      if (inactiveRole[category]) {
        Object.keys(inactiveRole[category] as any).forEach((key: string) => {
          (inactiveRole[category] as any)[key] = false;
        });
      }
    });

    return inactiveRole;
  }

  resetCurrentUserRole() {
    this.currentUserRole = undefined;
  }

  resetSelectedRole() {
    this.selectedRole = undefined;
  }

  cleanUp() {
    this.allRoles = undefined;
    this.resetCurrentUserRole();
    this.resetSelectedRole();
    this.destroy$.next();
  }
}
