import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { MainNavComponent } from './components/master/main-nav/main-nav.component';
import { LoginComponent } from './components/master/login/login.component';
import { FakerMenuComponent } from './components/miscellaneous/faker-menu/faker-menu.component';
import { PolicyStepperComponent } from './components/policy-management/policy-stepper/policy-stepper.component';
import { ReportStepperComponent } from './components/report/report-stepper/report-stepper.component';
import { CustomStepperComponent } from './components/miscellaneous/custom-stepper/custom-stepper.component';
import { DecimalOnlyDirective } from './directives/decimal-only.directive';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CookieService } from 'ngx-cookie-service';
import { CustomSpinnerComponent } from './components/miscellaneous/custom-spinner/custom-spinner.component';
import { initializeApp } from 'firebase/app';
import { MainReportStepperComponent } from './components/report/main-report-stepper/main-report-stepper.component';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    LoginComponent,
    FakerMenuComponent,
    PolicyStepperComponent,
    CustomStepperComponent,
    DecimalOnlyDirective,
    ReportStepperComponent,
    CustomSpinnerComponent,
    MainReportStepperComponent,
  ],
  imports: [AppRoutingModule, SharedModule, BrowserAnimationsModule],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB', // or 'en-US', 'fr-FR', etc.
    },
    CookieService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
