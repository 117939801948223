import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { UserService } from 'src/app/services/user.service';
import { appVersion } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'src/app/services/validation.service';
import { LocationStatus, UserLocation } from 'src/app/models/general.model';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate('750ms ease-in-out')),
    ]),
    trigger('fadeOut', [
      state('*', style({ opacity: 1 })),
      transition(
        '* => void',
        animate('750ms ease-in-out', style({ opacity: 0 }))
      ),
    ]),
  ],
})
export class LoginComponent {
  loginForm: FormGroup;
  signUpForm: FormGroup;
  accountDetailsForm: FormGroup;
  forgotPasswordForm: FormGroup;
  setCurrentLocationForm: FormGroup;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  userLocations: UserLocation[] = [];

  public environment = environment;
  public version = appVersion;

  LocationStatus = LocationStatus;

  screenSize = { width: window.innerWidth, height: window.innerHeight };

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    public validationService: ValidationService,
    private mainService: MainService,
    private router: Router,
    private snackBar: SnackBarService
  ) {}

  @HostListener('window:resize')
  onResize() {
    this.screenSize.width = window.innerWidth;
    this.screenSize.height = window.innerHeight;
  }

  async ngOnInit() {
    // Initialize form groups and set default values
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.minLength(6), Validators.required]],
    });
    this.signUpForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.minLength(6), Validators.required]],
      passwordConfirm: ['', [Validators.minLength(6), Validators.required]],
    });
    this.accountDetailsForm = this.fb.group({
      displayName: ['', [Validators.required]],
    });
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.setCurrentLocationForm = this.fb.group({
      userLocationId: ['', [Validators.required]],
    });
    if (this.userService.isSignUpPassword) {
      if (window.localStorage.getItem('emailForSignIn') !== null) {
        const email = window.localStorage.getItem('emailForSignIn');
        this.signUpForm.get('userLocationId')?.setValue(email);
      }
    }
  }

  // Method to submit the login form on enter key press
  submitLoginForm(event: KeyboardEvent) {
    event.preventDefault(); // Prevents default behavior of Enter key
    const email = this.loginForm.get('email')?.value;
    const password = this.loginForm.get('password')?.value;
    this.userService.logIn(email, password);
  }

  // Method to toggle password visibility
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  // Method to toggle confirm password visibility
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  // Method to toggle the 'forgot password' state
  toggleForgotPassword() {
    this.userService.isForgotPassword = !this.userService.isForgotPassword;
    const emailControl = this.forgotPasswordForm.get('email');
    const loginEmailValue = this.loginForm.get('email')?.value;
    if (emailControl && loginEmailValue) {
      emailControl.setValue(loginEmailValue);
    }
  }

  // Method to update account details
  async updateAccountDetails(displayName: string) {
    const user = this.userService.userData; // Get user data from user service
    try {
      if (user === undefined || user === null)
        throw new Error('USER NOT FOUND');
      user.displayName = displayName; // Update display name from input
      user.status = 'ACTIVE'; // Set status to active
      await this.userService.setUserData(user); // Update user data in user service
    } catch (error) {
      // Show an error message using the SnackBarService
      this.snackBar.openRedSnackBar(
        'THERE WAS AN ERROR WITH UPDATING YOUR ACCOUNT'
      );
    }
  }

  // Method to send forgot password email
  forgotPassword(email: string) {
    try {
      this.userService.forgotPassword(email);
      this.userService.isForgotPassword = false;
      // Show a success message using the SnackBarService
      this.snackBar.openBlueSnackBar('PASSWORD RESET LINK SENT SUCCESSFULLY!');
    } catch (error) {
      // Show an error message using the SnackBarService
      this.snackBar.openRedSnackBar(
        'THERE WAS AN ERROR SENDING YOUR PASSWORD RESET LINK'
      );
    }
  }

  // Getters to get password and confirm password form controls
  get password() {
    return this.signUpForm.get('password');
  }

  get passwordConfirm() {
    return this.signUpForm.get('passwordConfirm');
  }

  // Method to check if password matches confirm password
  get passwordDoesMatch() {
    if (!this.userService.isSignUpPassword) {
      return true;
    } else {
      return this.password?.value === this.passwordConfirm?.value;
    }
  }

  async setUserLocation() {
    const userLocationId =
      this.setCurrentLocationForm.get('userLocationId')?.value;
    if (userLocationId != this.userService.userData?.currentUserLocationId) {
      await this.userService.setUserData({
        ...this.userService.userData,
        currentUserLocationId: userLocationId,
      });
    }
    this.userService.isSelectingLocation = false;
    this.userService.selectedUserLocationId = userLocationId;
    localStorage.removeItem('selectedPolicyId');
    this.router.navigate(['/home']);
    setTimeout(() => {
      this.router.navigate([this.mainService.getPreviousRoute()]);
    }, 1500);
  }

  async logOut() {
    await this.userService.signOut();
    this.userService.isSelectingLocation = false;
    this.userService.isCurrentlyLoggedIn = false;
  }
}
