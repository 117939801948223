import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component } from '@angular/core';
import { RolesRightsService } from 'src/app/services/roles-rights.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate('500ms ease-in-out')),
    ]),
  ],
})
export class SettingsComponent {
  constructor(
    public rolesRightsService: RolesRightsService,
    public userService: UserService
  ) {}

  get canImport(): boolean {
    return (
      this.rolesRightsService.currentUserRole?.imports?.eft === true ||
      this.rolesRightsService.currentUserRole?.imports?.netcash === true ||
      this.rolesRightsService.currentUserRole?.imports?.payAt === true ||
      this.rolesRightsService.copyEmailDomain(
        this.userService.userData?.email
      ) === '@ioio.co.za'
    );
  }
}
