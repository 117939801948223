import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[inputMaxLength]',
})
export class MaxLengthDirective {
  @Input() inputMaxLength: number;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    const transformedValue = target.value.toUpperCase();

    if (transformedValue.length > this.inputMaxLength) {
      target.value = transformedValue.slice(0, this.inputMaxLength);
    } else {
      target.value = transformedValue;
    }
  }
}
