import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { PolicyLogService } from 'src/app/services/policy-log.service';
import { PolicyService } from 'src/app/services/policy.service';
import { RolesRightsService } from 'src/app/services/roles-rights.service';

@Component({
  selector: 'policy-stepper',
  templateUrl: './policy-stepper.component.html',
  styleUrls: ['./policy-stepper.component.scss'],
})
export class PolicyStepperComponent {
  showStepper = false;
  selectedIndex = 0;

  constructor(
    private router: Router,
    public policyService: PolicyService,
    public policyLogService: PolicyLogService,
    public rolesRightsService: RolesRightsService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.reloadStepperState();
      });
  }

  ngOnInit() {
    this.reloadStepperState();
  }

  reloadStepperState() {
    const currentRoute = this.router.url;
    this.showStepper =
      currentRoute.startsWith('/policy-details') ||
      currentRoute.startsWith('/policy-member-details') ||
      currentRoute.startsWith('/policy-confirm');

    if (this.showStepper) {
      if (currentRoute.startsWith('/policy-details')) {
        this.selectedIndex = 0;
      } else if (currentRoute.startsWith('/policy-member-details')) {
        this.selectedIndex = 1;
      } else if (currentRoute.startsWith('/policy-confirm')) {
        this.selectedIndex = 2;
      }
    }
  }

  onRouteChange(selectedIndex: number): void {
    switch (selectedIndex) {
      case 0:
        this.router.navigate([
          `/policy-details`,
          this.policyService.selectedPolicy?.id,
        ]);
        break;
      case 1:
        this.router.navigate([
          '/policy-member-details',
          this.policyService.selectedPolicy?.id,
        ]);
        break;
      case 2:
        this.router.navigate([
          '/policy-confirm',
          this.policyService.selectedPolicy?.id,
        ]);
        break;
    }
  }
}
