import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
  pure: true,
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text?: string | number, search?: string | number): SafeHtml {
    if (!text || !search) {
      return (text || '').toString();
    }

    const textStr = text.toString();
    const searchStr = this.prepareSearchString(search.toString());
    const regex = new RegExp(searchStr, 'gi');

    const highlighted = `<div>${textStr.replace(
      regex,
      (match) => `<span class="highlight">${match}</span>`
    )}</div>`;
    return this.sanitizer.bypassSecurityTrustHtml(highlighted);
  }

  // Move the logic into the transform method or make these methods static
  prepareSearchString(value: string): string {
    if (this.isNumeric(value)) {
      return value
        .split('')
        .map((char) => `${char}\\s*`)
        .join('');
    } else {
      return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }
  }

  isNumeric(value: string): boolean {
    return /^\d+$/.test(value);
  }
}
