import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './components/master/login/login.component';
import { RightsGuard } from './rights.guard';
const routes: Routes = [
  {
    path: 'create-password',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },

  { path: '', redirectTo: 'home', pathMatch: 'full' },

  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },

  {
    path: 'home',
    loadChildren: () =>
      import('./components/master/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'policies',
    loadChildren: () =>
      import(
        './components/policy-management/policy-main/policy-main.module'
      ).then((m) => m.PolicyMainModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'policy-details/:policyId',
    loadChildren: () =>
      import(
        './components/policy-management/policy-details/policy-details.module'
      ).then((m) => m.PolicyDetailsModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'policy-member-details/:policyId',
    loadChildren: () =>
      import(
        './components/policy-management/policy-member-details/policy-member-details.module'
      ).then((m) => m.PolicyMemberDetailsModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'policy-confirm/:policyId',
    loadChildren: () =>
      import(
        './components/policy-management/policy-confirm/policy-confirm.module'
      ).then((m) => m.PolicyConfirmModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'policy-summary/:policyId',
    loadChildren: () =>
      import(
        './components/policy-management/policy-summary/policy-summary.module'
      ).then((m) => m.PolicySummaryModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'policy-logs/:policyId',
    loadChildren: () =>
      import(
        './components/policy-management/policy-logs/policy-logs.module'
      ).then((m) => m.PolicyLogsModule),
    canActivate: [RightsGuard],
  },
  // {
  //   path: 'policy-files/:policyId',
  //   loadChildren: () =>
  //     import('./components/policy-management/file/file.module').then(
  //       (m) => m.FileModule
  //     ),
  //   canActivate: [RightsGuard],
  // },
  {
    path: 'policy-pdf/:policyId',
    loadChildren: () =>
      import(
        './components/policy-management/policy-pdf/policy-pdf.module'
      ).then((m) => m.PolicyPDFModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'files/:policyId',
    loadChildren: () =>
      import('./components/policy-management/file/file.module').then(
        (m) => m.FileModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./components/master/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./components/user-management/user-main/user-main.module').then(
        (m) => m.UserMainModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: 'user-management/:uid',
    loadChildren: () =>
      import(
        './components/user-management/user-details/user-details.module'
      ).then((m) => m.UserDetailsModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'location-management',
    loadChildren: () =>
      import(
        './components/user-management/location-management/location-management.module'
      ).then((m) => m.LocationManagementModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'products',
    loadChildren: () =>
      import(
        './components/product-management/product-main/product-main.module'
      ).then((m) => m.ProductMainModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'plan-member-management/:planId',
    loadChildren: () =>
      import(
        './components/product-management/plan-member-management/plan-member-management.module'
      ).then((m) => m.PlanMemberManagementModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'add-on-management/:addOnId',
    loadChildren: () =>
      import(
        './components/product-management/add-on-management/add-on-management.module'
      ).then((m) => m.AddOnManagementModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'roles-rights-management',
    loadChildren: () =>
      import(
        './components/roles-rights-management/roles-rights-management.module'
      ).then((m) => m.RolesRightsManagementModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'comments/:policyId',
    loadChildren: () =>
      import('./components/policy-management/comment/comment.module').then(
        (m) => m.CommentModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: 'messages/:policyId',
    loadChildren: () =>
      import('./components/miscellaneous/message/message.module').then(
        (m) => m.MessageModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: 'transactions',
    loadChildren: () =>
      import(
        './components/transaction-management/transactions-main/transactions-main.module'
      ).then((m) => m.TransactionsMainModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'transaction-history/:policyId',
    loadChildren: () =>
      import(
        './components/transaction-management/transaction-history/transaction-history.module'
      ).then((m) => m.TransactionHistoryModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'transaction-logs/:policyId',
    loadChildren: () =>
      import(
        './components/transaction-management/transaction-logs/transaction-logs.module'
      ).then((m) => m.TransactionLogsModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'app-dialog',
    loadChildren: () =>
      import('./components/miscellaneous/dialog/dialog.module').then(
        (m) => m.ProductDialogModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: 'imports',
    loadChildren: () =>
      import(
        './components/miscellaneous/import-management/import-main/import-main.module'
      ).then((m) => m.ImportMainModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'import-result/:importId',
    loadChildren: () =>
      import(
        './components/miscellaneous/import-management/import-result/import-result.module'
      ).then((m) => m.ImportResultModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'transaction-summary/:transactionId',
    loadChildren: () =>
      import(
        './components/transaction-management/transaction-summary/transaction-summary.module'
      ).then((m) => m.TransactionSummaryModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'transaction-history-pdf/:policyId',
    loadChildren: () =>
      import(
        './components/transaction-management/transaction-history-pdf/transaction-history-pdf.module'
      ).then((m) => m.TransactionHistoryPDFModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'daily-transactions/:uid/:date/:filter',
    loadChildren: () =>
      import(
        './components/transaction-management/daily-transactions/daily-transactions.module'
      ).then((m) => m.DailyTransactionsModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'offline-transactions',
    loadChildren: () =>
      import(
        './components/transaction-management/offline-transactions/offline-transactions.module'
      ).then((m) => m.OfflineTransactionsModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'debit-order-history',
    loadChildren: () =>
      import(
        './components/transaction-management/debit-order-history/debit-order-history.module'
      ).then((m) => m.DebitOrderHistoryModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./components/report/reports-main/reports-main.module').then(
        (m) => m.ReportsMainModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: 'report-details/:reportId',
    loadChildren: () =>
      import('./components/report/report-details/report-details.module').then(
        (m) => m.ReportDetailsModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: 'report-fields/:reportId',
    loadChildren: () =>
      import('./components/report/report-fields/report-fields.module').then(
        (m) => m.ReportFieldsModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: 'report-extraction/:reportId',
    loadChildren: () =>
      import(
        './components/report/report-extraction/report-extraction.module'
      ).then((m) => m.ReportExtractionModule),
    canActivate: [RightsGuard],
  },
  {
    path: 'report-extracts',
    loadChildren: () =>
      import('./components/report/report-extracts/report-extracts.module').then(
        (m) => m.ReportExtractModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: 'backups',
    loadChildren: () =>
      import('./components/backup/backup-main/backup-main.module').then(
        (m) => m.BackupMainModule
      ),
    canActivate: [RightsGuard],
  },
  {
    path: '**',
    redirectTo: '/policies',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
