import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ReportService } from 'src/app/services/report.service';
import { RolesRightsService } from 'src/app/services/roles-rights.service';

@Component({
  selector: 'main-report-stepper',
  templateUrl: './main-report-stepper.component.html',
  styleUrls: ['./main-report-stepper.component.scss'],
})
export class MainReportStepperComponent {
  showStepper = false;
  selectedIndex = 0;

  constructor(
    private router: Router,
    public reportService: ReportService,
    public rolesRightsService: RolesRightsService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.reloadStepperState();
      });
  }

  ngOnInit() {
    this.reloadStepperState();
  }

  reloadStepperState() {
    const currentRoute = this.router.url;
    this.showStepper =
      currentRoute.startsWith('/reports') ||
      currentRoute.startsWith('/report-extracts');

    if (this.showStepper) {
      if (currentRoute.startsWith('/reports')) {
        this.selectedIndex = 0;
      } else if (currentRoute.startsWith('/report-extracts')) {
        this.selectedIndex = 1;
      }
    }
  }

  onRouteChange(selectedIndex: number): void {
    switch (selectedIndex) {
      case 0:
        this.router.navigate([`/reports`]);
        break;
      case 1:
        this.router.navigate(['/report-extracts']);
        break;
    }
  }
}
