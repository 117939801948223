import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  Member,
  Policy,
  PolicyAddOn,
  PolicyCount,
  PolicyStatus,
} from '../models/policy.model';
import { MemberPolicySearch, MemberStatus } from '../models/search.model';
import { Transaction, TransactionStatus } from '../models/transaction.model';
import { DateTimeService } from './date-time.service';
import { User, UserStatus } from '../models/user.model';
import { ReportConfig } from '../models/report.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filterClicked = {
    plans: true,
    planMembers: true,
    addOns: true,
    addOnPlans: true,
    policyAddOns: true,
    policyMembers: true,
    users: true,
  };

  policyFilter: PolicyStatus = PolicyStatus.ALL;
  memberFilter: MemberStatus = MemberStatus.ALL;

  unidentifiedReversedFilter = false;
  transactionFilter: TransactionStatus = TransactionStatus.ALL;
  dailyTransactionStatusFilter: boolean = false;
  dailyTransactionMethodFilter: string = 'ONLINE';
  policyTransactionFilter: 'all' | 'premiums' = 'premiums';

  userFilter: UserStatus = UserStatus.ACTIVE;

  primaryMemberFilter: boolean = false;
  primaryMemberTypes: string[];

  ownedReportsFilter: boolean = false;

  @Output() policyTransactionFilterChanged = new EventEmitter<void>();

  constructor(
    private dateTimeService: DateTimeService,
    private cookieService: CookieService
  ) {
    this.initializePolicyTransactionFilter();
  }

  applyReportsFilter(reports: ReportConfig[]): ReportConfig[] {
    if (this.ownedReportsFilter) {
      return reports.filter((report) => report.isPrivate === true);
    } else {
      return reports;
    }
  }

  toggleInactiveFilter(
    dataSourceKey: keyof typeof FilterService.prototype.filterClicked,
    dataSource: MatTableDataSource<any>
  ) {
    if (this.filterClicked.hasOwnProperty(dataSourceKey)) {
      this.filterClicked[dataSourceKey] = !this.filterClicked[dataSourceKey];

      if (this.filterClicked[dataSourceKey]) {
        dataSource.filterPredicate = (data: any) => {
          return (
            data.status !== 'INACTIVE' &&
            data.status !== 'LAPSED' &&
            data.status !== 'CLAIMED'
          );
        };
      } else {
        dataSource.filterPredicate = (data: any) => {
          return data.status;
        };
      }
      dataSource.filter = 'true';
    }
  }

  applyMemberSearchFilter(
    members: MemberPolicySearch[],
    searchText?: string,
    status?: MemberStatus
  ): MemberPolicySearch[] {
    if (status) this.memberFilter = status;

    // Group members by policyId
    const groupedByPolicy: { [key: string]: MemberPolicySearch[] } = {};
    members.forEach((member) => {
      if (!groupedByPolicy[member.policyId]) {
        groupedByPolicy[member.policyId] = [];
      }
      groupedByPolicy[member.policyId].push(member);
    });

    let filteredMembers: MemberPolicySearch[] = [];

    Object.entries(groupedByPolicy).forEach(([policyId, policyMembers]) => {
      const includePolicy =
        this.policyFilter === 'ALL' ||
        policyMembers.some((member) => member.planStatus === this.policyFilter);

      if (!includePolicy) {
        return;
      }
      let policyFilteredMembers = policyMembers.filter((member) =>
        this.memberFilter === 'ALL'
          ? true
          : member.memberStatus === this.memberFilter
      );

      if (searchText) {
        const lowerCaseSearchText = searchText.toLowerCase();
        policyFilteredMembers = policyFilteredMembers.filter((member) => {
          return Object.keys(member)
            .filter(
              (key) =>
                ![
                  'policyId',
                  'memberTypeId',
                  'waitingDate',
                  'memberStatus',
                ].includes(key)
            )
            .some((key) => {
              const value = member[key as keyof MemberPolicySearch];
              return (
                typeof value === 'string' &&
                value.toLowerCase().includes(lowerCaseSearchText)
              );
            });
        });
      }

      const hasValidPrimaryMember =
        !this.primaryMemberFilter ||
        policyMembers.some(
          (member) =>
            this.primaryMemberTypes.includes(member.memberTypeId ?? '') &&
            member.memberStatus !== MemberStatus.INACTIVE
        );

      if (!hasValidPrimaryMember && policyFilteredMembers.length !== 0) {
        const defaultMember = {
          policyId: policyId,
          policyNumber: policyMembers[0].policyNumber,
          plan: policyMembers[0].plan,
          planStatus: policyMembers[0].planStatus,
          idNumber: '',
          firstName: '',
          lastName: '',
          memberTypeId: '',
          memberStatus: '',
          waitingDate: null,
        };
        filteredMembers.push(defaultMember);
      } else {
        filteredMembers.push(
          ...policyFilteredMembers.filter(
            (member) =>
              !this.primaryMemberFilter ||
              (this.primaryMemberTypes.includes(member.memberTypeId ?? '') &&
                member.memberStatus !== MemberStatus.INACTIVE)
          )
        );
      }
    });

    return filteredMembers;
  }

  applyTransactionSearchFilter(
    transactions: Transaction[],
    status?: TransactionStatus
  ): Transaction[] {
    if (status) this.transactionFilter = status;

    let filteredTransactions = transactions.filter((transaction) =>
      this.transactionFilter === TransactionStatus.ALL
        ? transaction.status !== TransactionStatus.TRANSFERRED_OUT
        : transaction.status === this.transactionFilter
    );

    filteredTransactions.sort((a, b) => {
      const aDate =
        this.dateTimeService.algoliaTimestampToDate(a.transactionDate) ||
        new Date(0);
      const bDate =
        this.dateTimeService.algoliaTimestampToDate(b.transactionDate) ||
        new Date(0);

      if (aDate > bDate) {
        return -1;
      } else if (aDate < bDate) {
        return 1;
      }
      return 0;
    });

    return filteredTransactions;
  }

  applyUserSearchFilter(
    users: User[],
    searchText?: string,
    status?: UserStatus
  ): User[] {
    if (status) this.userFilter = status;

    let filteredUsers = users.filter((user) =>
      this.userFilter === 'ALL' ? true : user.status === this.userFilter
    );

    if (searchText) {
      const lowerCaseSearchText = searchText.toLowerCase();
      filteredUsers = filteredUsers.filter((user) => {
        return Object.keys(user)
          .filter(
            (key) =>
              ![
                'uid',
                'status',
                'photoURL',
                'roleId',
                'currentUserLocationId',
              ].includes(key)
          )
          .some((key) => {
            const value = user[key as keyof User];
            return (
              typeof value === 'string' &&
              value.toLowerCase().includes(lowerCaseSearchText)
            );
          });
      });
    }

    filteredUsers.sort((a, b) => {
      const aDate =
        this.dateTimeService.algoliaTimestampToDate(a.updatedOn) || new Date(0);
      const bDate =
        this.dateTimeService.algoliaTimestampToDate(b.updatedOn) || new Date(0);

      if (aDate > bDate) {
        return -1;
      } else if (aDate < bDate) {
        return 1;
      }
      return 0;
    });

    return filteredUsers;
  }

  private initializePolicyTransactionFilter() {
    const filter = this.cookieService.get('policyTransactionFilter');
    if (filter === 'all' || filter === 'premiums') {
      this.policyTransactionFilter = filter;
    } else {
      this.policyTransactionFilter = 'premiums';
      this.updatePolicyTransactionFilter(this.policyTransactionFilter);
    }
  }

  updatePolicyTransactionFilter(status: 'all' | 'premiums') {
    this.policyTransactionFilter = status;
    const expiration = new Date();
    expiration.setTime(expiration.getTime() + 60 * 1000);

    this.cookieService.set('policyTransactionFilter', status, expiration);
    this.policyTransactionFilterChanged.emit();
  }
}
