<ng-container
  *ngIf="
    showStepper &&
    (rolesRightsService.currentUserRole?.policies?.export ||
      rolesRightsService.currentUserRole?.transactions?.export)
  "
>
  <h1 class="pageSideMargins policyNumber">
    {{ "&#8203;" }}
  </h1>
  <custom-stepper
    [steps]="[{ label: 'Report Management' }, { label: 'Report Extracts' }]"
    [(selectedIndex)]="selectedIndex"
    [enabledSteps]="[true, true]"
    (routeChange)="onRouteChange($event)"
  ></custom-stepper>
</ng-container>
