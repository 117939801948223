import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[decimalOnly]',
})
export class DecimalOnlyDirective {
  constructor(private elementRef: ElementRef) {}

  // Method to restrict input to only numeric characters and single decimal point
  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.elementRef.nativeElement.value;

    // Allow numbers and single dot
    let valueWithoutInvalidCharacters = initialValue.replace(/[^0-9.]*/g, '');

    // Remove all dots except the first
    const split = valueWithoutInvalidCharacters.split('.');
    if (split.length > 2) {
      valueWithoutInvalidCharacters = `${split.shift()}.${split.join('')}`;
    }

    this.elementRef.nativeElement.value = valueWithoutInvalidCharacters;

    if (initialValue !== this.elementRef.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
