import { Injectable } from '@angular/core';
import { SnackBarService } from './snack-bar.service';
import { UserService } from './user.service';
import { Timestamp } from '@firebase/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { Message, Messages } from '../models/message.model';
import { PolicyService } from './policy.service';
import { Observable, Subject, catchError, map, takeUntil } from 'rxjs';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  runTransaction,
  where,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private dbModular = getFirestore();

  selectedPolicyMessages: Message | undefined;
  dataSourceMessages: MatTableDataSource<any>;

  selectedPolicyMessages$: Observable<Message | undefined>;

  // Form state
  loading = false;
  success = false;
  policyFilterClicked = true;
  messages: Messages[] = [];

  destroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    public policyService: PolicyService,
    public snackBarService: SnackBarService
  ) {
    this.userService.destroy$.pipe().subscribe(() => this.cleanUp());
  }

  async setSelectedPolicyMessages(policyId: string): Promise<void> {
    if (!policyId) {
      this.snackBarService.openRedSnackBar('POLICY ID NOT PROVIDED!');
      throw new Error('POLICY ID NOT PROVIDED!');
    }

    const messagesRef = collection(this.dbModular, 'message');
    const q = query(messagesRef, where('policyId', '==', policyId));

    this.selectedPolicyMessages$ = new Observable<Message | undefined>(
      (observer) => {
        const unsubscribe = onSnapshot(
          q,
          (querySnapshot) => {
            const messagesArray = querySnapshot.docs
              .filter((doc) => doc.exists())
              .map((doc) => ({
                id: doc.id,
                ...(doc.data() as Message),
              }));

            if (messagesArray.length > 0) {
              observer.next(messagesArray[0]);
            } else {
              this.snackBarService.openRedSnackBar(
                'NO MESSAGES FOUND FOR THE GIVEN POLICY ID'
              );
              observer.next(undefined);
            }
          },
          (error) => {
            observer.error(error);
          }
        );

        return () => unsubscribe();
      }
    );

    await new Promise<void>((resolve, reject) => {
      this.selectedPolicyMessages$.pipe(takeUntil(this.destroy$)).subscribe({
        next: (messageDoc) => {
          if (messageDoc) {
            const storedPolicyId = sessionStorage.getItem('selectedPolicyId');
            if (messageDoc.policyId === storedPolicyId) {
              this.selectedPolicyMessages = messageDoc;
              this.refreshSelectedPolicyMessages();
              resolve();
            }
          } else {
            reject(new Error('No messages available'));
          }
        },
        error: (err) => {
          if (err instanceof Error) {
            this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar(
              'ERROR FETCHING MESSAGES BY POLICY ID'
            );
          }
          reject(err);
        },
      });
    });
  }

  private refreshSelectedPolicyMessages(): void {
    if (this.selectedPolicyMessages) {
      const sortedMessages = this.selectedPolicyMessages.messages
        .slice()
        .sort((a, b) => {
          const aCreatedOn = a.createdOn ? a.createdOn.toDate().getTime() : 0;
          const bCreatedOn = b.createdOn ? b.createdOn.toDate().getTime() : 0;
          return bCreatedOn - aCreatedOn;
        });
      this.dataSourceMessages = new MatTableDataSource(sortedMessages);
      this.messages = sortedMessages;
    }
  }

  private handleError(err: Error) {
    if (err.message) this.snackBarService.latestError = err.message;

    this.snackBarService.openRedSnackBar(
      'FETCHING MESSAGES BY POLICY ID FAILED!'
    );
  }

  getCellNumberDisplayFormat(cellNumber: string) {
    let formattedNumber = cellNumber;

    // Check if the number starts with +27
    if (formattedNumber.startsWith('+27')) {
      // Remove +27 and add 0
      formattedNumber = '0' + formattedNumber.slice(3);
    }
    return formattedNumber;
  }

  async sendSMS(
    phoneNumber: string,
    message: string,
    policyId: string,
    referenceNumber?: string
  ) {
    const createdBy = {
      uid: this.userService.userData?.uid,
      displayName: this.userService.userData?.displayName,
      email: this.userService.userData?.email,
      userLocationId: this.userService.userData?.currentUserLocationId,
    };

    try {
      let apiKey = environment.smsPortalKey;
      let apiSecret = environment.smsPortalSecret;
      let accountApiCredentials = apiKey + ':' + apiSecret;
      let base64Credentials = btoa(accountApiCredentials);

      let requestHeaders = {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
          'Content-Type': 'application/json',
        },
      };

      let requestData = {
        messages: [
          {
            content: message,
            destination: phoneNumber,
            customerId: policyId,
          },
        ],
      };

      let newMessageDetails: Messages = {
        message: message,
        to: this.getCellNumberDisplayFormat(phoneNumber),
        createdOn: Timestamp.now(),
        createdBy: createdBy,
        status: 'REQUESTED',
        referenceNumber: referenceNumber ?? '',
        eventId: '',
      };

      await axios
        .post(
          'https://rest.smsportal.com/bulkmessages',
          requestData,
          requestHeaders
        )
        .then((response) => {
          if (response.data) {
            newMessageDetails.eventId = response.data.eventId;
          }
        })
        .catch((err) => {
          if (err.response) {
            newMessageDetails.status = 'FAILED';
          }
        });

      await runTransaction(this.dbModular, async (transaction) => {
        const messageCollectionRef = collection(this.dbModular, 'message');
        const messageQuery = query(
          messageCollectionRef,
          where('policyId', '==', policyId)
        );

        const querySnapshot = await getDocs(messageQuery);

        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0].ref;
          const docSnapshot = await transaction.get(docRef);

          if (docSnapshot.exists()) {
            let existingMessages =
              (docSnapshot.data() as Message)?.messages || [];
            existingMessages.push(newMessageDetails);
            transaction.update(docRef, { messages: existingMessages });
          } else {
            console.error('Document no longer exists');
          }
        } else {
          const newMessage: Message = {
            policyId: policyId,
            messages: [newMessageDetails],
          };
          const newDocRef = doc(collection(this.dbModular, 'message'));
          transaction.set(newDocRef, newMessage);
        }
      });
    } catch (err) {
      console.error('Error during sending SMS or Firestore operation:', err);
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('SENDING SMS FAILED!');
    }
  }

  cleanUp() {
    this.destroy$.next();
  }
}
