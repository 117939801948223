import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AddOnService } from 'src/app/services/add-on.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { FakerService } from 'src/app/services/faker.service';
import { PlanService } from 'src/app/services/plan.service';
import { PolicyService } from 'src/app/services/policy.service';
import { RolesRightsService } from 'src/app/services/roles-rights.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { PolicyLogService } from 'src/app/services/policy-log.service';
import { UserService } from 'src/app/services/user.service';
import { ImportService } from 'src/app/services/import.service';

@Component({
  selector: 'faker-menu',
  templateUrl: './faker-menu.component.html',
  styleUrls: ['./faker-menu.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate('1250ms ease-in-out')),
    ]),
    trigger('zoomIn', [
      state('void', style({ transform: 'scale(1.1)' })),
      state('*', style({ transform: 'scale(1)' })),
      transition('void => *', animate('750ms ease-in-out')),
    ]),
  ],
})
export class FakerMenuComponent {
  noData: boolean = false;
  activeRoute: string;
  fakerMenuForm: FormGroup;
  submitChecked = false;

  maxDate = new Date();

  constructor(
    public router: Router,
    public fakerService: FakerService,
    private fb: FormBuilder,
    public policyService: PolicyService,
    public policyLogService: PolicyLogService,
    public rolesRightsService: RolesRightsService,
    public planService: PlanService,
    public addOnService: AddOnService,
    public transactionService: TransactionService,
    public dateTimeService: DateTimeService,
    public userService: UserService,
    public importService: ImportService
  ) {}
  ngOnInit() {
    this.fakerMenuForm = this.fb.group({
      submit: [false],
      generateTransactions: [true],
      language: [''],
      role: [''],
      method: [''],
      amount: ['', [Validators.required, Validators.min(1)]],
      policyAmount: [
        '',
        [Validators.required, Validators.min(1), Validators.max(100)],
      ],
      transactionDate: [''],
    });

    this.fakerMenuForm
      .get('language')
      ?.setValue(this.fakerService.selectedLocale);
    this.fakerService.setFakerLocale(this.fakerService.selectedLocale);

    if (!this.rolesRightsService.allRoles) this.rolesRightsService.loadRoles();
  }
}
