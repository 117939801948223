import { Timestamp } from '@firebase/firestore';

export interface Backups {
  id?: string;
  backups?: BackupDetails[];
}

export interface BackupDetails {
  id?: string;
  collections?: CollectionDetails[];
  createdOn?: Timestamp;
  backupCreatedOn?: Timestamp;
  type?: BackupOrRestoreType;
}
export interface CollectionDetails {
  name?: string;
  path?: string;
  sizeInBytes?: number;
  status?: BackOrRestoreStatus;
  // Backups Metadata
  lastDocId?: string;
  processedDocuments?: number;
  backupChunks?: number;
  // Restore Metadata
  totalPosition?: number;
  positionInCurrentChunk?: number;
  currentChunk?: number;
  bufferId?: string;
}

export enum BackupOrRestoreType {
  BACKUP = 'BACKUP',
  RESTORE = 'RESTORE',
}

export enum BackOrRestoreStatus {
  ALL = 'ALL', // "ALL" is not present in the original model
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED OUT',
  IN_PROGRESS = 'IN PROGRESS',
  HANDING_OVER = 'HANDING OVER',
}

export enum Collections {
  POLICY = 'policy',
  POLICY_LOG = 'policyLog',
  MESSAGE = 'message',
  TRANSACTION = 'transaction',
  TRANSACTION_LOG = 'transactionLog',
  PLAN = 'plan',
  ADD_ON = 'addOn',
  USERS = 'users',
  ROLES = 'roles',
  REPORT = 'report',
  META_DATA = 'metaData',
  IMPORTS = 'imports',
  CASHUP = 'cashUp',
  POLICY_LOG_INDEX = 'policyLogIndex',
}
