import { Timestamp } from '@firebase/firestore';
import { CreatedBy } from './general.model';
import { FileData } from './file.model';

export interface ImportResult {
  id?: string;
  csvFileData?: CSVFileData;
  retryCsvFileData?: FileData;
  totalLines?: number;
  succeeded?: number;
  failed?: number;
  totalElapsed?: number; // In seconds
  errors?: ImportError[];
  type?: ImportType;
  status?: ImportStatus;
  statusInfo?: string;
  createdOn?: Timestamp;
  createdBy?: CreatedBy;
}

export interface CSVFileData extends FileData {
  chunkSize?: number;
  totalChunks?: number;
  totalLines?: number;
  currentLine?: number;
}

export interface ImportError {
  objectRef?: string;
  line?: number;
  log?: string;
}

export enum ImportType {
  MEMBER = 'POL360 MEMBER',
  ADD_ON = 'POL360 ADD-ON',
  POL360_TRANSACTION = 'POL360 TRANSACTION',
  NETCASH_TRANSACTION = 'NETCASH TRANSACTION',
  EFT_TRANSACTION = 'EFT TRANSACTION',
  PAY_AT_TRANSACTION = 'PAY@ TRANSACTION',
}

export enum ImportStatus {
  ALL = 'ALL', // "ALL" is not present in the original model
  SUCCESS = 'SUCCESS',
  PARTIAL = 'PARTIAL',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED OUT',
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN PROGRESS',
  HANDING_OVER = 'HANDING OVER',
  READY = 'READY',
}

export interface ImportCount {
  id?: string;
  count?: {
    [key in ImportStatus]?: number;
  };
}
