<div *ngIf="isLoading" class="spinner-container">
  <app-custom-spinner
    mode="indeterminate"
    [size]="'150px'"
  ></app-custom-spinner>
</div>
<div *ngIf="isLoading" class="loadingInfo-container">
  <h2 class="whiteColor">{{ loadingInfo }}</h2>
</div>
<div
  *ngIf="isDisabled"
  [ngClass]="{ 'disabled-container': true, loadingCursor: loadingCursor }"
></div>

<div class="background">
  <app-login
    *ngIf="
      (!userService.isLoggedIn() && !userService.loading) ||
      userService.isSelectingLocation ||
      (userService.isSignUpPassword && !userService.loading)
    "
    [@fadeIn]
    [@fadeOut]
  ></app-login>

  <app-main-nav
    *ngIf="
      userService.isLoggedIn() &&
      userService.userData &&
      userService.allLocations &&
      !userService.isSignUpPassword &&
      !userService.isSelectingLocation
    "
    [@fadeIn]
    [@fadeOut]
    [ngClass]="{ loadingCursor: loadingCursor }"
  ></app-main-nav>
</div>
