import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  BackOrRestoreStatus,
  BackupDetails,
  BackupOrRestoreType,
  Backups,
  CollectionDetails,
} from '../models/backup.model';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from './snack-bar.service';
import { UserService } from './user.service';
import { MainService } from './main.service';
import {
  doc,
  DocumentReference,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

@Injectable({
  providedIn: 'root',
})
export class BackupService {
  private dbModular = getFirestore();
  private fireFunctions = getFunctions(undefined, 'europe-west3');

  backupsDoc: DocumentReference<Backups>;
  backups$: Observable<Backups | undefined>;
  backupDetails: BackupDetails[] = [];

  dataSourceBackupDetails: MatTableDataSource<BackupDetails>;

  loading: boolean = false;

  destroy$ = new Subject<void>();

  @Output() loadedBackupsUpdated = new EventEmitter<void>();

  constructor(
    private snackBarService: SnackBarService,
    private userService: UserService,
    private mainService: MainService
  ) {
    this.userService.destroy$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cleanUp();
    });
  }

  async loadBackUpDetails(): Promise<void> {
    try {
      const backupsDocRef = doc(this.dbModular, 'metaData', 'backups');
      this.backups$ = new Observable<Backups>((observer) => {
        const unsubscribe = onSnapshot(
          backupsDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              observer.next({
                ...docSnapshot.data(),
                id: docSnapshot.id,
              } as Backups);
            } else {
              observer.error(new Error('No backups found'));
            }
          },
          (error) => observer.error(error)
        );

        return unsubscribe;
      });

      return new Promise<void>((resolve, reject) => {
        this.backups$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (doc) => {
            this.backupDetails = doc?.backups || [];
            this.sortBackupDetails();
            this.updateCurrentBackupDetails(this.backupDetails);
            this.loadedBackupsUpdated.emit();
            resolve();
          },
          error: (err) => {
            if (err instanceof Error) {
              this.snackBarService.latestError = err.message;
              this.snackBarService.openRedSnackBar(
                'LOADING THE CURRENT BACKUPS FAILED!'
              );
              reject(err);
            }
          },
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar(
          'LOADING THE CURRENT BACKUPS FAILED!'
        );
        throw err;
      }
    }
  }

  get isBackupOrRestoreInProgress(): boolean {
    return this.backupDetails.some((backup) =>
      backup.collections?.some(
        (collection) =>
          collection.status === BackOrRestoreStatus.IN_PROGRESS ||
          collection.status === BackOrRestoreStatus.HANDING_OVER
      )
    );
  }

  getCollectionsString(backupDetails: BackupDetails): string {
    if (!backupDetails?.collections) {
      return '';
    }
    return backupDetails.collections
      ?.map((collection) => this.getCollectionNameString(collection.name))
      .join(', ');
  }

  /**
   * Converts the total size of the collections in bytes to a human-readable string.
   * @param {BackupDetails} backupDetails - The backup details containing collection sizes.
   * @return {string} - The human-readable size string.
   */
  getCollectionSizeString(backupDetails: BackupDetails): string {
    if (!backupDetails?.collections) {
      return '0 B';
    }
    const totalBytes =
      backupDetails.collections
        ?.map((collection) => collection.sizeInBytes)
        .reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0) ?? 0;

    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let size = totalBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(1)} ${units[unitIndex]}`;
  }

  getCollectionNameString(collection?: string): string {
    if (!collection) return '';

    const readableName = collection
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toUpperCase();

    return this.mainService.pluralize(readableName, 2);
  }

  async newBackup(collections: string[]): Promise<void> {
    try {
      this.loading = true;
      if (collections.length === 0) {
        throw new Error('No collections specified for backup.');
      }
      if (!this.userService.userData?.email?.endsWith('@ioio.co.za')) {
        throw new Error('You are not authorized to initiate a backup.');
      }

      const callable = httpsCallable(
        this.fireFunctions,
        'backupFirestoreCallable',
        {
          timeout: 540000,
        }
      );
      await callable({ collections });
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('CREATING A NEW BACKUP FAILED!');
        throw err;
      }
    }
  }

  async restoreFirestore(collections: CollectionDetails[]): Promise<void> {
    try {
      this.loading = true;
      if (collections === undefined) {
        throw new Error('No collections specified for restore.');
      }

      if (!this.userService.userData?.email?.endsWith('@ioio.co.za')) {
        throw new Error('You are not authorized to initiate a backup restore');
      }

      const callable = httpsCallable(
        this.fireFunctions,
        'restoreFirestoreCallable',
        {
          timeout: 540000,
        }
      );
      await callable({ collections });
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('RESTORING THE BACKUP FAILED!');
        throw err;
      }
    }
  }

  updateCurrentBackupDetails(backupDetails: BackupDetails[]) {
    if (!this.dataSourceBackupDetails) {
      this.dataSourceBackupDetails = new MatTableDataSource<BackupDetails>(
        backupDetails
      );
    } else {
      this.dataSourceBackupDetails.data = backupDetails;
      this.dataSourceBackupDetails._updateChangeSubscription();
    }
  }

  sortBackupDetails() {
    this.backupDetails = this.backupDetails.sort((a, b) => {
      const dateA = a.createdOn ? a.createdOn.toDate() : new Date(0);
      const dateB = b.createdOn ? b.createdOn.toDate() : new Date(0);
      return dateA < dateB ? 1 : -1;
    });
  }

  calculateMainStatus(backupDetails: BackupDetails): {
    text: BackOrRestoreStatus | '';
    styleClass: string;
  } {
    const defaultResult = {
      text: '' as BackOrRestoreStatus | '',
      styleClass: '',
    };

    if (!backupDetails.collections || backupDetails.collections.length === 0) {
      return { text: BackOrRestoreStatus.FAILED, styleClass: 'warningColor' };
    }

    let hasSuccess = false;
    let hasInProgressOrHandingOver = false;
    let hasFailed = false;

    for (const collection of backupDetails.collections) {
      if (!collection.status) continue;

      switch (collection.status) {
        case BackOrRestoreStatus.SUCCESS:
          hasSuccess = true;
          break;
        case BackOrRestoreStatus.IN_PROGRESS:
        case BackOrRestoreStatus.HANDING_OVER:
          hasInProgressOrHandingOver = true;
          break;
        case BackOrRestoreStatus.FAILED:
          hasFailed = true;
          break;
      }
    }

    if (hasInProgressOrHandingOver) {
      return {
        text: BackOrRestoreStatus.IN_PROGRESS,
        styleClass: 'inProgressColor',
      };
    }

    if (hasFailed && !hasInProgressOrHandingOver) {
      return { text: BackOrRestoreStatus.FAILED, styleClass: 'warningColor' };
    }

    if (hasSuccess && !hasInProgressOrHandingOver && !hasFailed) {
      return { text: BackOrRestoreStatus.SUCCESS, styleClass: 'activeColor' };
    }

    return defaultResult;
  }

  calculateProgress(backupDetails: BackupDetails): string {
    if (!backupDetails.collections || backupDetails.collections.length === 0) {
      return 'No collections found';
    }

    let totalProcessedDocuments = 0;
    let totalSizeInBytes = 0;
    let totalPosition = 0;

    for (const collection of backupDetails.collections) {
      if (collection.processedDocuments) {
        totalProcessedDocuments += collection.processedDocuments;
      }
      if (collection.sizeInBytes) {
        totalSizeInBytes += collection.sizeInBytes;
      }
      if (collection.totalPosition) {
        totalPosition += collection.totalPosition;
      }
    }

    if (backupDetails.type === BackupOrRestoreType.BACKUP) {
      return `${totalProcessedDocuments} DOCUMENTS BACKED UP`;
    } else if (backupDetails.type === BackupOrRestoreType.RESTORE) {
      const percentage = Math.round((totalPosition / totalSizeInBytes) * 100);
      const percentageString = percentage.toString();
      if (percentage === 0) {
        return 'WIPING OLD COLLECTIONS';
      }
      const sizeFormatted = this.formatBytes(totalSizeInBytes);
      const positionFormatted = this.formatBytes(totalPosition);
      return `${percentageString}% - ${positionFormatted}/${sizeFormatted}`;
    }

    return '';
  }

  formatBytes(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  cleanUp() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
