import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import pluralize from 'pluralize';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private loadingInfoSubject = new BehaviorSubject<string>('');
  isLoading$ = this.loadingSubject.asObservable();
  loadingInfo$ = this.loadingInfoSubject.asObservable();

  loadingInfo: string = '';
  returnRoute: string | undefined;

  private disabledSubject = new BehaviorSubject<boolean>(false);
  isDisabled$ = this.disabledSubject.asObservable();

  private loadingCursorSubject = new BehaviorSubject<boolean>(false);
  loadingCursor$ = this.loadingCursorSubject.asObservable();
  initError = '';

  get isLoading(): boolean {
    return this.loadingSubject.getValue();
  }

  getPreviousRoute() {
    const previousRoute = sessionStorage.getItem('previousRoute');
    return previousRoute ?? '/home';
  }

  setLoading(isLoading: boolean): void {
    if (!isLoading) this.setLoadingInfo('');
    this.loadingSubject.next(isLoading);
  }

  setLoadingInfo(loadingInfo: string): void {
    this.loadingInfoSubject.next(loadingInfo);
  }

  setDisabled(isDisabled: boolean): void {
    this.disabledSubject.next(isDisabled);
  }

  setLoadingCursor(loadingCursor: boolean): void {
    this.loadingCursorSubject.next(loadingCursor);
  }

  setPreviousRoute(route: string) {
    sessionStorage.setItem('previousRoute', route);
  }

  pluralize(word: string, count: number) {
    return pluralize(word, count);
  }

  selectInputText(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.select();
  }
}
