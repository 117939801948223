import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  first,
  firstValueFrom,
  map,
  Observable,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { AddOn } from '../models/addOn.model';
import { MemberType, Plan } from '../models/plan.model';
import {
  Member,
  Policy,
  PolicyAddOn,
  ProductPaymentStatus,
  Comment,
  PolicyCount,
  PolicyStatus,
  DebitOrder,
  MemberStatus,
} from '../models/policy.model';
import { PlanService } from './plan.service';
import { SnackBarService } from './snack-bar.service';
import { uuidv4 } from '@firebase/util';
import { UserService } from './user.service';
import { Timestamp } from '@firebase/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { DateTimeService } from './date-time.service';
import { FilterService } from './filter.service';
import { AddOnService } from './add-on.service';
import { PolicyLogService } from './policy-log.service';
import { MessageService } from './message.service';
import { Router } from '@angular/router';
import { TransactionLogService } from './transaction-log.service';
import { MainService } from './main.service';
import { Preset } from '../models/user.model';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  runTransaction,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  private dbModular = getFirestore();

  selectedPolicy: Policy | undefined;
  selectedPolicyMember: Member | undefined;
  selectedPolicyAddOn: PolicyAddOn | undefined;
  selectedPolicyMemberIndex: number;
  selectedPolicyAddOnIndex: number;
  loadedPolicies: Policy[] = [];
  policyCount: PolicyCount | undefined;
  loadedOfflineTransactionPolicies: Policy[];
  plans: Plan[];
  addOns: AddOn[];
  allowedMemberTypes: MemberType[] | undefined;
  lastPolicyNumber: string | undefined = undefined;
  returnRoute: string | undefined;
  commentSearchText: string = '';

  policySubscription: Subscription;
  policyAddOnRequestApproved: boolean;
  policyMemberRequestApproved: boolean;
  policyAddOnRequestDeclined: boolean;
  policyMemberRequestDeclined: boolean;
  policyMembersHasRequests = false;
  policyAddOnsHasRequests = false;

  dataSourcePolicies: MatTableDataSource<any>;
  dataSourceOfflineTransactionPolicies: MatTableDataSource<any>;
  dataSourceAddOns: MatTableDataSource<any>;
  dataSourcePolicyMembers: MatTableDataSource<any>;
  dataSourceComments: MatTableDataSource<any>;
  dataSourceTransactions: MatTableDataSource<any>;

  // Form state
  loading = false;
  policiesLoading = false;
  offlineTransactionPoliciesLoading = false;
  success = false;
  generatingOnlinePolicyNumber = false;
  policyFilterClicked = true;
  isPaginatingPolicies = false;
  isPaginatingOfflineTransactionPolicies = false;
  isPoliciesBehind = false;
  isOfflineTransactionPoliciesBehind = false;

  // BehaviorSubject to hold a boolean based on if policiesSubject recently updated
  private updated$ = new BehaviorSubject<boolean>(false);
  // BehaviorSubject to hold the policies data
  private policiesSubject = new BehaviorSubject<Policy[]>([]);
  public policies$ = this.policiesSubject.asObservable();
  private offlineTransactionPoliciesSubject = new BehaviorSubject<Policy[]>([]);
  public offlineTransactionPoliciesSubject$ =
    this.policiesSubject.asObservable();

  destroy$ = new Subject<void>();
  destroySelectedPolicy$ = new Subject<void>();

  policyDoc: DocumentReference<Policy>;
  policyCountDoc: DocumentReference<PolicyCount>;
  policyCount$: Observable<PolicyCount | undefined>;
  userPresets$: Observable<Preset | undefined>;
  selectedPolicy$: Observable<Policy | undefined>;

  unsubscribeFromPoliciesSnapshot: (() => void) | undefined = undefined;
  unsubscribeFromOfflineTransactionPoliciesSnapshot: (() => void) | undefined =
    undefined;

  @Output() loadedPoliciesUpdated = new EventEmitter<void>();
  @Output() loadedOfflineTransactionPoliciesUpdated = new EventEmitter<void>();
  @Output() selectedPolicyUpdated = new EventEmitter<void>();
  @Output() selectedPolicyChanged = new EventEmitter<void>();
  @Output() selectedPolicyCommentsChanged = new EventEmitter<void>();
  @Output() selectedPolicyUnallocatedBalanceUpdated = new EventEmitter<void>();
  @Output() selectedPolicyProductPaymentStatusUpdated =
    new EventEmitter<void>();
  @Output() selectedPolicyIntendedPaymentDayUpdated = new EventEmitter<void>();

  constructor(
    private planService: PlanService,
    private addOnService: AddOnService,
    public dateTimeService: DateTimeService,
    private userService: UserService,
    private filterService: FilterService,
    public snackBarService: SnackBarService,
    private messageService: MessageService,
    private policyLogService: PolicyLogService,
    private router: Router,
    private transactionLogService: TransactionLogService,
    private mainService: MainService
  ) {
    this.userService.destroy$.pipe().subscribe(() => this.cleanUp());
  }

  //Initial Load {
  async ngOnInit() {
    this.updateBoolean(false);
    // Get all plans from the plan service
    this.plans = this.planService.allPlans;
  }

  async setSelectedPolicy(docId?: string, isCreation = false) {
    try {
      if (!docId) {
        this.router.navigate(['/policies']);
        throw new Error('DOCUMENT ID NOT PROVIDED!');
      }

      sessionStorage.setItem('selectedPolicyId', docId);
      this.selectedPolicyChanged.emit();

      if (!isCreation) {
        await Promise.all([
          this.policyLogService.setSelectedPolicyLog(docId),
          this.transactionLogService.setSelectedTransactionLog(docId),
        ]);
      }

      if (!this.planService.allPlans || this.planService.plansUnsubscribed) {
        await this.planService.loadPlans();
      }

      if (
        !this.addOnService.allAddOns ||
        this.addOnService.addOnsUnsubscribed
      ) {
        await this.addOnService.loadAddOns();
      }

      const policyDocRef = doc(this.dbModular, 'policy', docId);

      this.selectedPolicy$ = new Observable<Policy>((observer) => {
        const unsubscribe = onSnapshot(
          policyDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const policyData = {
                ...(docSnapshot.data() as Policy),
                id: docSnapshot.id,
              };
              observer.next(policyData);
            } else {
              this.snackBarService.openRedSnackBar(
                'POLICY DOCUMENT NOT FOUND!'
              );
              observer.error(new Error('POLICY DOCUMENT NOT FOUND!'));
            }
          },
          (error) => {
            observer.error(error);
          }
        );

        return () => unsubscribe();
      });

      await new Promise<void>((resolve, reject) => {
        this.selectedPolicy$
          .pipe(takeUntil(this.destroy$ || this.destroySelectedPolicy$))
          .subscribe({
            next: (policy) => {
              const storedPolicyId = sessionStorage.getItem('selectedPolicyId');
              if (policy && policy.id === storedPolicyId) {
                this.selectedPolicy = policy;
                this.refreshPolicyAddOnDataSource();
                this.refreshPolicyMemberDataSource();
                this.refreshPolicyCommentsDataSource();
                this.selectedPolicyUpdated.emit();
                resolve();
              }
            },
            error: (err) => {
              this.handleError(err);
              reject(err);
            },
          });
      });
    } catch (err) {
      if (err instanceof Error) {
        this.handleError(err);
        throw err;
      }
    }
  }

  private handleError(err: Error) {
    if (err.message) this.snackBarService.latestError = err.message;

    this.mainService.setLoading(false);
    this.snackBarService.openRedSnackBar('SETTING THE SELECTED POLICY FAILED!');
    this.router.navigate(['/policies']);
  }

  // Get the Primary member of the policy with the given planId
  getPolicyPrimaryMember(policy: Policy) {
    const policyMembers = policy.members;
    const planId = policy.planId;
    if (policyMembers && planId) {
      return policyMembers.find(
        (member) =>
          member.memberTypeId ===
            this.planService.getPrimaryMemberTypeByPlanId(planId)?.id &&
          (member.status === 'ACTIVE' || member.status === 'REQUESTED')
      );
    } else {
      return undefined;
    }
  }

  // Returns the name and class of the member type with the given ID.
  getMemberTypeNameAndClassById(memberTypeId: string) {
    if (memberTypeId) {
      for (const plan of this.planService.allPlans) {
        const memberType = plan.memberType?.find(
          (type) => type.id === memberTypeId
        );
        if (memberType) {
          return {
            text: memberType.name,
            class:
              plan.id !== this.selectedPolicy?.planId ? ['warningColor'] : [''],
          };
        }
      }
    }
    return { text: '(NOT FOUND)', class: ['italic'] };
  }

  isPrimaryMember(policyMember: Member) {
    if (this.selectedPolicy?.planId && policyMember?.memberTypeId) {
      return (
        this.planService.getPrimaryMemberTypeByPlanId(
          this.selectedPolicy?.planId
        )?.id === policyMember.memberTypeId
      );
    }
    return false;
  }
  // Check if the given member type can be added to the current policy
  canAddMemberTypeToPolicy(memberTypeId: string): boolean {
    if (this.selectedPolicy?.planId && this.selectedPolicy?.members) {
      const memberType = this.planService.getMemberTypeById(memberTypeId);
      if (
        !memberType?.maxAllowed ||
        memberType.status === MemberStatus.INACTIVE
      ) {
        return false;
      }
      const activeMembers = this.selectedPolicy.members.filter(
        (member) =>
          member.memberTypeId === memberTypeId &&
          ((!memberType.primaryMember &&
            member.status != MemberStatus.INACTIVE) ||
            (member.status != MemberStatus.INACTIVE &&
              member.status != MemberStatus.CLAIMED))
      );
      return activeMembers.length < memberType.maxAllowed;
    }
    return true;
  }

  getAllowedAddOns(currentAddOnId?: string): AddOn[] {
    if (!this.selectedPolicy?.planId) {
      return [];
    }

    const addOns = this.addOnService.allAddOns;

    const allowedAddOns = addOns.filter((addOn) => {
      return (
        addOn.id === currentAddOnId ||
        (addOn.status !== 'INACTIVE' &&
          addOn.plans?.some(
            (addOnPlan) =>
              addOnPlan.planId === this.selectedPolicy?.planId &&
              addOnPlan.status !== 'INACTIVE'
          ) &&
          !this.selectedPolicy?.addOns?.some(
            (policyAddOn) =>
              policyAddOn.addOnId === addOn.id &&
              policyAddOn.status !== 'INACTIVE'
          ))
      );
    });
    return allowedAddOns;
  }

  refreshAllowedMemberTypes(currentMemberTypeId?: string) {
    if (!this.selectedPolicy?.planId) {
      this.allowedMemberTypes = [];
      return;
    }

    const planMemberTypes = this.planService.getPlanMemberTypes(
      this.selectedPolicy.planId
    );
    if (!planMemberTypes) {
      this.allowedMemberTypes = [];
      return;
    }

    // Check if there are no members in the selected policy
    if (this.selectedPolicy.members?.length === 0) {
      // Filter in only the primary member type
      const primaryMemberType = planMemberTypes.find((memberType) =>
        this.isPrimaryMember({ memberTypeId: memberType.id })
      );
      this.allowedMemberTypes = primaryMemberType ? [primaryMemberType] : [];
    } else {
      // Regular filtering logic
      const allowedMemberTypes = planMemberTypes.filter((memberType) => {
        return (
          memberType.id &&
          (this.canAddMemberTypeToPolicy(memberType.id) ||
            memberType.id === currentMemberTypeId)
        );
      });
      this.allowedMemberTypes = allowedMemberTypes;
    }
  }

  getAppropriatePolicyStatus(status: string): {
    text: string;
    styleClass: string;
  } {
    const defaultResult = { text: '', styleClass: '' };

    switch (status) {
      case PolicyStatus.ACTIVE:
        return { text: 'ACTIVE', styleClass: 'activeColor' };

      case PolicyStatus.INACTIVE:
        return { text: 'INACTIVE', styleClass: 'warningColor' };

      case PolicyStatus.LAPSED:
        return { text: 'LAPSED', styleClass: 'warningColor' };

      case PolicyStatus.PENDING:
        return { text: 'PENDING', styleClass: 'waitingColor' };

      case PolicyStatus.ARREARS:
        return { text: 'ARREARS', styleClass: 'waitingColor' };

      case PolicyStatus.IN_PROGRESS:
        return { text: 'IN PROGRESS', styleClass: 'inProgressColor' };

      case PolicyStatus.UNCONFIRMED:
        return { text: 'UNCONFIRMED', styleClass: 'requestedColor' };
      default:
        return defaultResult;
    }
  }

  getAppropriatePolicyDebitOrderStatus(): {
    text: string;
    styleClass: string;
  } {
    const defaultResult = { text: '', styleClass: '' };

    switch (this.selectedPolicy?.debitOrder?.status) {
      case 'ACTIVE':
        return { text: 'ACTIVE', styleClass: 'activeColor' };

      case 'INACTIVE':
        return { text: 'INACTIVE', styleClass: 'warningColor' };

      default:
        return defaultResult;
    }
  }

  getAppropriateMemberStatus(member: any | undefined, statusOnly = false) {
    if (
      (member?.status === 'ACTIVE' || member?.memberStatus === 'ACTIVE') &&
      member?.memberTypeId
    ) {
      const waitingPeriod = this.planService.getMemberTypeById(
        member.memberTypeId
      )?.waitingPeriod;
      if (waitingPeriod !== undefined) {
        let now = new Date();
        now.setHours(0, 0, 0, 0);
        const waitingPeriodLeft = Number(
          waitingPeriod -
            this.dateTimeService.getDaysDifference(
              this.dateTimeService.dateToTimestamp(now) ?? Timestamp.now(),
              this.dateTimeService.verifyTimestamp(member?.waitingDate)
            )
        );
        if (waitingPeriodLeft <= 0) {
          return { text: 'ACTIVE', class: ['activeColor clickable'] };
        } else if (
          waitingPeriodLeft > 0 &&
          member.waitingDate?.seconds != member?.inceptionDate?.seconds
        ) {
          return {
            text: waitingPeriodLeft.toString() + ' DAYS',
            class: ['requestedColor'],
          };
        } else {
          return {
            text: waitingPeriodLeft.toString() + ' DAYS',
            class: ['waitingColor'],
          };
        }
      }
    } else if (
      member?.status === 'REQUESTED' ||
      member?.memberStatus === 'REQUESTED'
    ) {
      if (statusOnly) {
        return {
          text: 'REQUESTED',
          class: ['requestedColor'],
        };
      }
      let newWaitingPeriod = 0;
      if (this.selectedPolicy?.planId && member?.memberTypeId) {
        let waitingPeriod = this.planService.getMemberTypeById(
          member.memberTypeId
        ).waitingPeriod;
        if (waitingPeriod)
          waitingPeriod =
            waitingPeriod -
            Number(
              this.dateTimeService.getDaysDifference(
                member.inceptionDate,
                member.waitingDate
              )
            );
        newWaitingPeriod = waitingPeriod ?? 0;
        if (waitingPeriod) {
          newWaitingPeriod =
            waitingPeriod -
            Number(
              this.dateTimeService.getDaysDifference(
                member.waitingDate,
                member.requestedWaitingDate
              )
            );
          if (newWaitingPeriod <= 0) {
            return {
              text: 'REQUESTED ' + waitingPeriod + ' TO ' + 0 + ' DAYS',
              class: ['requestedColor'],
              newWaitingPeriod,
            };
          } else if (newWaitingPeriod > 0) {
            return {
              text:
                'REQUESTED ' +
                waitingPeriod +
                ' TO ' +
                newWaitingPeriod +
                ' DAYS',
              class: ['requestedColor'],
              newWaitingPeriod,
            };
          }
        }
      }
      return {
        text: '(NOT FOUND)',
        class: ['whiteColor italic'],
        newWaitingPeriod,
      };
    } else if (
      member?.status === 'CLAIMED' ||
      member?.memberStatus === 'CLAIMED'
    ) {
      return { text: 'CLAIMED', class: ['whiteColor'] };
    } else if (
      member?.status === 'INACTIVE' ||
      member?.memberStatus === 'INACTIVE'
    ) {
      return { text: 'INACTIVE', class: ['warningColor'] };
    }
    return {
      text: '',
      class: ['whiteColor'],
    };
  }

  getPolicyActiveProductsString(policy: Policy) {
    let activeProducts: string[] = [];
    if (
      policy.status != PolicyStatus.INACTIVE &&
      // policy.status != PolicyStatus.INACTIVE && ??????????????
      policy.planId
    ) {
      const planName = this.planService.getPlanById(policy.planId)?.name;
      if (planName) activeProducts.push(planName);
    }

    policy.addOns?.forEach((policyAddOn) => {
      if (
        policyAddOn.status != 'INACTIVE' &&
        policyAddOn.status != 'CLAIMED' &&
        policyAddOn.addOnId
      ) {
        const addOnName = this.addOnService.getAddOnById(
          policyAddOn.addOnId
        )?.name;
        if (addOnName) activeProducts.push(addOnName);
      }
    });
    return activeProducts.join(' + ');
  }

  getPolicyMemberWaitingPeriodLeft(
    member: Member,
    timestamp?: Timestamp,
    policyData?: Policy
  ) {
    const policy = this.selectedPolicy ?? policyData;
    if (policy?.planId && member?.memberTypeId) {
      const waitingPeriod = this.planService.getMemberTypeById(
        member.memberTypeId
      )?.waitingPeriod;

      if (waitingPeriod)
        return Number(
          waitingPeriod -
            this.dateTimeService.getDaysDifference(
              timestamp ?? Timestamp.now(),
              member.requestReason !== 'WAITING DATE OVERRIDE'
                ? this.dateTimeService.verifyTimestamp(member.waitingDate)
                : this.dateTimeService.verifyTimestamp(member.inceptionDate)
            )
        );
    }
    return undefined;
  }

  getPolicyAddOnWaitingPeriodLeft(
    policyAddOn: PolicyAddOn,
    timestamp?: Timestamp,
    policyData?: Policy
  ) {
    const policy = policyData ?? this.selectedPolicy;
    if (policy?.planId && policyAddOn?.addOnId) {
      const addOn = this.addOnService.getAddOnById(policyAddOn?.addOnId);
      const waitingPeriod = addOn?.plans?.find(
        (addOnPlan) => addOnPlan.planId === policy.planId
      )?.waitingPeriod;
      if (waitingPeriod) {
        return Number(
          waitingPeriod -
            this.dateTimeService.getDaysDifference(
              timestamp ?? Timestamp.now(),
              policyAddOn.status !== 'REQUESTED'
                ? this.dateTimeService.verifyTimestamp(policyAddOn.waitingDate)
                : this.dateTimeService.verifyTimestamp(
                    policyAddOn.inceptionDate
                  )
            )
        );
      }
    }
    return undefined;
  }

  getAppropriateAddOnStatus(
    policyAddOn: PolicyAddOn,
    statusOnly = false,
    policyData?: Policy
  ) {
    const policy = this.selectedPolicy ?? policyData;
    if (policy?.planId) {
      if (policyAddOn?.status === 'ACTIVE' && policyAddOn?.addOnId) {
        const addOn = this.addOnService.getAddOnById(policyAddOn?.addOnId);
        const waitingPeriod = addOn?.plans?.find(
          (addOnPlan) => addOnPlan.planId === policy?.planId
        )?.waitingPeriod;
        if (waitingPeriod !== undefined) {
          let now = new Date();
          now.setHours(0, 0, 0, 0);
          const waitingPeriodLeft = Number(
            waitingPeriod -
              this.dateTimeService.getDaysDifference(
                this.dateTimeService.dateToTimestamp(now) ?? Timestamp.now(),
                this.dateTimeService.verifyTimestamp(policyAddOn?.waitingDate)
              )
          );
          if (waitingPeriodLeft <= 0) {
            return { text: 'ACTIVE', class: ['activeColor clickable'] };
          } else if (
            waitingPeriodLeft > 0 &&
            policyAddOn.waitingDate?.seconds !=
              policyAddOn?.inceptionDate?.seconds
          ) {
            return {
              text: waitingPeriodLeft.toString() + ' DAYS',
              class: ['requestedColor'],
            };
          } else {
            return {
              text: waitingPeriodLeft.toString() + ' DAYS',
              class: ['waitingColor'],
            };
          }
        }
      } else if (policyAddOn?.status === 'REQUESTED') {
        if (statusOnly) {
          return {
            text: 'REQUESTED',
            class: ['requestedColor'],
          };
        }
        let newWaitingPeriod = 0;
        if (policyAddOn?.addOnId) {
          const addOn = this.addOnService.getAddOnById(policyAddOn?.addOnId);
          let waitingPeriod = addOn?.plans?.find(
            (addOnPlan) => addOnPlan.planId === policy?.planId
          )?.waitingPeriod;

          if (waitingPeriod && policyAddOn.inceptionDate)
            waitingPeriod =
              waitingPeriod -
              Number(
                this.dateTimeService.getDaysDifference(
                  policyAddOn.inceptionDate,
                  policyAddOn.waitingDate
                )
              );
          newWaitingPeriod = waitingPeriod ?? 0;
          if (waitingPeriod && policyAddOn.waitingDate) {
            newWaitingPeriod =
              waitingPeriod -
              Number(
                this.dateTimeService.getDaysDifference(
                  policyAddOn.waitingDate,
                  policyAddOn.requestedWaitingDate
                )
              );
            if (newWaitingPeriod <= 0) {
              return {
                text: 'REQUESTED ' + waitingPeriod + ' TO ' + 0 + ' DAYS',
                class: ['requestedColor'],
                newWaitingPeriod: 0,
              };
            } else if (newWaitingPeriod > 0) {
              return {
                text:
                  'REQUESTED ' +
                  waitingPeriod +
                  ' TO ' +
                  newWaitingPeriod +
                  ' DAYS',
                class: ['requestedColor'],
                newWaitingPeriod,
              };
            }
          }
        }
        return {
          text: '(NOT FOUND)',
          class: ['whiteColor italic'],
          newWaitingPeriod,
        };
      } else if (policyAddOn?.status === 'INACTIVE') {
        return { text: 'INACTIVE', class: ['warningColor'] };
      }
    }
    return {
      text: '(NOT FOUND)',
      class: ['whiteColor italic'],
    };
  }

  isProductActive(id?: string): boolean {
    if (!id) return false;

    if (id === this.selectedPolicy?.planId) {
      return true;
    } else {
      const addOnStatus = this.selectedPolicy?.addOns?.find(
        (policyAddOn) => policyAddOn.addOnId === id
      )?.status;
      return (addOnStatus && addOnStatus === 'ACTIVE') ||
        addOnStatus === 'REQUESTED'
        ? true
        : false;
    }
  }

  getMemberOldAndNewWaitingPeriod(member: Member) {
    let newWaitingPeriod = 0;
    if (
      this.selectedPolicy &&
      this.selectedPolicy.planId &&
      member?.memberTypeId
    ) {
      const waitingPeriod = this.planService.getMemberTypeById(
        member.memberTypeId
      )?.waitingPeriod;
      newWaitingPeriod = waitingPeriod ?? 0;
      if (waitingPeriod && member.inceptionDate) {
        newWaitingPeriod =
          waitingPeriod -
          Number(
            this.dateTimeService.getDaysDifference(
              member.inceptionDate,
              member?.waitingDate
            )
          );

        if (newWaitingPeriod <= 0) {
          return {
            text: 'REQUESTED ' + waitingPeriod + ' TO ' + 0 + ' DAYS',
            class: ['requestedColor'],
            newWaitingPeriod,
          };
        } else if (newWaitingPeriod > 0) {
          return {
            text:
              'REQUESTED ' +
              waitingPeriod +
              ' TO ' +
              newWaitingPeriod +
              ' DAYS',
            class: ['requestedColor'],
            newWaitingPeriod,
          };
        }
      }
    }
    return {
      text: '(NOT FOUND)',
      class: ['whiteColor italic'],
      newWaitingPeriod,
    };
  }

  getAddOnOldAndNewWaitingPeriod(policyAddOn: PolicyAddOn) {
    let newWaitingPeriod = 0;
    if (policyAddOn?.addOnId) {
      const addOn = this.addOnService.getAddOnById(policyAddOn?.addOnId);
      const waitingPeriod = addOn?.plans?.find(
        (addOnPlan) => addOnPlan.planId === this.selectedPolicy?.planId
      )?.waitingPeriod;
      newWaitingPeriod = waitingPeriod ?? 0;
      if (
        waitingPeriod &&
        this.selectedPolicy?.addOns &&
        policyAddOn.inceptionDate
      ) {
        newWaitingPeriod =
          waitingPeriod -
          Number(
            this.dateTimeService.getDaysDifference(
              policyAddOn.inceptionDate,
              policyAddOn?.waitingDate
            )
          );
        if (newWaitingPeriod <= 0) {
          return {
            text: 'REQUESTED ' + waitingPeriod + ' TO ' + 0 + ' DAYS',
            class: ['requestedColor'],
            newWaitingPeriod: 0,
          };
        } else if (newWaitingPeriod > 0) {
          return {
            text:
              'REQUESTED ' +
              waitingPeriod +
              ' TO ' +
              newWaitingPeriod +
              ' DAYS',
            class: ['requestedColor'],
            newWaitingPeriod,
          };
        }
      }
    }
    return {
      text: '(NOT FOUND)',
      class: ['whiteColor italic'],
      newWaitingPeriod,
    };
  }

  async getPolicyByPolicyNumber(policyNumber: string): Promise<Policy> {
    try {
      const policiesRef = collection(this.dbModular, 'policy');
      const q = query(policiesRef, where('policyNumber', '==', policyNumber));

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];
        const data = docSnapshot.data() as Policy;
        data.id = docSnapshot.id;
        return data;
      } else {
        throw new Error('No document found');
      }
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar(
          'GETTING POLICY BY POLICY NUMBER FAILED!'
        );
      }
      throw err;
    }
  }

  async getPolicyById(id: string): Promise<Policy> {
    try {
      const policyDocRef = doc(this.dbModular, 'policy', id);
      const docSnapshot = await getDoc(policyDocRef);

      if (docSnapshot.exists()) {
        return {
          ...(docSnapshot.data() as Policy),
          id: docSnapshot.id,
        } as Policy;
      } else {
        throw new Error('Policy not found');
      }
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('GETTING POLICY BY ID FAILED!');
      }
      throw err;
    }
  }

  async getAllRequiredPoliciesById(ids: string[]): Promise<Policy[]> {
    try {
      const pageSize = 500;
      let policies: Policy[] = [];
      for (let i = 0; i < ids.length; i += pageSize) {
        this.mainService.setLoadingInfo(i + ' POLICY DOCUMENTS FETCHED');
        this.userService.updateHeartbeat();

        const currentPageIds = ids.slice(i, i + pageSize);
        const policyPromises = currentPageIds.map(async (id) => {
          const policyDocRef = doc(this.dbModular, 'policy', id);
          const docSnapshot = await getDoc(policyDocRef);
          return {
            ...(docSnapshot.data() as Policy),
            id: docSnapshot.id,
          };
        });

        const currentPagePolicies = await Promise.all(policyPromises);

        policies = policies.concat(currentPagePolicies);
      }

      return policies;
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('GETTING POLICIES BY IDs FAILED!');
      }
      throw err;
    }
  }

  get totalPolicyCount(): number {
    if (!this.policyCount?.count) return 0;
    return Object.entries(this.policyCount.count)
      .filter(
        ([key]) =>
          key !== PolicyStatus.REQUESTED && key !== PolicyStatus.UNALLOCATED
      )
      .reduce((a, [, value]) => a + (value || 0), 0);
  }

  calculateAddOnWaitingDate(waitingPeriod: string, element: any) {
    if (this.selectedPolicy && this.selectedPolicy.planId && element?.addOnId) {
      const addOn = this.addOnService.getAddOnById(element.addOnId);
      const currentWaitingPeriod = addOn?.plans?.find(
        (addOnPlan) => addOnPlan.planId === this.selectedPolicy?.planId
      )?.waitingPeriod;

      const inceptionDate = this.dateTimeService.timestampToDate(
        element.inceptionDate
      );
      let waitingDate = inceptionDate;

      if (currentWaitingPeriod && inceptionDate && waitingDate) {
        waitingDate.setDate(
          inceptionDate.getDate() -
            (currentWaitingPeriod - Number(waitingPeriod))
        );
      }
      if (waitingDate) return this.dateTimeService.dateToTimestamp(waitingDate);
    }
    return undefined;
  }

  calculateMemberWaitingDate(waitingPeriod: string, member: any) {
    if (
      this.selectedPolicy &&
      this.selectedPolicy.planId &&
      member?.memberTypeId
    ) {
      const currentWaitingPeriod = this.planService.getMemberTypeById(
        member.memberTypeId
      )?.waitingPeriod;
      const inceptionDate = this.dateTimeService.timestampToDate(
        member.inceptionDate
      );
      let waitingDate = inceptionDate;

      if (currentWaitingPeriod && inceptionDate && waitingDate) {
        waitingDate.setDate(
          inceptionDate.getDate() -
            (currentWaitingPeriod - Number(waitingPeriod))
        );
      }
      if (waitingDate) return this.dateTimeService.dateToTimestamp(waitingDate);
    }
    return undefined;
  }

  async loadPolicies(
    pageIndex: number = 0,
    pageSize: number = 20
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.loading = true;

      if (this.policiesSubject.closed && this.userService.isLoggedIn()) {
        this.policiesSubject = new BehaviorSubject<Policy[]>([]);
      }

      const policiesRef = collection(this.dbModular, 'policy');
      let q = query(policiesRef);

      if (this.filterService.policyFilter === PolicyStatus.REQUESTED) {
        q = query(
          policiesRef,
          where('hasRequest', '==', true),
          orderBy('updatedOn', 'desc')
        );
      } else if (this.filterService.policyFilter === PolicyStatus.UNALLOCATED) {
        q = query(
          policiesRef,
          where('unallocatedBalance', '!=', 0),
          orderBy('unallocatedBalance', 'desc'),
          orderBy('updatedOn', 'desc')
        );
      } else if (this.filterService.policyFilter !== PolicyStatus.ALL) {
        q = query(
          policiesRef,
          where('status', '==', this.filterService.policyFilter),
          orderBy('updatedOn', 'desc')
        );
      } else if (this.filterService.policyFilter === PolicyStatus.ALL) {
        q = query(policiesRef, orderBy('updatedOn', 'desc'));
      }

      let startAtPolicy;
      if (pageIndex > 0) {
        startAtPolicy = this.loadedPolicies[pageIndex * pageSize - 1];
      }
      if (startAtPolicy) {
        if (this.filterService.policyFilter === PolicyStatus.UNALLOCATED) {
          q = query(
            q,
            startAfter(
              startAtPolicy.unallocatedBalance,
              startAtPolicy.updatedOn
            )
          );
        } else {
          q = query(q, startAfter(startAtPolicy.updatedOn));
        }
      }

      q = query(q, limit(pageSize));

      this.unsubscribeFromPoliciesSnapshot = onSnapshot(
        q,
        async (querySnapshot: QuerySnapshot<DocumentData>) => {
          try {
            if (
              !querySnapshot.metadata.hasPendingWrites &&
              !querySnapshot.metadata.fromCache
            ) {
              const isIntentionalPagination =
                this.loadedPolicies &&
                pageIndex > 0 &&
                (pageIndex + 1) * pageSize > this.loadedPolicies.length;

              if (isIntentionalPagination) {
                this.isPaginatingPolicies = true;
              }

              if (!this.policyCount) await this.loadPolicyCount();

              let policies: Policy[] = [];
              querySnapshot.forEach((doc) => {
                const policyData = doc.data() as Policy;
                policyData.id = doc.id;
                policies.push(policyData);
              });

              if (
                this.filterService.policyFilter === PolicyStatus.ALL ||
                (this.filterService.policyFilter === PolicyStatus.UNALLOCATED &&
                  !policies.some(
                    (policy) => policy.unallocatedBalance === 0
                  )) ||
                (this.filterService.policyFilter === PolicyStatus.REQUESTED &&
                  !policies.some((policy) => policy.hasRequest === false)) ||
                !policies.some(
                  (policy) => policy.status !== this.filterService.policyFilter
                )
              ) {
                if (this.isPaginatingPolicies && !isIntentionalPagination) {
                  this.isPoliciesBehind = true;
                  this.loading = false;
                  resolve();
                  return;
                }
                this.policiesLoading = true;

                this.loadedPolicies = [
                  ...(this.loadedPolicies || []).slice(0, pageIndex * pageSize),
                  ...policies,
                ];

                const policiesForPage = this.loadedPolicies.slice(
                  pageIndex * pageSize,
                  (pageIndex + 1) * pageSize
                );

                this.policiesSubject.next(this.loadedPolicies);
                this.updateCurrentPolicies(policiesForPage);
                this.loadedPoliciesUpdated.emit();
              }
            }
            this.policiesLoading = false;
            this.loading = false;
            resolve();
          } catch (err) {
            if (err instanceof Error)
              this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar('ERROR LOADING POLICIES');
            this.loading = false;

            reject(err);
          }
        },
        (error) => {
          console.error('Error fetching snapshot:', error);
          reject(error);
        }
      );
    });
  }

  async loadPolicyCount(): Promise<void> {
    try {
      const policyCountDocRef = doc(this.dbModular, 'metaData', 'policy');

      this.policyCount$ = new Observable<PolicyCount>((observer) => {
        const unsubscribe = onSnapshot(
          policyCountDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              observer.next({
                ...(docSnapshot.data() as PolicyCount),
                id: docSnapshot.id,
              });
            } else {
              this.snackBarService.openRedSnackBar(
                'POLICY COUNT DOCUMENT NOT FOUND!'
              );
              observer.error(new Error('POLICY COUNT DOCUMENT NOT FOUND!'));
            }
          },
          (error) => {
            observer.error(error);
          }
        );

        return () => unsubscribe();
      });

      await new Promise<void>((resolve, reject) => {
        this.policyCount$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (policyCount) => {
            if (policyCount) {
              this.policyCount = policyCount;
              resolve();
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        throw err;
      }
    }
  }

  updateCurrentPolicies(policies: Policy[]) {
    if (!this.dataSourcePolicies) {
      this.dataSourcePolicies = new MatTableDataSource<Policy>(policies);
    } else {
      this.dataSourcePolicies.data = policies;
      this.dataSourcePolicies._updateChangeSubscription();
    }
  }

  async loadOfflineTransactionPolicies(
    pageIndex: number = 0,
    pageSize: number = 20
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.loading = true;

      if (
        this.offlineTransactionPoliciesSubject.closed &&
        this.userService.isLoggedIn()
      ) {
        this.offlineTransactionPoliciesSubject = new BehaviorSubject<Policy[]>(
          []
        );
      }

      const policiesRef = collection(this.dbModular, 'policy');
      let q = query(policiesRef, orderBy('updatedOn', 'desc'));

      let startAtPolicy;
      if (pageIndex > 0) {
        startAtPolicy =
          this.loadedOfflineTransactionPolicies[pageIndex * pageSize - 1];
      }

      if (startAtPolicy) {
        q = query(q, startAfter(startAtPolicy.updatedOn));
      }

      q = query(q, limit(pageSize));

      this.unsubscribeFromOfflineTransactionPoliciesSnapshot = onSnapshot(
        q,
        async (querySnapshot: QuerySnapshot<DocumentData>) => {
          try {
            if (
              !querySnapshot.metadata.hasPendingWrites &&
              !querySnapshot.metadata.fromCache
            ) {
              const isIntentionalPagination =
                this.loadedOfflineTransactionPolicies &&
                pageIndex > 0 &&
                (pageIndex + 1) * pageSize >
                  this.loadedOfflineTransactionPolicies.length;

              if (isIntentionalPagination) {
                this.isPaginatingOfflineTransactionPolicies = true;
              }

              if (!this.policyCount) await this.loadPolicyCount();

              if (
                this.isPaginatingOfflineTransactionPolicies &&
                !isIntentionalPagination
              ) {
                this.isOfflineTransactionPoliciesBehind = true;
                this.loading = false;
                resolve();
                return;
              }
              this.offlineTransactionPoliciesLoading = true;

              let policies: Policy[] = [];
              querySnapshot.forEach((doc) => {
                const policyData = doc.data() as Policy;
                policyData.id = doc.id;
                policies.push(policyData);
              });

              this.loadedOfflineTransactionPolicies = [
                ...(this.loadedOfflineTransactionPolicies || []).slice(
                  0,
                  pageIndex * pageSize
                ),
                ...policies,
              ];

              const policiesForPage =
                this.loadedOfflineTransactionPolicies.slice(
                  pageIndex * pageSize,
                  (pageIndex + 1) * pageSize
                );

              this.offlineTransactionPoliciesSubject.next(
                this.loadedOfflineTransactionPolicies
              );
              this.updateCurrentOfflineTransactionPolicies(policiesForPage);
              this.loadedOfflineTransactionPoliciesUpdated.emit();
            }
            this.offlineTransactionPoliciesLoading = false;
            this.loading = false;

            resolve();
          } catch (err) {
            if (err instanceof Error)
              this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar('ERROR LOADING POLICIES');
            this.offlineTransactionPoliciesLoading = false;
            this.loading = false;

            reject(err);
          }
        },
        (error) => {
          console.error('Error fetching snapshot:', error);
          reject(error);
        }
      );
    });
  }

  updateCurrentOfflineTransactionPolicies(policies: Policy[]) {
    if (!this.dataSourceOfflineTransactionPolicies) {
      this.dataSourceOfflineTransactionPolicies =
        new MatTableDataSource<Policy>(policies);
    } else {
      this.dataSourceOfflineTransactionPolicies.data = policies;
      this.dataSourceOfflineTransactionPolicies._updateChangeSubscription();
    }
  }

  getPolicyPrimaryMemberCellNum() {
    if (this.selectedPolicy) {
      let primaryMember = this.getPolicyPrimaryMember(this.selectedPolicy);
      if (
        primaryMember?.status != 'INACTIVE' &&
        primaryMember?.status != 'CLAIMED'
      ) {
        const cellNum = primaryMember?.cellNumber;
        return '+27' + cellNum?.substring(1);
      }
    }
    return '';
  }

  generatePayAtNumber(): string {
    const date = new Date();
    const year = date.getFullYear().toString().substring(2); // Last 2 digits of year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month (0-11 so +1)
    const day = date.getDate().toString().padStart(2, '0'); // Day of month
    const hours = date.getHours().toString().padStart(2, '0'); // Hours
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutes
    const seconds = date.getSeconds().toString().padStart(2, '0'); // Seconds
    const tenthsOfSecond = Math.floor(date.getMilliseconds() / 100).toString();
    const random = this.generateRandomNumber(0, 9);

    return `${year}${month}${day}${hours}${minutes}${seconds}${tenthsOfSecond}${random}`;
  }

  generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  async getNextOnlinePolicyNumber(): Promise<string> {
    const docRef: DocumentReference = doc(
      this.dbModular,
      'metaData/onlinePolicyCounter'
    );

    try {
      return await runTransaction(this.dbModular, async (transaction) => {
        let currentNumber;

        do {
          const docSnapshot = await transaction.get(docRef);
          const docData = docSnapshot.data() || {};
          const currentNumberData = docData['count'] || 0;
          currentNumber = (currentNumberData || 0) + 1;

          const policyNumber = `${
            environment.generatedPolicyNumberPrefix
          }${currentNumber.toString().padStart(6, '0')}`;

          const policyExists = await this.checkPolicyExists(policyNumber);

          if (!policyExists) {
            transaction.update(docRef, { ['count']: currentNumber });
            return policyNumber;
          }
        } while (true);
      });
    } catch (error) {
      this.snackBarService.openRedSnackBar(
        'ERROR GETTING NEXT ONLINE POLICY NUMBER'
      );
      throw error;
    }
  }

  private async checkPolicyExists(policyNumber: string): Promise<boolean> {
    try {
      const policy = await this.getPolicyByPolicyNumber(policyNumber);
      return !!policy;
    } catch (error) {
      return false;
    }
  }

  // Create a new policy with the given form values
  async createPolicy(formValues: any) {
    this.loading = true;
    try {
      const members: never[] = [];
      const addOns: never[] = [];
      const comments: never[] = [];
      const files: never[] = [];
      const productsPaymentStatus: ProductPaymentStatus[] = [];
      const intendedPaymentDay: number = Number(
        this.dateTimeService
          .timestampToDate(formValues.inceptionDate)
          ?.getDate()
      );
      const unallocatedBalance: number = 0;
      const payAtNumber = this.generatePayAtNumber();
      const createdBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const createdOn = Timestamp.now();
      const updatedBy = createdBy;
      const updatedOn = createdOn;

      const adjustedDate = new Date(
        formValues.inceptionDate.toDate().getTime()
      );
      adjustedDate.setHours(0, 0, 0, 0);

      formValues.inceptionDate =
        this.dateTimeService.dateToTimestamp(adjustedDate);

      const modifiedFormValues = {
        ...formValues,
        status: 'IN PROGRESS',
        members,
        addOns,
        comments,
        files,
        productsPaymentStatus,
        intendedPaymentDay,
        unallocatedBalance,
        payAtNumber,
        createdBy,
        createdOn,
        updatedBy,
        updatedOn,
      };

      // Add the modified form values to the 'policy' collection in the database
      const policyRef = collection(this.dbModular, 'policy');
      const docRef = await addDoc(policyRef, modifiedFormValues);
      const docId = docRef.id;

      // Add a new document to the 'message' collection
      const messageRef = collection(this.dbModular, 'message');
      await addDoc(messageRef, { policyId: docId, messages: [] });

      // Set the selected policy to the new policy with the generated ID
      await this.setSelectedPolicy(docId, true);
      this.success = true;
      this.router.navigate(['/policy-details', docId]);
      if (this.selectedPolicy)
        await this.policyLogService.createPolicyLog(this.selectedPolicy);

      // Show a success message using the SnackBarService
      this.snackBarService.openBlueSnackBar('POLICY CREATED SUCCESSFULLY!');
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      // Show an error message using the SnackBarService
      this.snackBarService.openRedSnackBar('POLICY CREATION FAILED!');
    }
    this.loading = false;
  }

  // Update an existing policy with the given form values and policy document
  async updatePolicy(formValues: any) {
    this.loading = true;
    const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));
    try {
      if (this.selectedPolicy?.id) {
        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const updatedOn = Timestamp.now();

        let intendedPaymentDay = this.dateTimeService
          .timestampToDate(formValues?.inceptionDate)
          ?.getDate();

        const currentPolicy = await this.getPolicyById(this.selectedPolicy.id);

        let updateValues = { ...formValues, updatedBy, updatedOn };
        if (
          !currentPolicy?.productsPaymentStatus?.find(
            (status) => status.id === this.selectedPolicy?.planId
          ) &&
          this.selectedPolicy.inceptionDate?.seconds !==
            formValues?.inceptionDate?.seconds
        ) {
          updateValues.intendedPaymentDay = intendedPaymentDay;
        }

        const policyDocRef = doc(
          this.dbModular,
          'policy',
          this.selectedPolicy.id
        );
        await updateDoc(policyDocRef, updateValues);

        this.policyLogService.indexObject = 'policy';
        this.policyLogService.updateLog(oldDoc, this.selectedPolicy);
      }

      this.loading = false;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('POLICY UPDATE FAILED!');
    }
  }

  // Update the add-ons of the given policy document with the given form values and index
  async updatePolicyAddOns(
    policyDoc: Policy,
    formValues: any,
    i: number,
    remove?: boolean
  ) {
    this.loading = true;
    const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));
    let newDoc = JSON.parse(JSON.stringify(policyDoc));
    try {
      if (!policyDoc?.id) {
        throw new Error('Policy Document ID is undefined');
      }

      if (i === -1) {
        const id = uuidv4();
        const createdBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const createdOn = Timestamp.now();
        const adjustedDate = new Date(
          formValues.inceptionDate.toDate().getTime()
        );
        adjustedDate.setHours(0, 0, 0, 0);

        formValues.inceptionDate =
          this.dateTimeService.dateToTimestamp(adjustedDate);
        const waitingDate = formValues.inceptionDate;
        // Add the new add-on with the modified form values, created by and created on properties
        policyDoc.addOns?.push({
          ...formValues,
          id,
          waitingDate,
          createdBy,
          createdOn,
        });
      } else if (remove) {
        // Remove the add-on at the given index from the add-ons array
        policyDoc.addOns?.splice(i, 1);
        this.success = true;
      } else if (formValues.status == 'REQUESTED') {
        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const updatedOn = Timestamp.now();
        // Update the add-on at the given index with the modified form values, updated by and updated on properties
        policyDoc.addOns![i] = {
          ...policyDoc.addOns![i],
          ...formValues,
          updatedBy,
          updatedOn,
        };
      } else if (
        this.policyAddOnRequestApproved ||
        this.policyAddOnRequestDeclined
      ) {
        // Refactored code for policyAddOnRequestApproved and policyAddOnRequestDeclined
        const updateAddOn = (addOn: PolicyAddOn, index: number) => {
          if (addOn.status !== 'INACTIVE' && policyDoc.addOns) {
            const updatedBy = {
              uid: this.userService.userData?.uid,
              displayName: this.userService.userData?.displayName,
              email: this.userService.userData?.email,
              userLocationId: this.userService.userData?.currentUserLocationId,
            };
            const updatedOn = Timestamp.now();
            const waitingDate = this.policyAddOnRequestApproved
              ? addOn.requestedWaitingDate
              : addOn.waitingDate;

            policyDoc.addOns[index] = {
              ...policyDoc.addOns[index],
              status: 'ACTIVE',
              updatedBy,
              updatedOn,
              waitingDate,
            };

            if (policyDoc.addOns[index].requestedWaitingDate !== undefined) {
              delete policyDoc.addOns[index].requestedWaitingDate;
            }
          }
        };

        // policyDoc.addOns?.forEach(updateAddOn);
        if (typeof i === 'number' && policyDoc.addOns)
          updateAddOn(policyDoc.addOns[i], i);
      } else {
        const waitingDate = this.dateTimeService.verifyWaitingDate(
          policyDoc.addOns![i].waitingDate,
          formValues.inceptionDate,
          this.dateTimeService.verifyTimestamp(oldDoc?.addOns![i].inceptionDate)
        );

        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const updatedOn = Timestamp.now();
        policyDoc.addOns![i] = {
          ...policyDoc.addOns![i],
          ...formValues,
          waitingDate,
          updatedBy,
          updatedOn,
        };
      }

      // Code for updating the database
      const updatedBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const updatedOn = Timestamp.now();
      newDoc = JSON.parse(JSON.stringify(policyDoc));
      policyDoc.addOns = this.sortPolicyAddOns(policyDoc.addOns || []);

      let updateValues: any = {
        addOns: policyDoc.addOns,
        updatedBy,
        updatedOn,
      };

      if (formValues.status === 'REQUESTED' || this.policyAddOnsHasRequests) {
        updateValues.hasRequest = true;
      } else if (this.policyAddOnRequestApproved) {
        updateValues.hasRequest = false;
      }

      const policyDocRef = doc(this.dbModular, 'policy', policyDoc.id);
      await updateDoc(policyDocRef, updateValues);
      this.success = true;
    } catch (err) {
      // Error handling
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('POLICY ADD-ON UPDATE FAILED!');
    }

    // Final steps: updating the selected policy and logging
    if (policyDoc.id) await this.setSelectedPolicy(policyDoc.id);
    this.policyLogService.indexObject = 'addOn';
    this.policyLogService.updateLog(oldDoc, newDoc);
    this.loading = false;
    this.policyAddOnRequestApproved = false;
  }

  // Update the members of the given policy document with the given form values and index
  async updatePolicyMembers(
    policyDoc: Policy,
    formValues: any,
    i: number,
    remove?: boolean
  ) {
    this.loading = true;
    const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));
    let newDoc = JSON.parse(JSON.stringify(policyDoc));
    try {
      if (!policyDoc?.id) {
        throw new Error('Policy Document ID is undefined');
      }

      if (i === -1) {
        const id = uuidv4();
        const createdBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const createdOn = Timestamp.now();
        const adjustedDate = new Date(
          formValues.inceptionDate.toDate().getTime()
        );
        adjustedDate.setHours(0, 0, 0, 0);

        formValues.inceptionDate =
          this.dateTimeService.dateToTimestamp(adjustedDate);
        const waitingDate = formValues.inceptionDate;
        if (formValues.status != 'REQUESTED') formValues.status = 'ACTIVE';
        // Add the new member with the modified form values, created by and created on properties
        policyDoc.members?.push({
          ...formValues,
          id,
          createdBy,
          createdOn,
          waitingDate,
        });

        if (this.isPrimaryMember(formValues))
          this.snackBarService.dismissSnackBar();
      } else if (remove) {
        // Remove the member at the given index from the members array
        policyDoc.members?.splice(i, 1);
        this.success = true;
      } else if (
        this.policyMemberRequestApproved ||
        this.policyMemberRequestDeclined
      ) {
        // Refactored code for policyMemberRequestApproved and policyMemberRequestDeclined

        const updateMember = (member: Member, index: number) => {
          if (
            member.status !== 'INACTIVE' &&
            member.status !== 'CLAIMED' &&
            policyDoc.members
          ) {
            const updatedBy = {
              uid: this.userService.userData?.uid,
              displayName: this.userService.userData?.displayName,
              email: this.userService.userData?.email,
              userLocationId: this.userService.userData?.currentUserLocationId,
            };
            const updatedOn = Timestamp.now();
            const waitingDate = this.policyMemberRequestApproved
              ? member.requestedWaitingDate
              : member.waitingDate;

            policyDoc.members[index] = {
              ...policyDoc.members[index],
              status: 'ACTIVE',
              updatedBy,
              updatedOn,
              waitingDate,
              ...(formValues.status === 'REQUESTED'
                ? { requestReason: formValues.requestReason }
                : {}),
            };

            if (policyDoc.members[index].requestedWaitingDate !== undefined) {
              delete policyDoc.members[index].requestedWaitingDate;
            }
          }
        };
        if (typeof i === 'number' && policyDoc.members)
          updateMember(policyDoc.members[i], i);
        // policyDoc.members?.forEach(updateMember);
      } else {
        let waitingDate;
        if (policyDoc.members)
          waitingDate = this.dateTimeService.verifyWaitingDate(
            policyDoc.members[i].waitingDate,
            formValues.inceptionDate,
            this.dateTimeService.verifyTimestamp(
              oldDoc.members[i].inceptionDate
            )
          );

        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const updatedOn = Timestamp.now();

        policyDoc.members![i] = {
          ...policyDoc.members![i],
          ...formValues,
          waitingDate,
          updatedBy,
          updatedOn,
        };
      }
      // Check if the updated member is a primary member and if their status is 'INACTIVE' or 'CLAIMED'
      const updatedMember = policyDoc.members![i];
      if (
        this.isPrimaryMember(updatedMember) &&
        (updatedMember?.status === 'INACTIVE' ||
          updatedMember?.status === 'CLAIMED') &&
        updatedMember.memberTypeId
      ) {
        this.snackBarService.openPrimaryMemberSnackBar(
          'PLEASE SELECT A NEW ' +
            this.getMemberTypeNameAndClassById(updatedMember.memberTypeId)
              .text +
            '!'
        );
      }

      const updatedBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const updatedOn = Timestamp.now();

      // Update the member at the given index with the modified form values, updated by and updated on properties

      newDoc = JSON.parse(JSON.stringify(policyDoc));
      policyDoc.members = this.sortMembers(policyDoc.members || []);

      // Update the 'policy' collection in the database with the updated members array
      const memberIdNumbers = (policyDoc.members || []).map(
        (member) => member.idNumber
      );

      const memberIdAndCellNumbers = (policyDoc.members || []).map(
        (member) =>
          `${member.idNumber}${this.userService.toInternationalFormat(
            member.cellNumber ?? ''
          )}`
      );

      let updateValues: any = {
        members: policyDoc.members,
        updatedBy,
        updatedOn,
        memberIdNumbers,
        memberIdAndCellNumbers,
      };

      if (formValues.status === 'REQUESTED' || this.policyMembersHasRequests) {
        updateValues.hasRequest = true;
      } else if (this.policyMemberRequestApproved) {
        updateValues.hasRequest = false;
      }

      const policyDocRef = doc(this.dbModular, 'policy', policyDoc.id);
      await updateDoc(policyDocRef, updateValues);
      this.refreshAllowedMemberTypes();
      this.success = true;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      // Show an err message using the SnackBarService
      this.snackBarService.openRedSnackBar('POLICY MEMBER UPDATE FAILED! ');
    }
    // Update the selected policy with the updated members array and stop the loading state
    if (policyDoc.id) await this.setSelectedPolicy(policyDoc.id);
    this.policyLogService.indexObject = 'member';
    this.policyLogService.updateLog(oldDoc, newDoc);
    this.loading = false;
    this.policyMemberRequestApproved = false;
    this.policyMemberRequestDeclined = false;
    this.planService.overrideMemberIdAge = false;
    this.planService.requestOverrideMemberIdAge = false;
  }

  async updateAllPolicyAddOnRequests(policyDoc: Policy) {
    const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));
    if (
      policyDoc?.addOns && // Ensure addOns is not undefined
      policyDoc.id &&
      policyDoc.status != 'INACTIVE' &&
      policyDoc.status != 'LAPSED'
    ) {
      const updatedBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const updatedOn = Timestamp.now();
      policyDoc.addOns.forEach((addOn, index) => {
        if (addOn.status === 'REQUESTED') {
          this.policyAddOnRequestDeclined = true;

          const waitingDate = this.policyAddOnRequestApproved
            ? addOn.requestedWaitingDate
            : addOn.waitingDate;
          if (policyDoc.addOns) {
            policyDoc.addOns[index] = {
              ...addOn,
              status: 'ACTIVE',
              updatedBy,
              updatedOn,
              waitingDate,
            };

            if (policyDoc.addOns[index].requestedWaitingDate !== undefined) {
              delete policyDoc.addOns[index].requestedWaitingDate;
            }
          }
        }
      });

      let updateValues: any = {
        addOns: policyDoc.addOns,
        updatedBy,
        updatedOn,
      };

      const policyDocRef = doc(this.dbModular, 'policy', policyDoc.id);
      await updateDoc(policyDocRef, updateValues);
      let newDoc = JSON.parse(JSON.stringify(policyDoc));

      this.policyLogService.updateLog(oldDoc, newDoc);
    }
  }

  async updateAllPolicyMemberRequests(policyDoc: Policy) {
    const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));
    if (
      policyDoc?.members &&
      policyDoc.id &&
      policyDoc.status != 'INACTIVE' &&
      policyDoc.status != 'LAPSED'
    ) {
      const updatedBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const updatedOn = Timestamp.now();
      policyDoc.members.forEach((member, index) => {
        if (member.status === 'REQUESTED') {
          this.policyMemberRequestDeclined = true;

          const waitingDate = this.policyMemberRequestApproved
            ? member.requestedWaitingDate
            : member.waitingDate;
          if (policyDoc.members) {
            policyDoc.members[index] = {
              ...member,
              status: 'ACTIVE',
              updatedBy,
              updatedOn,
              waitingDate,
            };

            if (policyDoc.members[index].requestedWaitingDate !== undefined) {
              delete policyDoc.members[index].requestedWaitingDate;
            }
          }
        }
      });

      let updateValues: any = {
        members: policyDoc.members,
        updatedBy,
        updatedOn,
      };

      const policyDocRef = doc(this.dbModular, 'policy', policyDoc.id);
      await updateDoc(policyDocRef, updateValues);
      let newDoc = JSON.parse(JSON.stringify(policyDoc));

      this.policyLogService.updateLog(oldDoc, newDoc);
    }
  }

  async updatePolicyComments(
    policyDoc: Policy,
    formValues: any,
    i: number,
    remove?: boolean
  ) {
    this.loading = true;
    const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));
    try {
      if (!policyDoc?.id) {
        throw new Error('Policy Document ID is undefined');
      }

      if (i === -1) {
        const id = uuidv4();
        const createdBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const createdOn = Timestamp.now();
        policyDoc.comments?.push({ ...formValues, id, createdBy, createdOn });
      } else if (remove === true) {
        policyDoc.comments?.splice(i, 1);
      } else {
        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const updatedOn = Timestamp.now();
        // Update the member at the given index with the modified form values, updated by and updated on properties
        policyDoc.comments![i] = {
          ...policyDoc.comments![i],
          ...formValues,
          updatedBy,
          updatedOn,
        };
      }

      policyDoc.comments = this.sortComments(policyDoc.comments || []);

      const policyDocRef = doc(this.dbModular, 'policy', policyDoc.id);
      await updateDoc(policyDocRef, { comments: policyDoc.comments });

      this.selectedPolicyCommentsChanged.emit();
      this.success = true;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('POLICY COMMENT UPDATE FAILED!');
      this.success = false;
    }
    if (policyDoc.id !== this.selectedPolicy?.id)
      await this.setSelectedPolicy(policyDoc.id);
    this.policyLogService.indexObject = 'comment';
    this.policyLogService.updateLog(oldDoc, this.selectedPolicy);
    this.loading = false;
  }

  async updatePolicyFiles(
    policyDoc: Policy,
    formValues: any,
    i: number,
    remove?: boolean
  ) {
    this.loading = true;
    const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));
    try {
      if (!policyDoc?.id) {
        throw new Error('Policy Document ID is undefined');
      }

      if (i === -1) {
        const createdBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const createdOn = Timestamp.now();
        policyDoc.files?.push({ ...formValues, createdBy, createdOn });
      } else if (remove === true) {
        policyDoc.files?.splice(i, 1);
      } else {
        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const updatedOn = Timestamp.now();
        // Update the member at the given index with the modified form values, updated by and updated on properties
        policyDoc.files![i] = {
          ...policyDoc.files![i],
          ...formValues,
          updatedBy,
          updatedOn,
        };
      }

      const updatedBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const updatedOn = Timestamp.now();

      const policyDocRef = doc(this.dbModular, 'policy', policyDoc.id);
      await updateDoc(policyDocRef, {
        files: policyDoc.files,
        updatedBy,
        updatedOn,
      });
      this.success = true;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
    }
    if (policyDoc.id !== this.selectedPolicy?.id)
      await this.setSelectedPolicy(policyDoc.id);
    this.policyLogService.indexObject = 'file';
    this.policyLogService.updateLog(oldDoc, this.selectedPolicy);
    this.loading = false;
  }

  async updateLatestProductPaymentStatus(
    policyDoc: Policy,
    productsPaymentStatus: ProductPaymentStatus[]
  ) {
    this.loading = true;
    try {
      if (!policyDoc.id) {
        throw new Error('Policy Document ID is undefined');
      }

      const policyRef = doc(this.dbModular, 'policy', policyDoc.id);
      await updateDoc(policyRef, {
        productsPaymentStatus,
      });
      this.success = true;
      this.selectedPolicyProductPaymentStatusUpdated.emit();
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.loading = false;
      throw err;
    } finally {
      this.loading = false;
    }
  }

  async updateIntendedPaymentDay(
    policyDoc: Policy,
    intendedPaymentDay: number,
    reason: string
  ) {
    this.loading = true;
    try {
      if (!policyDoc.id) {
        throw new Error('Policy Document ID is undefined');
      }

      const oldDoc = JSON.parse(JSON.stringify(policyDoc));

      const policyDocRef = doc(this.dbModular, 'policy', policyDoc.id);
      await updateDoc(policyDocRef, {
        intendedPaymentDay,
      });

      if (policyDoc.id !== this.selectedPolicy?.id)
        await this.setSelectedPolicy(policyDoc.id);
      this.policyLogService.indexObject = 'policy';
      this.policyLogService.updateLog(oldDoc, this.selectedPolicy, reason);
      this.selectedPolicyIntendedPaymentDayUpdated.emit();
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.loading = false;
    }
    this.loading = false;
  }

  async updatePolicyUnallocatedBalance(policyDoc: Policy, amount: number) {
    this.loading = true;
    try {
      if (!policyDoc.id) {
        throw new Error('Policy Document ID is undefined');
      }

      const unallocatedBalance = (policyDoc.unallocatedBalance ?? 0) + amount;
      const policyRef = doc(this.dbModular, 'policy', policyDoc.id);
      await updateDoc(policyRef, {
        unallocatedBalance,
      });
      this.success = true;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  async updatePolicyDebitOrder(policyDoc: Policy, formValues: DebitOrder) {
    if (policyDoc.id) {
      this.mainService.setLoading(true);

      const createdBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };

      const createdOn = this.selectedPolicy?.debitOrder?.createdOn
        ? this.selectedPolicy?.debitOrder?.createdOn
        : Timestamp.now();
      const updatedBy = createdBy;
      const updatedOn = Timestamp.now();

      formValues = {
        ...formValues,
        updatedBy,
        updatedOn,
      } as DebitOrder;

      let updateData: any = {
        debitOrder: formValues,
      };

      if (formValues.deductionDay !== policyDoc.debitOrder?.deductionDay) {
        updateData.intendedPaymentDay = formValues.deductionDay;
      }

      if (!policyDoc.debitOrder) {
        updateData.debitOrder = {
          ...updateData.debitOrder,
          createdBy,
          createdOn,
        };
      }

      const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));

      try {
        const policyDocRef = doc(this.dbModular, 'policy', policyDoc.id);
        await updateDoc(policyDocRef, updateData);

        this.snackBarService.openBlueSnackBar(
          'DEBIT ORDER UPDATED SUCCESSFULLY!'
        );

        if (!oldDoc.debitOrder) {
          await this.policyLogService.newDebitOrderLog(formValues, policyDoc);
        } else {
          if (policyDoc.id !== this.selectedPolicy?.id)
            await this.setSelectedPolicy(policyDoc.id);
          this.policyLogService.indexObject = 'debitOrder';
          this.policyLogService.updateLog(oldDoc, this.selectedPolicy);
        }
      } catch (err) {
        if (err instanceof Error) {
          this.snackBarService.latestError = err.message;
          this.snackBarService.openRedSnackBar('UPDATING DEBIT ORDER FAILED!');
        }
        this.mainService.setLoading(false);
      } finally {
        this.mainService.setLoading(false);
      }
    }
  }

  async deletePolicy(docId: string) {
    this.loading = true;
    try {
      this.destroySelectedPolicy$.next();
      await new Promise((resolve) => setTimeout(resolve, 250));

      const policyDocRef = doc(this.dbModular, 'policy', docId);
      await deleteDoc(policyDocRef);

      this.snackBarService.openBlueSnackBar('POLICY DELETED SUCCESSFULLY!');
      this.success = true;
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('POLICY DELETION FAILED!');
      }
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  // Call this method to update the boolean value
  updateBoolean(updated: boolean) {
    this.updated$.next(updated);
  }

  // Subscribe to this method to get updates on the updated$ boolean value
  getUpdatedBoolean() {
    return this.updated$.asObservable();
  }

  /**
  Sorts an array of PolicyAddOn objects based on the following conditions:
  Active add ons should appear first and should be sorted in ascending order based on their 'createdOn' field.
  Inactive add ons should appear after all the active ones and should be sorted in descending order based on their 'updatedOn' field.
  */
  sortPolicyAddOns(addOns: PolicyAddOn[]): PolicyAddOn[] {
    const requestedAddOns = addOns.filter(
      (addOn) => addOn.status === 'REQUESTED'
    );
    const activeAddOns = addOns.filter((addOn) => addOn.status === 'ACTIVE');
    const inactiveAddOns = addOns.filter(
      (addOn) => addOn.status === 'INACTIVE'
    );

    requestedAddOns.sort((a, b) => {
      const aDate = a.updatedOn?.toDate() || new Date(0);
      const bDate = b.updatedOn?.toDate() || new Date(0);

      if (aDate > bDate) {
        return -1;
      }
      if (aDate < bDate) {
        return 1;
      }
      return 0;
    });

    activeAddOns.sort((a, b) => {
      const aDate = a.createdOn?.toDate() || new Date(0);
      const bDate = b.createdOn?.toDate() || new Date(0);

      if (aDate > bDate) {
        return 1;
      }
      if (aDate < bDate) {
        return -1;
      }
      return 0;
    });

    inactiveAddOns.sort((a, b) => {
      const aDate = a.updatedOn?.toDate() || new Date(0);
      const bDate = b.updatedOn?.toDate() || new Date(0);

      if (aDate > bDate) {
        return -1;
      }
      if (aDate < bDate) {
        return 1;
      }
      return 0;
    });

    return [...requestedAddOns, ...activeAddOns, ...inactiveAddOns];
  }

  /**
  Sorts an array of Member objects based on the following conditions:
  The Primary member should always be first.
  The spouse, if one exists, should be second.
  The remaining members should be sorted based on their 'waitingDate' field in descending order.
  Active members should appear before inactive ones.
  */
  sortMembers(members: Member[]): Member[] {
    let primaryMember: any;
    if (this.selectedPolicy)
      primaryMember = this.getPolicyPrimaryMember(this.selectedPolicy);

    const remainingMembers = primaryMember
      ? members.filter((member) => member.id !== primaryMember.id)
      : members;

    const spouse = remainingMembers.find(
      (member) =>
        this.getMemberTypeNameAndClassById(member.memberTypeId || '').text ===
          'SPOUSE' && member.status === 'ACTIVE'
    );

    const otherMembers = remainingMembers.filter(
      (member) => member.id !== spouse?.id
    );

    otherMembers.sort((a, b) => {
      if (a.status !== b.status) {
        return a.status === 'ACTIVE' ? -1 : 1;
      }

      const aDate = a.waitingDate?.toDate() || new Date(0);
      const bDate = b.waitingDate?.toDate() || new Date(0);

      if (aDate > bDate) {
        return -1;
      } else if (aDate < bDate) {
        return 1;
      }
      return 0;
    });

    const sortedMembers = primaryMember ? [primaryMember] : [];
    if (spouse) {
      sortedMembers.push(spouse);
    }
    sortedMembers.push(...otherMembers);

    return sortedMembers;
  }

  /**
   * Sorts an array of Comment objects in descending order based on the 'createdOn' field.
   */
  sortComments(comments: Comment[]): Comment[] {
    return comments.sort((a, b) => {
      const aDate = a.createdOn?.toDate() || new Date(0);
      const bDate = b.createdOn?.toDate() || new Date(0);

      if (aDate > bDate) {
        return -1;
      } else if (aDate < bDate) {
        return 1;
      }
      return 0;
    });
  }

  applyCommentsFilter() {
    this.dataSourceComments.filterPredicate = (
      data: Comment,
      filter: string
    ) => {
      const commentText = data.comment || '';
      const displayName = data.createdBy?.displayName || '';

      return (
        commentText.toLowerCase().includes(filter.toLowerCase()) ||
        displayName.toLowerCase().includes(filter.toLowerCase())
      );
    };

    this.dataSourceComments.filter = this.commentSearchText;
  }

  doesOwnComment(comment?: Comment): boolean {
    return comment
      ? comment.createdBy?.uid === this.userService.userData?.uid
      : false;
  }

  public refreshPolicyAddOnDataSource() {
    this.dataSourceAddOns = new MatTableDataSource(this.selectedPolicy?.addOns);
    this.filterService.filterClicked.policyAddOns =
      !this.filterService.filterClicked.policyAddOns;
    this.filterService.toggleInactiveFilter(
      'policyAddOns',
      this.dataSourceAddOns
    );
  }

  public refreshPolicyMemberDataSource() {
    this.dataSourcePolicyMembers = new MatTableDataSource(
      this.selectedPolicy?.members
    );
    this.filterService.filterClicked.policyMembers =
      !this.filterService.filterClicked.policyMembers;
    this.filterService.toggleInactiveFilter(
      'policyMembers',
      this.dataSourcePolicyMembers
    );
  }

  public refreshPolicyCommentsDataSource() {
    this.dataSourceComments = new MatTableDataSource(
      this.selectedPolicy?.comments
    );
    this.applyCommentsFilter();
  }

  resetSelectedPolicy() {
    this.selectedPolicy = undefined;
    this.policyLogService.selectedPolicyLog = undefined;
    this.policyLogService.currentRefNum = undefined;
    this.dataSourceAddOns = new MatTableDataSource<any>([]);
    this.dataSourcePolicyMembers = new MatTableDataSource<any>([]);
    this.dataSourceComments = new MatTableDataSource<any>([]);
    this.messageService.dataSourceMessages = new MatTableDataSource<any>([]);
    this.policyLogService.dataSourcePolicyLogs = new MatTableDataSource<any>(
      []
    );
  }

  unsubscribe() {
    this.policiesSubject.unsubscribe();
    this.resetSelectedPolicy();

    if (this.unsubscribeFromPoliciesSnapshot) {
      this.unsubscribeFromPoliciesSnapshot();
      this.unsubscribeFromPoliciesSnapshot = undefined;
    }

    if (this.unsubscribeFromOfflineTransactionPoliciesSnapshot) {
      this.unsubscribeFromOfflineTransactionPoliciesSnapshot();
      this.unsubscribeFromOfflineTransactionPoliciesSnapshot = undefined;
    }
  }

  //Close {
  // Unsubscribes from all subscriptions when the component is destroyed
  cleanUp() {
    this.policyCount = undefined;
    this.unsubscribe();
    this.destroy$.next();
  }
  //Close }
}
