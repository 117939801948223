<div class="pageRow pageSideMargins" [@fadeIn]>
  <h1 class="pageHeading">SETTINGS</h1>
</div>

<mat-nav-list [@fadeIn]>
  <div class="grid pageSideMargins">
    <div *ngIf="rolesRightsService.currentUserRole?.users?.read" class="flex">
      <a
        mat-list-item
        routerLink="/roles-rights-management"
        routerLinkActive="is-active"
      >
        <mat-card>
          <img
            class="whiteSvgColor"
            mat-card-image
            src="assets\images\roleMan.svg"
          />
        </mat-card>
      </a>
    </div>
    <div *ngIf="rolesRightsService.currentUserRole?.users?.read" class="flex">
      <a mat-list-item routerLink="/users" routerLinkActive="is-active">
        <mat-card>
          <img
            class="whiteSvgColor"
            mat-card-image
            src="assets\images\userMan.svg"
          />
        </mat-card>
      </a>
    </div>
    <div
      *ngIf="rolesRightsService.currentUserRole?.products?.read"
      class="flex"
    >
      <a mat-list-item routerLink="/products" routerLinkActive="is-active">
        <mat-card>
          <img
            class="whiteSvgColor"
            mat-card-image
            src="assets\images\productMan.svg"
          />
        </mat-card>
      </a>
    </div>
    <div *ngIf="canImport" class="flex">
      <a mat-list-item routerLink="/imports" routerLinkActive="is-active">
        <mat-card>
          <img
            class="whiteSvgColor"
            mat-card-image
            src="assets\images\dataMan.svg"
          />
        </mat-card>
      </a>
    </div>
    <!-- <div class="flex">
      <a mat-list-item routerLink="" routerLinkActive="is-active">
        <mat-card>
          <img class="whiteSvgColor" mat-card-image src="assets\images\transactionMan.svg" />
        </mat-card>
      </a>
    </div> -->
  </div>
</mat-nav-list>
