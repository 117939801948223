import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  NetcashBatch,
  NetcashBatchHistory,
  Payment,
  PaymentMethod,
  PaymentSource,
  ProductItem,
  Transaction,
  TransactionCount,
  TransactionStatus,
} from '../models/transaction.model';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  firstValueFrom,
  from,
  map,
  takeUntil,
} from 'rxjs';
import { SnackBarService } from './snack-bar.service';
import { Router } from '@angular/router';
import { PolicyService } from './policy.service';
import { AddOnService } from './add-on.service';
import { DateTimeService } from './date-time.service';
import { PlanService } from './plan.service';
import { UserService } from './user.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  Member,
  Policy,
  PolicyAddOn,
  PolicyStatus,
  ProductPaymentStatus,
} from '../models/policy.model';
import { MainService } from 'src/app/services/main.service';
import { TransactionLogService } from './transaction-log.service';
import { PolicyLogService } from './policy-log.service';
import { FilterService } from './filter.service';
import { MessageService } from './message.service';
import { CashUp, DailyCashUp } from '../models/user.model';
import { RolesRightsService } from './roles-rights.service';
import {
  doc,
  DocumentReference,
  getFirestore,
  runTransaction,
  Timestamp,
} from '@firebase/firestore';
import {
  addDoc,
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private dbModular = getFirestore();
  private fireFunctions = getFunctions(undefined, 'europe-west3');

  loadedTransactions: Transaction[];
  transactionCount: TransactionCount | undefined;
  netcashBatchHistory: NetcashBatchHistory | undefined;
  loadedSelectedPolicyTransactions: Transaction[];
  private lastSelectedPolicyTransactionDoc: DocumentReference<Transaction> | null =
    null;
  lastLoadedTransactionDoc: DocumentReference<Transaction> | null = null;
  dailyTransactions: Transaction[] = [];
  filteredDailyTransactions: Transaction[] = [];
  displayedTransactions: Transaction[] = [];
  selectedTransaction: Transaction | undefined;
  allNetcashBatches: NetcashBatch[] | undefined;
  filteredNetcashBatches: NetcashBatch[] = [];
  selectedDailyCashUps: CashUp[] = [];
  selectedDailyCashUpDoc: DailyCashUp | undefined;
  userDailyCashUp: CashUp[] = [];
  userDailyCashUpTotals: any;
  cashTotalsMatch: boolean | undefined;
  cardTotalsMatch: boolean | undefined;
  cashVarianceIsZero: boolean | undefined;
  cardVarianceIsZero: boolean | undefined;
  isPaginatingTransactions = false;
  isTransactionsBehind = false;

  selectedTransaction$: Observable<Transaction | undefined>;
  transactionDoc: DocumentReference<Transaction>;
  returnRoute: string | undefined;
  selectedPolicyTransactions: Transaction[] = [];
  selectedDailyTransactionDate: Date = new Date(
    Date.now() - 24 * 60 * 60 * 1000
  );
  offlineReceiptCounter: string | undefined;
  offlineReceiptDate: Date | undefined;

  dataSourceTransactions: MatTableDataSource<Transaction>;
  dataSourceSelectedPolicyTransactions: MatTableDataSource<Transaction>;
  dataSourceNetcashBatchHistory: MatTableDataSource<NetcashBatch>;
  dataSourceDailyTransactions: MatTableDataSource<Transaction>;

  // BehaviorSubject to hold the transaction data
  private transactionsSubject = new BehaviorSubject<Transaction[]>([]);
  public transactions$ = this.transactionsSubject.asObservable();
  transactionCountDoc: DocumentReference<TransactionCount>;
  transactionCount$: Observable<TransactionCount | undefined>;
  netcashBatchHistoryDoc: DocumentReference<NetcashBatchHistory>;
  netcashBatchHistory$: Observable<NetcashBatchHistory | undefined>;

  public selectedPolicyTransactionsSubject = new BehaviorSubject<Transaction[]>(
    []
  );
  public selectedPolicyTransactions$ =
    this.selectedPolicyTransactionsSubject.asObservable();

  unsubscribeFromTransactionsSnapshot: (() => void) | undefined = undefined;
  unsubscribeFromSelectedPolicyTransactionsSnapshot: (() => void) | undefined =
    undefined;

  @Output() loadedTransactionsUpdated = new EventEmitter<void>();
  @Output() loadedSelectedPolicyTransactionsUpdated = new EventEmitter<void>();
  @Output() loadedDailyTransactionsUpdated = new EventEmitter<void>();
  @Output() netcashBatchHistoryUpdated = new EventEmitter<void>();
  @Output() transactionTransferredOrReversed = new EventEmitter<void>();

  netcashHistoryFilter: string = 'ALL';
  netcashHistorySearchText: string = '';

  loading = false;
  transactionsLoading = false;
  selectedPolicyTransactionsLoading = false;

  destroy$ = new Subject<void>();

  updateSuccess = false;

  constructor(
    private mainService: MainService,
    private snackBarService: SnackBarService,
    private router: Router,
    public policyService: PolicyService,
    public planService: PlanService,
    public dateTimeService: DateTimeService,
    public addOnService: AddOnService,
    private userService: UserService,
    private transactionLogService: TransactionLogService,
    private policyLogService: PolicyLogService,
    public rolesRightsService: RolesRightsService,
    private filterService: FilterService,
    private messageService: MessageService
  ) {
    this.userService.destroy$.pipe().subscribe(() => this.cleanUp());

    this.policyService.selectedPolicyChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.resetSelectedPolicyTransactions();
      });
  }

  /**
   * Sets the transaction with the given ID as the currently selected transaction.
   *
   * @param {string} transactionId The ID of the transaction to set as selected.
   *
   * @returns {Promise<void>}
   * @throws Will throw an error if the transaction ID is not provided or if there's an issue fetching the transaction.
   */
  async setSelectedTransaction(transactionId: string): Promise<void> {
    try {
      if (!transactionId) {
        this.router.navigate(['/transactions']);
        throw new Error('DOCUMENT ID NOT PROVIDED!');
      }

      const transactionDocRef = doc(
        this.dbModular,
        'transaction',
        transactionId
      );

      this.selectedTransaction$ = new Observable<Transaction>((observer) => {
        const unsubscribe = onSnapshot(
          transactionDocRef,
          (doc) => {
            if (doc.exists()) {
              const transaction = { ...doc.data(), id: doc.id } as Transaction;
              observer.next(transaction);
            } else {
              observer.error(new Error('Transaction document does not exist'));
            }
          },
          (error) => observer.error(error)
        );

        return { unsubscribe };
      });

      await new Promise<void>((resolve, reject) => {
        this.selectedTransaction$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (transaction) => {
            if (transaction) {
              this.selectedTransaction = transaction;
            }
            resolve();
          },
          error: (err) => {
            if (err instanceof Error)
              this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar(
              'ERROR SETTING SELECTED TRANSACTION'
            );
            reject(err);
          },
        });
      });
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar(
        'ERROR SETTING SELECTED TRANSACTION'
      );
      throw err;
    }
  }

  /**
   * Retrieves a list of transactions associated with a given policy ID.
   *
   * @param {any} policyId The policy ID for which to retrieve transactions.
   * @param {Date} [fromDate] The start date for the period from which to retrieve transactions (optional).
   * @param {Date} [toDate] The end date for the period from which to retrieve transactions (optional).
   *
   * @returns {Promise<Transaction[]>} A promise that resolves with the list of transactions.
   * @throws Will throw an error if there's an issue fetching the transactions.
   */
  getTransactionsByPolicyId(
    policyId: any,
    fromDate?: Date,
    toDate?: Date
  ): Promise<Transaction[]> {
    this.selectedPolicyTransactions = [];

    return new Promise(async (resolve, reject) => {
      try {
        const transactionCollectionRef = collection(
          this.dbModular,
          'transaction'
        );
        let constraints = [
          where('policyId', '==', policyId),
          orderBy('transactionDate', 'desc'),
        ];

        if (fromDate && toDate) {
          constraints.push(
            where('transactionDate', '>=', fromDate),
            where('transactionDate', '<=', toDate)
          );
        }

        if (this.filterService.policyTransactionFilter !== 'all') {
          constraints.push(
            where('status', 'in', [
              TransactionStatus.PAID,
              TransactionStatus.ALLOCATED,
            ]),
            where('isReversed', '==', false)
          );
        }

        const transactionQuery = query(
          transactionCollectionRef,
          ...constraints
        );
        const querySnapshot = await getDocs(transactionQuery);

        querySnapshot.forEach((doc) => {
          const data = doc.data() as Transaction;
          data.id = doc.id;
          if (data.createdOn) {
            this.selectedPolicyTransactions.push(data);
          }
        });

        resolve(this.selectedPolicyTransactions);
      } catch (err) {
        if (err instanceof Error) {
          this.snackBarService.latestError = err.message;
          this.snackBarService.openRedSnackBar(
            'GETTING TRANSACTIONS BY POLICY ID FAILED!'
          );
        }
        reject(err);
      }
    });
  }

  loadTransactions(
    pageIndex: number = 0,
    pageSize: number = 20
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.loading = true;

      if (this.transactionsSubject.closed && this.userService.isLoggedIn()) {
        this.transactionsSubject = new BehaviorSubject<Transaction[]>([]);
      }

      const transactionCollectionRef = collection(
        this.dbModular,
        'transaction'
      );
      let constraints: QueryConstraint[] = [
        orderBy('counter', 'desc'),
        limit(pageSize),
      ];

      if (this.filterService.transactionFilter === TransactionStatus.ALL) {
        constraints.push(
          where('status', 'in', [
            TransactionStatus.PAID,
            TransactionStatus.UNPAID,
            TransactionStatus.UNIDENTIFIED,
            TransactionStatus.IDENTIFIED,
            TransactionStatus.REVERSED,
            TransactionStatus.TRANSFERRED_IN,
            TransactionStatus.UNALLOCATED,
          ])
        );
      } else {
        constraints.push(
          where('status', '==', this.filterService.transactionFilter)
        );
      }

      if (this.lastLoadedTransactionDoc) {
        constraints.push(startAfter(this.lastLoadedTransactionDoc));
      }

      const transactionQuery = query(transactionCollectionRef, ...constraints);

      this.unsubscribeFromTransactionsSnapshot = onSnapshot(
        transactionQuery,
        async (querySnapshot) => {
          try {
            if (
              !querySnapshot.metadata.hasPendingWrites &&
              !querySnapshot.metadata.fromCache
            ) {
              const isIntentionalPagination =
                this.loadedTransactions &&
                pageIndex > 0 &&
                (pageIndex + 1) * pageSize > this.loadedTransactions.length;

              if (isIntentionalPagination) {
                this.isPaginatingTransactions = true;
              }

              if (!this.transactionCount) await this.loadTransactionCount();

              let transactions: Transaction[] = [];
              let lastDoc = null;

              querySnapshot.forEach((doc) => {
                const transactionData = doc.data() as Transaction;
                transactionData.id = doc.id;
                transactions.push(transactionData);
                lastDoc = doc;
              });

              if (
                this.filterService.transactionFilter ===
                  TransactionStatus.ALL ||
                !transactions.some(
                  (transaction) =>
                    transaction.status !== this.filterService.transactionFilter
                )
              ) {
                if (this.isPaginatingTransactions && !isIntentionalPagination) {
                  this.isTransactionsBehind = true;
                  this.loading = false;
                  resolve();
                  return;
                }
                this.transactionsLoading = true;

                this.lastLoadedTransactionDoc = lastDoc;

                this.loadedTransactions = [
                  ...(this.loadedTransactions || []).slice(
                    0,
                    pageIndex * pageSize
                  ),
                  ...transactions,
                ];

                const transactionsForPage = this.loadedTransactions.slice(
                  pageIndex,
                  pageSize
                );

                this.transactionsSubject.next(this.loadedTransactions);
                this.updateCurrentTransactions(transactionsForPage);
                this.loadedTransactionsUpdated.emit();
              }
            }
            this.transactionsLoading = false;
            this.loading = false;
            resolve();
          } catch (err) {
            if (err instanceof Error)
              this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar('ERROR LOADING TRANSACTIONS');
            this.transactionsLoading = false;
            this.loading = false;

            reject(err);
          }
        },
        (error) => {
          console.error('Error fetching snapshot:', error);
          reject(error);
        }
      );
    });
  }

  updateCurrentTransactions(transactions: Transaction[]) {
    if (!this.dataSourceTransactions) {
      this.dataSourceTransactions = new MatTableDataSource<Transaction>(
        transactions
      );
    } else {
      this.dataSourceTransactions.data = transactions;
      this.dataSourceTransactions._updateChangeSubscription();
    }
  }

  async loadTransactionCount() {
    try {
      const transactionCountDocRef = doc(
        this.dbModular,
        'metaData',
        'transaction'
      );

      this.transactionCount$ = new Observable<TransactionCount>((observer) => {
        const unsubscribe = onSnapshot(
          transactionCountDocRef,
          (doc) => {
            if (doc.exists()) {
              const transactionCount = {
                id: doc.id,
                ...doc.data(),
              } as TransactionCount;
              observer.next(transactionCount);
            } else {
              this.snackBarService.openRedSnackBar(
                'TRANSACTION COUNT DOCUMENT NOT FOUND!'
              );
              observer.error(
                new Error('TRANSACTION COUNT DOCUMENT NOT FOUND!')
              );
            }
          },
          (error) => observer.error(error)
        );

        return { unsubscribe };
      });

      await new Promise<void>((resolve, reject) => {
        this.transactionCount$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (transaction) => {
            if (transaction) {
              this.transactionCount = transaction;
              resolve();
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        throw err;
      }
    }
  }

  async loadNetcashBatchHistory() {
    try {
      const netcashBatchHistoryDocRef = doc(
        this.dbModular,
        'metaData',
        'netcashBatchHistory'
      );

      this.netcashBatchHistory$ = new Observable<NetcashBatchHistory>(
        (observer) => {
          const unsubscribe = onSnapshot(
            netcashBatchHistoryDocRef,
            (doc) => {
              if (doc.exists()) {
                const netcashBatchHistory = {
                  id: doc.id,
                  ...doc.data(),
                } as NetcashBatchHistory;
                observer.next(netcashBatchHistory);
              } else {
                this.snackBarService.openRedSnackBar(
                  'NETCASH BATCH HISTORY DOCUMENT NOT FOUND!'
                );
                observer.error(
                  new Error('NETCASH BATCH HISTORY DOCUMENT NOT FOUND!')
                );
              }
            },
            (error) => observer.error(error)
          );

          return { unsubscribe };
        }
      );

      await new Promise<void>((resolve, reject) => {
        this.netcashBatchHistory$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (netcashBatchHistory) => {
            if (netcashBatchHistory) {
              this.netcashBatchHistory = netcashBatchHistory;
              this.refreshAllNetcashBatches();
              this.netcashBatchHistoryUpdated.emit();
            }
            resolve();
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        throw err;
      }
    }
  }

  updateCurrentNetcashBatches(batches: NetcashBatch[]) {
    if (!this.dataSourceNetcashBatchHistory) {
      this.dataSourceNetcashBatchHistory = new MatTableDataSource<NetcashBatch>(
        batches
      );
    } else {
      this.dataSourceNetcashBatchHistory.data = batches;
      this.dataSourceNetcashBatchHistory._updateChangeSubscription();
    }
  }

  loadSelectedPolicyTransactions(
    pageIndex: number = 0,
    pageSize: number = 10
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loading = true;

      if (
        this.selectedPolicyTransactionsSubject.closed &&
        this.userService.isLoggedIn()
      ) {
        this.selectedPolicyTransactionsSubject = new BehaviorSubject<
          Transaction[]
        >([]);
      }

      const transactionsCollectionRef = collection(
        this.dbModular,
        'transaction'
      );

      let q = query(
        transactionsCollectionRef,
        where('policyId', '==', this.policyService.selectedPolicy?.id),
        orderBy('policyId'),
        orderBy('counter', 'desc')
      );

      if (this.filterService.policyTransactionFilter !== 'all') {
        q = query(
          q,
          where('status', 'in', [
            TransactionStatus.PAID,
            TransactionStatus.ALLOCATED,
          ]),
          where('isReversed', '==', false)
        );
      }

      if (this.lastSelectedPolicyTransactionDoc) {
        q = query(q, startAfter(this.lastSelectedPolicyTransactionDoc));
      }

      q = query(q, limit(pageSize));

      if (this.unsubscribeFromSelectedPolicyTransactionsSnapshot) {
        this.unsubscribeFromSelectedPolicyTransactionsSnapshot();
        this.unsubscribeFromSelectedPolicyTransactionsSnapshot = undefined;
      }

      onSnapshot(
        q,
        (querySnapshot) => {
          try {
            if (!querySnapshot.metadata.fromCache) {
              let transactions: Transaction[] = [];
              let lastDoc = null;

              querySnapshot.forEach((doc) => {
                const transactionData = {
                  ...doc.data(),
                  id: doc.id,
                } as Transaction;
                transactions.push(transactionData);
                lastDoc = doc;
              });

              if (
                !transactions.some(
                  (transaction) =>
                    transaction.policyId !==
                    this.policyService.selectedPolicy?.id
                ) &&
                (this.filterService.policyTransactionFilter === 'all' ||
                  !transactions.some(
                    (transaction) =>
                      (transaction.status !== TransactionStatus.PAID &&
                        transaction.status !== TransactionStatus.ALLOCATED) ||
                      transaction.isReversed
                  ))
              ) {
                this.selectedPolicyTransactionsLoading = true;
                const isIntentionalPagination =
                  this.loadedSelectedPolicyTransactions &&
                  pageIndex > 0 &&
                  (pageIndex + 1) * pageSize >
                    this.loadedSelectedPolicyTransactions.length;

                if (!isIntentionalPagination) {
                  this.loadedSelectedPolicyTransactions = [];
                }

                this.lastSelectedPolicyTransactionDoc = lastDoc;

                this.loadedSelectedPolicyTransactions = [
                  ...(this.loadedSelectedPolicyTransactions || []).slice(
                    0,
                    pageIndex * pageSize
                  ),
                  ...transactions,
                ];

                const transactionsForPage = transactions;

                this.selectedPolicyTransactionsSubject.next(
                  this.loadedSelectedPolicyTransactions
                );
                this.updateCurrentSelectedPolicyTransactions(
                  transactionsForPage
                );

                if (!isIntentionalPagination) {
                  this.loadedSelectedPolicyTransactionsUpdated.emit();
                }
              }
            }
            resolve();
            this.selectedPolicyTransactionsLoading = false;
            this.loading = false;
          } catch (err) {
            if (err instanceof Error)
              this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar(
              `ERROR LOADING SELECTED POLICY'S TRANSACTIONS`
            );
            this.loading = false;
            this.selectedPolicyTransactionsLoading = false;

            reject(err);
          }
        },
        (error) => {
          console.error('Error fetching snapshot:', error);
          reject(error);
        }
      );
    });
  }

  updateCurrentSelectedPolicyTransactions(transactions: Transaction[]) {
    if (!this.dataSourceSelectedPolicyTransactions) {
      this.dataSourceSelectedPolicyTransactions =
        new MatTableDataSource<Transaction>(transactions);
    } else {
      this.dataSourceSelectedPolicyTransactions.data = transactions;
      this.dataSourceSelectedPolicyTransactions._updateChangeSubscription();
    }
  }

  /**
   * Loads all transactions created by a specific user on a given date.
   * If the uid is 'ALL USERS', it loads transactions for all users.
   *
   * @param {string} uid - The user's unique identifier or 'ALL USERS' for all users.
   * @param {Date} date - The date for which to retrieve the transactions.
   */
  async loadAllDailyTransactionsByUserId(
    uid: string,
    date: Date
  ): Promise<void> {
    this.loading = true;
    this.dailyTransactions = [];

    await this.loadUserDailyCashUp(date);

    const startOfDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const endOfDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );

    const transactionsCollectionRef = collection(this.dbModular, 'transaction');
    let constraints = [
      where('createdOn', '>=', startOfDay),
      where('createdOn', '<=', endOfDay),
      where('status', 'in', [
        TransactionStatus.PAID,
        TransactionStatus.UNALLOCATED,
        TransactionStatus.REVERSED,
        TransactionStatus.UNIDENTIFIED,
      ]),
      where('method', 'in', [
        PaymentMethod.ONLINE_CASH,
        PaymentMethod.OFFLINE_CASH,
        PaymentMethod.ONLINE_CARD,
        PaymentMethod.OFFLINE_CARD,
        PaymentMethod.OFFLINE_EFT,
      ]),
      orderBy('createdOn', 'desc'),
    ];

    if (uid !== 'all-users') {
      constraints.push(where('createdBy.uid', '==', uid));
    }

    const transactionsQuery = query(transactionsCollectionRef, ...constraints);

    try {
      const querySnapshot = await getDocs(transactionsQuery);
      let transactions: Transaction[] = [];
      querySnapshot.forEach((doc) => {
        transactions.push({ ...doc.data(), id: doc.id } as Transaction);
      });

      if (uid === 'all-users') {
        transactions = transactions.filter(
          (transaction) => transaction.createdBy?.uid !== 'SYSTEM'
        );
      }
      this.dailyTransactions = transactions;

      await this.toggleDailyTransactionFilter(
        this.filterService.dailyTransactionMethodFilter.toLocaleUpperCase()
      );
      this.dailyTransactions = this.filteredDailyTransactions
        .filter(
          (transaction) =>
            transaction.createdBy?.uid !== 'SYSTEM' &&
            transaction.createdBy?.uid !== null
        )
        .sort((a, b) => {
          const emailA = a.createdBy?.email || '';
          const emailB = b.createdBy?.email || '';
          return emailA.localeCompare(emailB);
        });

      this.loading = false;
      this.loadedTransactionsUpdated.emit();
      this.loadedDailyTransactionsUpdated.emit();
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar(
        'ERROR LOADING TRANSACTIONS BY USER AND DATE'
      );
      this.loading = false;
      throw err;
    }
  }

  async toggleDailyTransactionFilter(filter: string) {
    this.filterService.dailyTransactionMethodFilter =
      filter.toLocaleUpperCase();
    // Filter the transactions based on the current filter state
    this.filteredDailyTransactions = this.dailyTransactions.filter(
      (transaction) => {
        if (filter === 'ONLINE') {
          return (
            transaction.method === 'ONLINE CASH' ||
            transaction.method === 'ONLINE CARD' ||
            transaction.method === 'ONLINE EFT'
          );
        } else {
          return (
            transaction.method === 'OFFLINE CASH' ||
            transaction.method === 'OFFLINE CARD' ||
            transaction.method === 'OFFLINE EFT'
          );
        }
      }
    );
  }

  async loadUserDailyCashUp(date: Date): Promise<void> {
    this.selectedDailyCashUps = [];

    const cashUpCollectionRef = collection(this.dbModular, 'cashUp');
    const cashUpQuery = query(cashUpCollectionRef, where('day', '==', date));

    const cashUpsObservable = new Observable<DailyCashUp[]>((subscriber) => {
      const unsubscribe = onSnapshot(
        cashUpQuery,
        (snapshot) => {
          const cashUps: DailyCashUp[] = [];
          snapshot.forEach((doc) => {
            const cashUpData = { ...doc.data(), id: doc.id } as DailyCashUp;
            cashUps.push(cashUpData);
          });
          subscriber.next(cashUps);
        },
        (error) => subscriber.error(error)
      );

      return { unsubscribe };
    });

    cashUpsObservable.pipe(takeUntil(this.destroy$)).subscribe({
      next: (cashUps) => {
        this.selectedDailyCashUps = [];
        cashUps.forEach((cashUpData) => {
          this.selectedDailyCashUpDoc = cashUpData;
          this.selectedDailyCashUps = this.selectedDailyCashUps.concat(
            cashUpData.cashUps ?? []
          );
        });

        if (this.userService.dailyTransactionSelectedUser?.uid != 'all-users') {
          const filteredCashUps = this.selectedDailyCashUps.filter(
            (cashUp) =>
              cashUp.createdBy?.uid ===
              this.userService.dailyTransactionSelectedUser?.uid
          );
          this.userDailyCashUp = filteredCashUps;
          this.userDailyCashUpTotals =
            this.calculateUserDailyCashUpTotals(filteredCashUps);
          this.checkTotalsMatch();
        } else {
          this.userDailyCashUp = this.selectedDailyCashUps;
          this.userDailyCashUpTotals = this.calculateUserDailyCashUpTotals(
            this.selectedDailyCashUps
          );
          this.checkTotalsMatch();
        }
      },
      error: (err) => {
        if (err instanceof Error) {
          this.snackBarService.latestError = err.message;
          this.snackBarService.openRedSnackBar(
            'ERROR LOADING USER DAILY CASH UP!'
          );
        }
      },
    });
  }

  calculateUserDailyCashUpTotals(cashUps: CashUp[]) {
    let cashUpCashDeclaredTotal = 0;
    let cashUpCardDeclaredTotal = 0;
    let cashUpCashExpectedTotal = 0;
    let cashUpCardExpectedTotal = 0;
    let cashUpCashVarianceTotal = 0;
    let cashUpCardVarianceTotal = 0;

    cashUps.map((cashUp) => {
      const cashDeclared = cashUp.cashDeclared || 0;
      const cardDeclared = cashUp.cardDeclared || 0;
      const cashExpected = cashUp.cashExpected || 0;
      const cardExpected = cashUp.cardExpected || 0;
      const cashVariance = cashUp.cashVariance || cashDeclared - cashExpected;
      const cardVariance = cashUp.cardVariance || cardDeclared - cardExpected;

      // Update the totals
      cashUpCashDeclaredTotal += cashDeclared;
      cashUpCardDeclaredTotal += cardDeclared;
      cashUpCashExpectedTotal += cashExpected;
      cashUpCardExpectedTotal += cardExpected;
      cashUpCashVarianceTotal += cashVariance;
      cashUpCardVarianceTotal += cardVariance;

      // Assuming you want to keep the updatedCashUp object for some reason
      const updatedCashUp = {
        ...cashUp,
        cashUpTotal: cashDeclared + cardDeclared,
      };
      return updatedCashUp;
    });

    // Store the computed totals in an object for easier access and manipulation
    return {
      cashUpCashExpectedTotal,
      cashUpCashDeclaredTotal,
      cashUpCashVarianceTotal,
      cashUpCardExpectedTotal,
      cashUpCardDeclaredTotal,
      cashUpCardVarianceTotal,
    };
  }

  checkTotalsMatch() {
    // Compare and set boolean flags based on whether the expected totals match the declared totals
    this.cashTotalsMatch =
      this.userDailyCashUpTotals.cashUpCashExpectedTotal ===
      this.userDailyCashUpTotals.cashUpCashDeclaredTotal;
    this.cardTotalsMatch =
      this.userDailyCashUpTotals.cashUpCardExpectedTotal ===
      this.userDailyCashUpTotals.cashUpCardDeclaredTotal;

    // Optionally, you might also want to check if variances are zero as an additional check
    this.cashVarianceIsZero =
      this.userDailyCashUpTotals.cashUpCashVarianceTotal === 0;
    this.cardVarianceIsZero =
      this.userDailyCashUpTotals.cashUpCardVarianceTotal === 0;
  }

  async updateCashUp(cashUpData: CashUp, cashUpId: string): Promise<void> {
    if (this.rolesRightsService.currentUserRole?.transactions?.userMonitoring) {
      try {
        if (this.selectedDailyCashUpDoc?.id) {
          const dailyCashUpData: DailyCashUp = this.selectedDailyCashUpDoc;

          if (dailyCashUpData.cashUps && dailyCashUpData.cashUps.length > 0) {
            const index = dailyCashUpData.cashUps.findIndex(
              (cu) => cu.id === cashUpId
            );

            if (
              index !== -1 &&
              cashUpData.cashDeclared &&
              cashUpData.cardDeclared
            ) {
              const updatedBy = {
                uid: this.userService.userData?.uid,
                displayName: this.userService.userData?.displayName,
                email: this.userService.userData?.email,
                userLocationId:
                  this.userService.userData?.currentUserLocationId,
              };
              const updatedOn = Timestamp.now();

              const cashVariance =
                cashUpData.cashDeclared -
                (dailyCashUpData.cashUps[index].cashExpected ?? 0);
              const cardVariance =
                cashUpData.cardDeclared -
                (dailyCashUpData.cashUps[index].cardExpected ?? 0);

              dailyCashUpData.cashUps[index] = {
                ...dailyCashUpData.cashUps[index],
                ...cashUpData,
                cashVariance,
                cardVariance,
                comment: cashUpData.comment,
                updatedBy,
                updatedOn,
              };

              // Proceed to update the document in Firestore
              await updateDoc(
                doc(this.dbModular, 'cashUp', this.selectedDailyCashUpDoc.id),
                {
                  cashUps: dailyCashUpData.cashUps,
                }
              );
              this.snackBarService.openBlueSnackBar('CASH UP UPDATED');
            } else {
              this.snackBarService.openRedSnackBar(
                'NO MATCHING CASHUP ID FOUND!'
              );
            }
          } else {
            this.snackBarService.openRedSnackBar('NO CASHUP TO UPDATE!');
          }
        } else {
          this.snackBarService.openRedSnackBar('DAILY CASHUP NOT FOUND!');
        }
      } catch (err) {
        if (err instanceof Error)
          this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('ERROR UPDATING CASHUP!');
      }
    } else {
      this.snackBarService.openRedSnackBar('INSUFFICIENT RIGHTS!');
    }
  }

  async deleteCashUp(cashUpData: CashUp): Promise<void> {
    if (this.rolesRightsService.currentUserRole?.transactions?.delete) {
      try {
        if (this.selectedDailyCashUpDoc?.id) {
          const dailyCashUpData: DailyCashUp = this.selectedDailyCashUpDoc;

          if (dailyCashUpData.cashUps && dailyCashUpData.cashUps.length > 0) {
            const cashUpsUpdated = dailyCashUpData.cashUps.filter(
              (cu: CashUp) => cu.id !== cashUpData.id
            );
            await updateDoc(
              doc(this.dbModular, 'cashUp', this.selectedDailyCashUpDoc.id),
              {
                cashUps: cashUpsUpdated,
              }
            );
            this.snackBarService.openBlueSnackBar('CASH UP DELETED');
          } else {
            this.snackBarService.openRedSnackBar('NO CASH UPS TO DELETE!');
          }
        } else {
          this.snackBarService.openRedSnackBar('DAILY CASH UP NOT FOUND!');
        }
      } catch (err) {
        if (err instanceof Error)
          this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('ERROR DELETING CASH UP!');
      }
    } else {
      this.snackBarService.openRedSnackBar('INSUFFICIENT RIGHTS!');
    }
  }

  updateCurrentDailyTransaction(transactions: Transaction[]) {
    if (!this.dataSourceDailyTransactions) {
      this.dataSourceDailyTransactions = new MatTableDataSource<Transaction>(
        transactions
      );
    } else {
      this.dataSourceDailyTransactions.data = transactions;
      this.dataSourceDailyTransactions._updateChangeSubscription();
    }
  }

  get totalTransactionCount(): number {
    if (!this.transactionCount?.count) return 0;
    return Object.entries(this.transactionCount.count)
      .filter(([key]) => key !== TransactionStatus.TRANSFERRED_OUT)
      .reduce((a, [, value]) => a + (value || 0), 0);
  }

  get totalPolicyTransactionCount(): number {
    if (!this.policyService.selectedPolicy?.transactionCount) return 0;
    return Object.entries(
      this.policyService.selectedPolicy.transactionCount
    ).reduce((a, [, value]) => a + (value || 0), 0);
  }

  /**
   * Calculates the daily transaction total for a specific payment method and type.
   * @param method - The payment method to filter by (CASH, CARD, or none for all).
   * @param type - The type to filter by (ONLINE, OFFLINE, or none for both).
   * @returns The total value of transactions for the day filtered by the method and type.
   */
  getSpecifiedDailyTransactionTotal(
    transactions: Transaction[],
    method?: string,
    type?: string
  ) {
    let total = 0;

    // Helper function to determine the accepted methods based on method and type
    const determineAcceptedMethods = (
      method?: string,
      type?: string
    ): string[] => {
      switch (method) {
        case 'CASH':
          return type ? [`${type} CASH`] : ['ONLINE CASH', 'OFFLINE CASH'];
        case 'CARD':
          return type ? [`${type} CARD`] : ['ONLINE CARD', 'OFFLINE CARD'];
        case 'EFT':
          return type ? [`${type} EFT`] : ['ONLINE EFT', 'OFFLINE EFT'];
        default:
          return [
            'ONLINE CASH',
            'OFFLINE CASH',
            'ONLINE CARD',
            'OFFLINE CARD',
            'OFFLINE EFT',
          ];
      }
    };

    const acceptedMethods = determineAcceptedMethods(method, type);

    transactions.forEach((transaction) => {
      if (transaction.method && acceptedMethods.includes(transaction.method)) {
        total += transaction.amount ?? 0;
      }
    });

    return total;
  }

  /**
   * Calculates the total transactions for a specific user by payment method and type.
   * @param userEmail - The display name of the user.
   * @param method - The payment method to filter by (CASH, CARD, or none for all).
   * @param type - The type to filter by (ONLINE, OFFLINE, or none for both).
   * @returns The total value of transactions for the user filtered by the method and type.
   */
  getUserDailyTotalByMethod(
    userEmail: string,
    method?: string,
    type?: string
  ): number {
    let total = 0;
    const acceptedStatuses = ['PAID', 'UNALLOCATED'];

    const determineAcceptedMethods = (
      method?: string,
      type?: string
    ): string[] => {
      switch (method) {
        case 'CASH':
          return type ? [`${type} CASH`] : ['ONLINE CASH', 'OFFLINE CASH'];
        case 'CARD':
          return type ? [`${type} CARD`] : ['ONLINE CARD', 'OFFLINE CARD'];
        case 'ALL':
          return type
            ? [`${type} CASH`, `${type} CARD`]
            : ['ONLINE CASH', 'OFFLINE CASH', 'ONLINE CARD', 'OFFLINE CARD'];
        default:
          return ['ONLINE CASH', 'OFFLINE CASH', 'ONLINE CARD', 'OFFLINE CARD'];
      }
    };

    const acceptedMethods = determineAcceptedMethods(method, type);

    for (let transaction of this.dailyTransactions) {
      if (
        transaction.createdBy?.email === userEmail &&
        transaction.status &&
        acceptedStatuses.includes(transaction.status) &&
        transaction.method &&
        acceptedMethods.includes(transaction.method)
      ) {
        total += transaction.amount || 0;
      }
    }

    return total;
  }

  /**
   * Determines the period up to which a product's premium has been paid.
   * @param productsPaymentStatus - The payment status of the product.
   * @returns The period up to which the product has been paid, or a message if the first premium is awaited.
   */
  getPaidUpToPeriod(
    productsPaymentStatus: ProductPaymentStatus,
    policyData?: Policy
  ) {
    const policy = policyData ?? this.policyService.selectedPolicy;

    if (
      productsPaymentStatus?.id &&
      !Number.isNaN(productsPaymentStatus?.lastPeriodPaid?.seconds)
    ) {
      if (productsPaymentStatus.id === policy?.planId) {
        const inceptionDateTimestamp = policy?.inceptionDate;

        const inceptionDate = this.dateTimeService.timestampToDate(
          inceptionDateTimestamp
        );
        inceptionDate?.setHours(0, 0, 0, 0);

        const lastPeriodPaid = this.dateTimeService.timestampToDate(
          productsPaymentStatus.lastPeriodPaid
        );
        lastPeriodPaid?.setHours(0, 0, 0, 0);

        if (inceptionDate && lastPeriodPaid && inceptionDate > lastPeriodPaid) {
          return 'AWAITING FIRST PREMIUM';
        } else {
          return this.formatPeriod(productsPaymentStatus.lastPeriodPaid, true);
        }
      } else {
        const policyAddOn = policy?.addOns?.find(
          (policyAddOn) => policyAddOn.addOnId === productsPaymentStatus.id
        );

        if (!policyAddOn) return '';

        const lastPeriodPaid = this.dateTimeService.timestampToDate(
          productsPaymentStatus.lastPeriodPaid
        );
        lastPeriodPaid?.setHours(0, 0, 0, 0);

        const firstPaymentDate = this.getProductFirstPaymentDate(
          policyAddOn,
          true,
          policy
        );

        if (
          firstPaymentDate &&
          lastPeriodPaid &&
          firstPaymentDate > lastPeriodPaid
        ) {
          return 'AWAITING FIRST PREMIUM';
        } else {
          return this.formatPeriod(productsPaymentStatus.lastPeriodPaid, true);
        }
      }
    }

    return '';
  }

  /**
   * Retrieves the month up to which a product (either plan or add-on) has been paid.
   * @param policy - The policy in question.
   * @param productId - The ID of the product.
   * @param nextMonth - If you're requesting the next payment month (default: false)
   * @returns A timestamp representing the last month the product was paid up to or the next payment month depending on if nextMonth is true.
   */
  public getProductPaymentStatusMonth(
    policy: Policy,
    product?: any,
    nextMonth?: boolean
  ): Timestamp {
    const productPaymentStatus = policy?.productsPaymentStatus?.find(
      (pps) =>
        pps.id === product.addOnId ||
        (product.memberTypeId && pps.id === policy.planId)
    );

    if (productPaymentStatus?.lastPeriodPaid) {
      const paidUpToTimestamp = productPaymentStatus.lastPeriodPaid;
      const paidUpToDate = paidUpToTimestamp.toDate();
      const nextPaymentDate = this.dateTimeService.dateToTimestamp(
        new Date(
          paidUpToDate.getFullYear(),
          paidUpToDate.getMonth() + 1,
          paidUpToDate.getDate()
        )
      );
      return nextPaymentDate && nextMonth ? nextPaymentDate : paidUpToTimestamp;
    } else {
      const inceptionDate = this.dateTimeService.verifyTimestamp(
        product?.inceptionDate
      );
      if (inceptionDate) {
        const inceptionMonthBefore = new Date(
          inceptionDate.toDate().getFullYear(),
          inceptionDate.toDate().getMonth() - (nextMonth ? 0 : 1),
          inceptionDate.toDate().getDate()
        );
        return Timestamp.fromDate(inceptionMonthBefore);
      } else {
        return Timestamp.fromDate(new Date());
      }
    }
  }

  getProductPaymentStatus(productId: string) {
    const productPaymentStatus =
      this.policyService.selectedPolicy?.productsPaymentStatus?.find(
        (pps) => pps.id === productId || pps.id === productId
      );
    return productPaymentStatus;
  }

  /**
   * Determines the premium values for upcoming months.
   * @param periods - Number of future periods to calculate premiums for.
   * @returns An array of premium details for each period.
   */
  getPolicyPremiumPeriods(
    periods: number,
    policyData?: Policy
  ): {
    premium: number;
    month: string;
    periodPaid: Timestamp | Date;
    premiumFormatted: string;
    payments: Payment[];
  }[] {
    let months = [];
    let payments: Payment[] = [];
    const policy = policyData ?? this.policyService.selectedPolicy;

    let earliestLastPaidMonth: Date | undefined;

    let planPaid = false;
    let addOnsPaid = policy?.addOns ? policy?.addOns.map(() => false) : [];

    policy?.productsPaymentStatus?.forEach((product) => {
      if (product.id === policy?.planId) {
        planPaid = true;
      }

      policy?.addOns?.forEach((addOn, index) => {
        if (product.id === addOn.addOnId) {
          addOnsPaid![index] = true;
        }
      });
    });

    if (!planPaid) {
      if (policy) {
        const inceptionDate =
          this.dateTimeService.timestampToDate(policy.inceptionDate) ??
          new Date();
        policy?.productsPaymentStatus?.push({
          id: policy.planId,
          lastPeriodPaid:
            this.dateTimeService.dateToTimestamp(
              this.getNextPaymentDate(inceptionDate, -1, undefined, policy)
            ) ?? policy.inceptionDate,
        });
      }
    }

    addOnsPaid.forEach((paid, index) => {
      if (!paid) {
        if (
          policy?.addOns![index].addOnId &&
          (policy?.addOns![index].status === 'ACTIVE' ||
            policy?.addOns![index].status === 'REQUESTED')
        ) {
          const firstPaymentDate = this.getProductFirstPaymentDate(
            policy?.addOns![index],
            true,
            policy
          );

          policy?.productsPaymentStatus?.push({
            id: policy?.addOns![index].addOnId,
            lastPeriodPaid:
              this.dateTimeService.dateToTimestamp(
                this.getNextPaymentDate(firstPaymentDate, -1, undefined, policy)
              ) ?? policy.inceptionDate,
          });
        }
      }
    });

    if (!earliestLastPaidMonth) {
      earliestLastPaidMonth = policy?.productsPaymentStatus?.reduce(
        (min, pps) =>
          pps?.lastPeriodPaid?.toDate() && pps.lastPeriodPaid?.toDate() < min
            ? pps.lastPeriodPaid.toDate()
            : min,
        new Date(8640000000000000)
      );
    }

    let totalPremium = 0;

    if (earliestLastPaidMonth) {
      for (let i = 1; i < periods; i++) {
        let payment: Payment = {};
        payment.items = [];
        payment.periodPaid = Timestamp.now();

        const dueDate = policy
          ? this.getNextPaymentDate(
              earliestLastPaidMonth,
              i,
              policy.intendedPaymentDay,
              policy
            )
          : this.getNextPaymentDate(
              earliestLastPaidMonth,
              i,
              undefined,
              policy
            );

        const dueDateTimestamp = this.dateTimeService.dateToTimestamp(dueDate);

        if (dueDateTimestamp) payment.periodPaid = dueDateTimestamp;

        let periodPremium = 0;

        policy?.productsPaymentStatus?.forEach((pps) => {
          if (
            pps.id &&
            pps?.lastPeriodPaid?.toDate() &&
            pps?.lastPeriodPaid?.toDate() < dueDate
          ) {
            if (pps.id === policy?.planId) {
              periodPremium += this.calculateCurrentPlanPremium(
                dueDateTimestamp ?? Timestamp.now(),
                policyData
              );

              if (periodPremium > 0)
                payment.items?.push({
                  id: pps.id,
                  product: this.planService.getPlanNameById(pps.id).text,
                  amount: periodPremium,
                });
            } else {
              const addOnPremium = this.calculateCurrentAddOnPremium(
                pps.id,
                dueDateTimestamp ?? Timestamp.now(),
                policyData
              );
              periodPremium += addOnPremium;

              if (addOnPremium > 0)
                payment.items?.push({
                  id: pps.id,
                  product: this.addOnService.getAddOnNameById(pps.id).text,
                  amount: addOnPremium,
                });
            }
          }
        });

        totalPremium += periodPremium;

        if (totalPremium > 0) {
          payments.push(payment);

          months.push({
            premium: totalPremium,
            month: this.formatPeriod(dueDate, true),
            periodPaid:
              this.dateTimeService.dateToTimestamp(dueDate) ?? dueDate,
            premiumFormatted: this.currencyToString(totalPremium),
            payments,
          });
        }
      }
    }

    return months;
  }

  /**
   * Creates summaries of transaction payments.
   * @param payments - Array of payments to summarize.
   * @param algoliaSearch - Flag to indicate if this method is being used with Algolia search.
   * @returns An array of summarized payment details.
   */
  getTransactionPaymentSummaries(
    payments: Payment[],
    algoliaSearch: boolean = false
  ): Array<{ periodPaid: Date; product: string; totalAmount: number }> {
    const paymentArray: Array<{
      periodPaid: Date;
      product: string;
      totalAmount: number;
    }> = [];

    if (payments && Array.isArray(payments)) {
      for (const payment of payments) {
        let productStr = '';
        let totalAmount = 0;

        if (payment.items) {
          const productArray: string[] = payment.items.map(
            (item: ProductItem) => item.product || ''
          );
          productStr = productArray.join(' + ');

          totalAmount = payment.items.reduce(
            (sum, item) => sum + (item.amount || 0),
            0
          );
        }

        if (payment.periodPaid) {
          paymentArray.push({
            periodPaid: algoliaSearch
              ? this.dateTimeService.algoliaTimestampToDate(payment.periodPaid)
              : payment.periodPaid.toDate(),
            product: productStr,
            totalAmount: totalAmount,
          });
        }
      }
    }

    return paymentArray;
  }

  /**
   * Summarizes the transaction details for a set of payments.
   * @param payments - Array of payments to summarize.
   * @param algoliaSearch - Flag to indicate if this method is being used with Algolia search.
   * @returns The total amount and combined products for all transactions.
   */
  getTransactionSummary(
    payments: Payment[],
    algoliaSearch: boolean = false
  ): {
    totalAmount: number;
    allProducts: string;
  } {
    const paymentArray = this.getTransactionPaymentSummaries(
      payments,
      algoliaSearch
    );
    let totalAmount = 0;
    let allProducts = '';

    if (payments && Array.isArray(payments))
      for (const payment of paymentArray) {
        totalAmount += payment.totalAmount;

        if (payment.product.length > allProducts.length) {
          allProducts = payment.product;
        }
      }

    if (allProducts.length === 0) allProducts = 'NO PRODUCTS PAID';

    return { totalAmount, allProducts };
  }

  /**
   * Retrieves the periods (timestamps) for which payments were made.
   * @param payments - Array of payments to extract periods from.
   * @returns An array of timestamps representing the periods of the payments.
   */
  getTransactionPeriodsPaid(payments: Payment[]): Array<Timestamp> {
    const periodsPaid: Array<Timestamp> = [];

    if (payments && Array.isArray(payments)) {
      for (const payment of payments) {
        if (payment.periodPaid) {
          periodsPaid.push(payment.periodPaid);
        }
      }
    }

    return periodsPaid;
  }

  /**
   * Retrieves the product based on its ID.
   * @param id - The ID of the product.
   * @returns The product, or undefined if not found.
   */
  getProduct(id?: string) {
    let product = undefined;

    if (id) {
      if (id === this.policyService.selectedPolicy?.planId) {
        product = this.planService.getPlanById(id);
      } else {
        product = this.addOnService.getAddOnById(id);
      }
    }
    return product;
  }

  /**
   * Computes the next policy premium.
   * @returns The premium amount and its due date.
   */
  getNextPolicyPremium(policyData?: Policy) {
    let premium = 0;
    let periods: any[] = [];
    let i = 0;

    while (premium <= 0 && i < 12) {
      i++;
      periods = this.getPolicyPremiumPeriods(i, policyData) ?? [];
      premium = periods[0]?.premium ?? 0;
    }
    let date =
      this.dateTimeService.formatDate(
        this.getPolicyPremiumPeriods(i, policyData)[0]?.periodPaid
      ) ?? '';

    date = date.length > 0 ? 'DUE FOR ' + date : '';
    return {
      amount: premium,
      amountFormatted: this.currencyToString(premium),
      date,
      periods,
    };
  }

  /**
   * Computes the next member premium.
   * @param member - The member of the policy.
   * @returns The premium amount and amountFormatted.
   */
  getNextMemberPremiumById(member?: Member, policy?: Policy) {
    let premium = 0;
    const policyData = policy ?? this.policyService.selectedPolicy;

    if (policyData?.planId && member) {
      const productPaymentStatusMonth = this.getProductPaymentStatusMonth(
        policyData,
        member,
        true
      );
      premium = this.getCurrentMemberPremiumById(
        policyData,
        member,
        productPaymentStatusMonth
      );
    }

    return {
      amount: premium,
      amountFormatted: this.currencyToString(premium),
    };
  }

  /**
   * Computes the next add-on premium.
   * @param addOn - The addOn of the policy.
   * @returns The premium amount and amountFormatted.
   */
  getNextAddOnPremiumById(addOn?: PolicyAddOn) {
    let premium = 0;
    const policy = this.policyService.selectedPolicy;

    if (policy?.planId && addOn?.addOnId) {
      const productPaymentStatus = this.getProductPaymentStatusMonth(
        policy,
        addOn,
        true
      );

      if (productPaymentStatus) {
        premium = this.getCurrentAddOnPremiumById(
          policy,
          addOn,
          productPaymentStatus
        );
      }
    }

    return {
      amount: premium,
      amountFormatted: this.currencyToString(premium),
    };
  }

  /**
   * Summarizes the total of a set of transactions.
   * @param payments - Array of payments to summarize.
   * @param algoliaSearch - Flag to indicate if this method is being used with Algolia search.
   * @returns A formatted string representing the total of the transactions.
   */
  getTransactionTotal(payments: Payment[], algoliaSearch: boolean = false) {
    const total = this.getTransactionSummary(
      payments,
      algoliaSearch
    ).totalAmount;

    return total.toLocaleString('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2,
    });
  }

  getNextOfflineReceiptNumber(): string {
    const prefix = this.getRandomChars();
    const timestamp = this.getTimeStampHex();
    return `${prefix}${timestamp}`;
  }

  /**
   * Generates a unique, sequential receipt number based on the current year and month, using a Firestore document for tracking.
   * The format of the receipt number is 'YYMM0<counter>', where 'YY' is the last two digits of the year, 'MM' is the month (padded to two digits),
   * and the remaining part is a sequential counter (padded to six digits).
   * The format of the counter is 'YYMM<counter>0', where 'YY' is the last two digits of the year, 'MM' is the month (padded to two digits),
   * and the remaining part is a sequential counter (padded to six digits).
   * The counter resets each month and increments with each new receipt.
   * For instance, the first receipt number in February 2024 would be '24020000010'.
   * This function uses a Firestore transaction to retrieve and update the current counter while ensuring its uniqueness and consistency.
   *
   * @returns {Promise<{
   *   receiptNumber: string;
   *   counter: string;
   * }>} - A promise that resolves to an object containing the generated receipt number and counter.
   */
  async getNextReceiptNumberAndCounter(): Promise<{
    receiptNumber: string;
    counter: string;
  }> {
    const timeZone = 'Africa/Johannesburg';
    const today = new Date();

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZone,
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const formattedDate = formatter.format(today);

    const [datePart] = formattedDate.split(',');
    const [month, , year] = datePart.split('/');

    const docRef: DocumentReference = doc(
      this.dbModular,
      'metaData/receiptCounter'
    );

    return runTransaction(this.dbModular, async (transaction) => {
      const docSnapshot = await transaction.get(docRef);
      const docData = docSnapshot.data() || {};
      const currentYearData = docData[year] || {};
      const currentNumber = (currentYearData[month] || 0) + 1;

      transaction.update(docRef, {
        [year]: { ...currentYearData, [month]: currentNumber },
      });

      const receiptNumber = `${year}${month}0${currentNumber
        .toString()
        .padStart(6, '0')}`;
      const counter = `${year}${month}${currentNumber
        .toString()
        .padStart(8, '0')}0`;

      return { receiptNumber, counter };
    });
  }

  private getRandomChars(): string {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 2; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars[randomIndex];
    }
    return result;
  }

  private getTimeStampHex(): string {
    const timestampBigInt = BigInt(this.getCurrentTimestamp());
    return timestampBigInt.toString(32).toUpperCase().padStart(9, '0');
  }

  private getCurrentTimestamp(): string {
    const date = new Date();
    const year = date.getFullYear().toString().substring(2); // Last 2 digits of year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month (0-11 so +1)
    const day = date.getDate().toString().padStart(2, '0'); // Day of month
    const hours = date.getHours().toString().padStart(2, '0'); // Hours
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutes
    const seconds = date.getSeconds().toString().padStart(2, '0'); // Seconds
    const milliseconds = Math.round(date.getMilliseconds())
      .toString()
      .padStart(3, '0'); //Milliseconds

    return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
  }

  /**
   * Retrieves the status and associated CSS class for a transaction.
   * @param status - The status of the transaction.
   * @returns An object containing the status text and its associated CSS class.
   */
  getTransactionStatusTextAndClass(status: string) {
    let text =
      status.includes('TRANSFERRED') || status === 'UNALLOCATED FUNDS AMENDMENT'
        ? status.includes('OUT')
          ? TransactionStatus.REVERSED
          : TransactionStatus.UNALLOCATED
        : status ?? '-';
    if (text === TransactionStatus.PAID) {
      return { text, class: ['activeColor'] };
    } else if (text === TransactionStatus.ALLOCATED) {
      return { text: TransactionStatus.PAID, class: ['waitingColor'] };
    } else if (
      text === TransactionStatus.UNALLOCATED ||
      text.includes('TRANSFERRED')
    ) {
      return { text, class: ['waitingColor'] };
    } else if (
      text === TransactionStatus.REVERSED ||
      text === TransactionStatus.CANCELLED ||
      text === TransactionStatus.UNPAID
    ) {
      return { text, class: ['warningColor'] };
    } else if (text === TransactionStatus.UNIDENTIFIED) {
      return { text, class: ['requestedColor'] };
    } else if (text === TransactionStatus.IDENTIFIED) {
      return { text, class: ['requestedColor'] };
    }

    return { text, class: [''] };
  }

  /**
   * `updateTransactions` - Handles the local processing of new transactions.
   *
   * - Validates policy details and updates the transaction status based on the premium amount.
   * - Manages and logs the creation of new transactions in the database.
   * - Updates product payment statuses or unallocated balances of the associated policy.
   * - Notifies the user about the transaction status using the SnackBarService.
   *
   * @param {any} formValues - Input values from the transaction form.
   * @param {any[]} periods - Array of payment periods to validate transaction amounts against.
   *
   * @throws
   * - Error when the linked policy details are not found.
   * - Error when the linked policy is inactive or lapsed.
   *
   * @returns {void}
   *
   * @dependencies
   * - Relies on services: mainService, policyService, userService, transactionLogService, db, snackBarService.
   */
  async updateTransactions(formValues: any, periods: any[]) {
    this.loading = true;
    const isAlreadyLoading = this.mainService.isLoading;
    this.mainService.setLoadingInfo('PROCESSING TRANSACTION...');
    if (!isAlreadyLoading) this.mainService.setLoading(true);
    try {
      const policyId = this.policyService.selectedPolicy?.id;
      const policyNumber = this.policyService.selectedPolicy?.policyNumber;
      const policyStatus = this.policyService.selectedPolicy?.status;
      let allocated;
      let periodIndex: number;
      let payments: any[] = [];
      let productsPaymentStatus: ProductPaymentStatus[] | undefined =
        this.policyService.selectedPolicy?.productsPaymentStatus ?? [];

      if (formValues.status !== TransactionStatus.CANCELLED) {
        allocated = this.policyService.selectedPolicy
          ? periods.some((period) => period.premium === formValues.amount)
          : false;

        if (policyStatus) {
          if (
            policyStatus === PolicyStatus.INACTIVE ||
            policyStatus === PolicyStatus.LAPSED
          ) {
            throw new Error(
              'POLICY LINKED TO THE TRANSACTION IS INACTIVE/LAPSED'
            );
          }
        }

        const status = allocated
          ? TransactionStatus.PAID
          : this.policyService.selectedPolicy
          ? TransactionStatus.UNALLOCATED
          : TransactionStatus.UNIDENTIFIED;

        if (status === TransactionStatus.PAID) {
          periodIndex = periods.findIndex((period: any) => {
            return period.premium === formValues.amount;
          });

          periods[periodIndex].payments[periodIndex].items.forEach(
            (item: any) => {
              if (productsPaymentStatus) {
                productsPaymentStatus.forEach((status) => {
                  if (status.id === item.id) {
                    status.lastPeriodPaid =
                      periods[periodIndex].payments[periodIndex].periodPaid;
                  }
                });
              }
            }
          );

          payments = periods[periodIndex].payments.slice(0, periodIndex + 1);
        }
      }
      const createdBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const createdOn = Timestamp.now();

      if (!formValues.receiptNumber) {
        const result = await this.getNextReceiptNumberAndCounter();
        formValues.receiptNumber = result.receiptNumber;
        formValues.counter = result.counter;
      }

      let modifiedFormValues: any = {
        source: formValues.source ?? PaymentSource.SYSTEM,
        method: formValues.method,
        payments,
        amount: formValues.amount,
        transactionDate: formValues.transactionDate ?? createdOn,
        receiptNumber: formValues.receiptNumber,
        receiptReferenceNumber:
          formValues.receiptReferenceNumber &&
          formValues.receiptReferenceNumber.length > 0
            ? formValues.receiptReferenceNumber
            : '-',
        status: formValues.status ?? status,
        statusInfo: '',
        isReversed: false,
        createdBy,
        createdOn,
      };

      if (formValues.counter) {
        modifiedFormValues.counter = formValues.counter;
      }

      if (this.policyService.selectedPolicy) {
        modifiedFormValues = {
          ...modifiedFormValues,
          policyId,
          policyNumber,
        };
      }

      const docRef = await addDoc(collection(this.dbModular, 'transaction'), {
        ...modifiedFormValues,
      });

      if (
        policyId &&
        this.policyService.selectedPolicy &&
        modifiedFormValues.amount !== undefined
      ) {
        if (
          modifiedFormValues.status === TransactionStatus.PAID ||
          modifiedFormValues.status === TransactionStatus.ALLOCATED
        ) {
          await this.policyService.updateLatestProductPaymentStatus(
            this.policyService.selectedPolicy,
            productsPaymentStatus
          );
        } else if (
          modifiedFormValues.status === TransactionStatus.UNALLOCATED
        ) {
          await this.policyService.updatePolicyUnallocatedBalance(
            this.policyService.selectedPolicy,
            modifiedFormValues.amount
          );
          this.policyService.selectedPolicyUnallocatedBalanceUpdated.emit();
        }
        await this.transactionLogService.createTransactionLog(
          modifiedFormValues
        );

        let primaryMember = this.policyService.getPolicyPrimaryMember(
          this.policyService.selectedPolicy
        );
        let message = '';
        if (modifiedFormValues.status === TransactionStatus.UNIDENTIFIED) {
          message = `Your payment of ${this.currencyToString(
            formValues.amount
          )} was processed for ${
            this.policyService.selectedPolicy.policyNumber
          } with an incorrect premium amount.\nReceipt: ${
            formValues.receiptNumber
          }.\nContact 0153076503 Wisani FSP51133.`;
        } else {
          message = `Your payment of ${this.currencyToString(
            formValues.amount
          )} for ${this.displayPeriodPaid(
            this.getTransactionPeriodsPaid(payments || [])
          )} was successful for ${
            this.policyService.selectedPolicy.policyNumber
          }.\nReceipt: ${
            formValues.receiptNumber
          }.\nContact 0153076503 Wisani FSP51133.`;
        }

        // Removing all tabs, unnecessary spaces and new lines
        message = message
          .replace(/[\t ]+/g, ' ')
          .replace(/ ?\n ?/g, '\n')
          .trim();

        if (primaryMember?.cellNumber)
          this.messageService.sendSMS(
            primaryMember.cellNumber,
            message,
            policyId,
            formValues.receiptNumber
          );
      }

      // Show a success message using the SnackBarService
      if (!isAlreadyLoading)
        this.snackBarService.openBlueSnackBar(
          (modifiedFormValues.status !== TransactionStatus.UNIDENTIFIED
            ? 'POLICY'
            : TransactionStatus.UNIDENTIFIED) +
            ' TRANSACTION CAPTURED SUCCESSFULLY!'
        );

      this.selectedTransaction = docRef;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      // Show an error message using the SnackBarService
      this.snackBarService.openRedSnackBar(
        'POLICY TRANSACTION CAPTURE FAILED!'
      );
      this.updateSuccess = false;
      if (!isAlreadyLoading) this.mainService.setLoading(false);
      throw err;
    }
    this.updateSuccess = true;
    this.loading = false;

    if (!isAlreadyLoading) this.mainService.setLoading(false);
  }

  /**
   * `reverseOrMoveTransaction` - Reverses a transaction or transfers it to another policy.
   *
   * - For a reversal, updates the status to "REVERSED" and adjusts the last payment period by a month.
   * - For a transfer, updates the original transaction status and creates a new transaction for the target policy.
   *
   * @param {Transaction} transaction - The transaction to be reversed or moved.
   * @param {string} reason - The reason for reversing or moving the transaction.
   * @param {boolean} transfer - Flag to determine if action is a transfer or reversal (default: false for reversal).
   * @param {string} [newPolicyNumber] - The policy number of the new policy to transfer to (if transfer is true).
   *
   * @throws
   * Error when the linked policy details are not found.
   *
   * @returns {void}
   *
   * @dependencies
   * Relies on services: mainService, policyService, transactionLogService, db, snackBarService.
   */
  async reverseOrMoveTransaction(
    transaction: Transaction,
    reason: string,
    transfer: boolean = false,
    newPolicyId?: string
  ) {
    this.loading = true;
    this.mainService.setLoading(true);

    try {
      const originalTransaction = { ...transaction } as Transaction;

      if (!originalTransaction.id) {
        throw new Error('TRANSACTION ID NOT FOUND');
      }

      const createdBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const createdOn = Timestamp.now();
      let newPolicy;
      let newReceiptNumber;
      let newCounter;

      if (
        this.policyService.selectedPolicy &&
        originalTransaction.policyId &&
        (originalTransaction.status === TransactionStatus.PAID ||
          originalTransaction.status === TransactionStatus.UNALLOCATED ||
          originalTransaction.status === TransactionStatus.TRANSFERRED_IN)
      ) {
        if (
          !this.policyService.selectedPolicy.policyNumber ||
          !this.policyService.selectedPolicy.productsPaymentStatus ||
          !this.policyService.selectedPolicy.id
        ) {
          throw new Error('POLICY LINKED TO THE TRANSACTION NOT FOUND');
        }

        const alreadyReversed = await this.checkIfTransactionAlreadyReversed(
          originalTransaction.receiptNumber
        );
        if (alreadyReversed) {
          this.snackBarService.openRedSnackBar(
            'TRANSACTION HAS ALREADY BEEN REVERSED!'
          );
          await this.loadSelectedPolicyTransactions();
          return;
        }

        originalTransaction.payments?.forEach((payment) => {
          payment.items?.forEach((item) => {
            this.policyService.selectedPolicy?.productsPaymentStatus?.forEach(
              (status) => {
                if (status.id === item.id) {
                  let date = status.lastPeriodPaid?.toDate();
                  if (date instanceof Date) {
                    date.setMonth(date.getMonth() - 1);
                    status.lastPeriodPaid = Timestamp.fromDate(date);
                  }
                }
              }
            );
          });
        });

        if (transfer && newPolicyId) {
          newPolicy = await this.policyService.getPolicyById(newPolicyId);

          const result = await this.getNextReceiptNumberAndCounter();
          newReceiptNumber = result.receiptNumber;
          newCounter = result.counter;
        }

        const transactionDocRef = doc(
          this.dbModular,
          'transaction',
          originalTransaction.id
        );
        await updateDoc(transactionDocRef, {
          ...originalTransaction,
          isReversed: true,
        });

        if (originalTransaction.receiptNumber && originalTransaction.counter) {
          if (originalTransaction.counter.endsWith('0')) {
            originalTransaction.counter =
              originalTransaction.counter.slice(0, -1) + '1';
          }

          if (originalTransaction.receiptNumber.charAt(4) === '0') {
            originalTransaction.receiptNumber =
              originalTransaction.receiptNumber.substring(0, 4) +
              '1' +
              originalTransaction.receiptNumber.substring(5);
          }
        }

        const transactionsCollectionRef = collection(
          this.dbModular,
          'transaction'
        );
        await addDoc(transactionsCollectionRef, {
          ...originalTransaction,
          status: transfer
            ? TransactionStatus.TRANSFERRED_OUT
            : TransactionStatus.REVERSED,
          statusInfo: transfer
            ? `TRANSFERRED TO ${newPolicy?.policyNumber}`
            : '',
          createdBy,
          createdOn,
          amount: -(originalTransaction.amount ?? 0),
        });

        if (transfer) {
          await addDoc(transactionsCollectionRef, {
            ...originalTransaction,
            receiptNumber: newReceiptNumber,
            counter: newCounter,
            status: TransactionStatus.TRANSFERRED_IN,
            statusInfo: `TRANSFERRED FROM ${this.policyService.selectedPolicy.policyNumber}`,
            policyId: newPolicy?.id,
            policyNumber: newPolicy?.policyNumber,
            payments: [],
            createdBy,
            createdOn,
          });
        }

        if (originalTransaction.status === TransactionStatus.PAID) {
          await this.policyService.updateLatestProductPaymentStatus(
            this.policyService.selectedPolicy,
            this.policyService.selectedPolicy.productsPaymentStatus
          );
        } else if (
          (originalTransaction.status === TransactionStatus.UNALLOCATED ||
            originalTransaction.status === TransactionStatus.TRANSFERRED_IN) &&
          originalTransaction.amount
        ) {
          await this.policyService.updatePolicyUnallocatedBalance(
            this.policyService.selectedPolicy,
            -originalTransaction.amount
          );
        }
      } else if (
        originalTransaction.status === TransactionStatus.UNIDENTIFIED
      ) {
        const transactionDocRef = doc(
          this.dbModular,
          'transaction',
          originalTransaction.id
        );
        await updateDoc(transactionDocRef, {
          ...originalTransaction,
          status: TransactionStatus.IDENTIFIED,
          isReversed: true,
        });

        if (originalTransaction.receiptNumber && originalTransaction.counter) {
          if (originalTransaction.counter.endsWith('0')) {
            originalTransaction.counter =
              originalTransaction.counter.slice(0, -1) + '1';
          }

          if (originalTransaction.receiptNumber.charAt(4) === '0') {
            originalTransaction.receiptNumber =
              originalTransaction.receiptNumber.substring(0, 4) +
              '1' +
              originalTransaction.receiptNumber.substring(5);
          }
        }

        const transactionsCollectionRef = collection(
          this.dbModular,
          'transaction'
        );

        await addDoc(transactionsCollectionRef, {
          ...originalTransaction,
          status: TransactionStatus.REVERSED,
          createdBy,
          createdOn,
          amount: -(originalTransaction.amount ?? 0),
        });

        if (transfer && newPolicyId) {
          newPolicy = await this.policyService.getPolicyById(newPolicyId);

          const result = await this.getNextReceiptNumberAndCounter();
          newReceiptNumber = result.receiptNumber;
          newCounter = result.counter;

          await addDoc(transactionsCollectionRef, {
            ...originalTransaction,
            receiptNumber: newReceiptNumber,
            counter: newCounter,
            policyId: newPolicy.id,
            policyNumber: newPolicy.policyNumber,
            status: TransactionStatus.TRANSFERRED_IN,
            createdOn,
          });
        }
      } else if (originalTransaction.status === TransactionStatus.ALLOCATED) {
        if (
          !this.policyService.selectedPolicy ||
          !this.policyService.selectedPolicy.policyNumber ||
          !this.policyService.selectedPolicy.productsPaymentStatus ||
          !this.policyService.selectedPolicy.id
        ) {
          throw new Error('POLICY LINKED TO THE TRANSACTION NOT FOUND');
        }

        if (originalTransaction.amount === undefined) {
          throw new Error('TRANSACTION AMOUNT NOT FOUND');
        }

        const transactionDocRef = doc(
          this.dbModular,
          'transaction',
          originalTransaction.id
        );
        await updateDoc(transactionDocRef, {
          ...originalTransaction,
          isReversed: true,
        });

        originalTransaction.payments?.forEach((payment) => {
          payment.items?.forEach((item) => {
            this.policyService.selectedPolicy?.productsPaymentStatus?.forEach(
              (status) => {
                if (status.id === item.id) {
                  let date = status.lastPeriodPaid?.toDate();
                  if (date instanceof Date) {
                    date.setMonth(date.getMonth() - 1);
                    status.lastPeriodPaid = Timestamp.fromDate(date);
                  }
                }
              }
            );
          });
        });

        await Promise.all([
          this.policyService.updateLatestProductPaymentStatus(
            this.policyService.selectedPolicy,
            this.policyService.selectedPolicy.productsPaymentStatus
          ),
          this.policyService.updatePolicyUnallocatedBalance(
            this.policyService.selectedPolicy,
            originalTransaction.amount
          ),
        ]);
      }

      if (this.filterService.policyTransactionFilter === 'premiums') {
        this.filterService.updatePolicyTransactionFilter('all');
      }

      if (transfer && newPolicy && originalTransaction.amount) {
        await this.policyService.updatePolicyUnallocatedBalance(
          newPolicy,
          originalTransaction.amount
        );

        this.snackBarService.openBlueSnackBar(
          'TRANSACTION TRANSFER FROM ' +
            (this.policyService.selectedPolicy?.policyNumber
              ? this.policyService.selectedPolicy.policyNumber
              : TransactionStatus.UNIDENTIFIED) +
            ' TO ' +
            newPolicy.policyNumber +
            ' SUCCESSFUL'
        );
        this.newTransactionHistoryTab(newPolicy.id);
      }

      if (
        transfer ||
        originalTransaction.status !== TransactionStatus.UNIDENTIFIED
      ) {
        await this.transactionLogService.createTransactionLog(
          {
            ...originalTransaction,
            policyId: this.policyService.selectedPolicy?.id,
            payments: transfer ? [] : originalTransaction.payments,
          },
          transfer
            ? 'TRANSFERRED FROM ' +
                this.policyService.selectedPolicy?.policyNumber
            : 'REVERSED',
          reason,
          newPolicy,
          newReceiptNumber
        );
      }
      this.transactionTransferredOrReversed.emit();
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      if (!transfer) {
        this.snackBarService.openRedSnackBar('TRANSACTION REVERSAL FAILED!');
      }
      this.mainService.setLoading(false);
    } finally {
      this.mainService.setLoading(false);
      this.loading = false;
    }
  }

  async checkIfTransactionAlreadyReversed(
    receiptNumber?: string
  ): Promise<boolean> {
    try {
      if (!receiptNumber) {
        throw new Error('RECEIPT NUMBER NOT PROVIDED');
      }

      const transactionsCollectionRef = collection(
        this.dbModular,
        'transaction'
      );
      const q = query(
        transactionsCollectionRef,
        where('receiptNumber', '==', receiptNumber),
        limit(1)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const transactionDoc = querySnapshot.docs[0];
        const transactionData = transactionDoc.data() as Transaction;
        if (transactionData.isReversed) {
          return true;
        }
      }

      return false;
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
      }
      this.snackBarService.openRedSnackBar(
        'CHECKING TRANSACTION REVERSAL STATUS FAILED!'
      );
      return false;
    }
  }

  /**
   * Amends the unallocated funds linked with a policy.
   * Updates the policy balance based on the amount provided, logs the transaction for unallocated funds amendment,
   * and optionally transfers funds to a new policy.
   *
   * @param {Transaction} transaction - Contains details like receipt numbers and amount.
   * @param {boolean} addFunds - If funds are being added or removed.
   * @param {string} [reason=''] - Reason for the amendment.
   * @param {string} [newPolicyNumber] - Optional policy number to which funds should be transferred.
   */
  async amendUnallocatedFunds(
    transaction: Transaction,
    addFunds: boolean,
    reason: string = '',
    newPolicyId?: string
  ) {
    const selectedPolicy = this.policyService.selectedPolicy;

    if (
      !selectedPolicy?.id ||
      !transaction.amount ||
      (!addFunds && !this.isValidUnallocatedFunds(transaction.amount))
    ) {
      this.snackBarService.openRedSnackBar(
        'INVALID UNALLOCATED FUNDS AMENDMENT WAS ATTEMPTED!'
      );
      return;
    }

    try {
      this.mainService.setLoading(true);
      await this.updatePolicyBalance(
        selectedPolicy,
        transaction.amount,
        addFunds
      );

      const result = await this.getNextReceiptNumberAndCounter();

      const transactionDetails = {
        ...transaction,
        receiptNumber: result.receiptNumber,
        counter: result.counter,
        receiptReferenceNumber: transaction.receiptReferenceNumber ?? '-',
        policyId: selectedPolicy.id,
        policyNumber: selectedPolicy.policyNumber,
        payments: [],
        amount: transaction.amount,
        status: 'UNALLOCATED FUNDS AMENDMENT',
        source: PaymentSource.SYSTEM,
      };

      if (newPolicyId) {
        await this.transferToNewPolicy(
          transactionDetails,
          reason,
          newPolicyId,
          selectedPolicy
        );
      } else {
        await this.transactionLogService.createTransactionLog(
          transactionDetails,
          addFunds ? '' : 'REVERSED',
          reason
        );
      }

      this.mainService.setLoading(false);
      this.policyService.selectedPolicyUnallocatedBalanceUpdated.emit();
    } catch (error) {
      this.mainService.setLoading(false);
      this.snackBarService.openRedSnackBar(
        'AN ERROR OCCURRED WHILE AMENDING THE UNALLOCATED FUNDS.'
      );
    }
  }

  /**
   * Updates the unallocated balance of a selected policy.
   *
   * @param {any} selectedPolicy - The policy to update.
   * @param {number} amount - Amount to adjust the policy balance.
   * @param {boolean} addFunds - If the amount should be added or subtracted.
   * @returns {Promise<void>}
   */
  private async updatePolicyBalance(
    selectedPolicy: any,
    amount: number,
    addFunds: boolean
  ) {
    const adjustmentAmount = addFunds ? amount : -amount;
    await this.policyService.updatePolicyUnallocatedBalance(
      selectedPolicy,
      adjustmentAmount
    );
  }

  /**
   * Transfers the amount of a transaction to a new policy, logs the transfer,
   * updates the balance of the new policy, and provides a notification to the user.
   *
   * @param {Transaction} transaction - Transaction data to transfer.
   * @param {string} reason - Reason for the transfer.
   * @param {string} newPolicyNumber - Policy number to transfer funds to.
   * @param {any} transactionDetails - Details of the transaction.
   * @param {any} selectedPolicy - Original policy for the transfer.
   * @returns {Promise<void>}
   */
  private async transferToNewPolicy(
    transaction: Transaction,
    reason: string,
    newPolicyId: string,
    selectedPolicy: any
  ) {
    const newPolicy = await this.policyService.getPolicyById(newPolicyId);

    await this.createNewTransaction({
      ...transaction,
      status: TransactionStatus.TRANSFERRED_IN,
      statusInfo: `TRANSFERRED FROM ${selectedPolicy.policyNumber}`,
      policyId: newPolicy.id,
      policyNumber: newPolicy.policyNumber,
    });

    await this.policyService.updatePolicyUnallocatedBalance(
      newPolicy,
      transaction.amount as number
    );

    await this.transactionLogService.createTransactionLog(
      transaction,
      `TRANSFERRED FROM ${selectedPolicy.policyNumber}`,
      reason,
      newPolicy
    );

    this.snackBarService.openBlueSnackBar(
      `${this.currencyToString(
        transaction.amount as number
      )} WAS TRANSFERRED FROM UNALLOCATED FUNDS TO POLICY ${
        newPolicy.policyNumber
      } SUCCESSFULLY`
    );

    this.newTransactionHistoryTab(newPolicy.id);
  }

  /**
   * Adds a new transaction to the database.
   *
   * @param {Transaction} transaction - Transaction data to add.
   * @returns {Promise<void>}
   */
  private async createNewTransaction(transaction: Transaction) {
    const createdBy = {
      uid: this.userService.userData?.uid,
      displayName: this.userService.userData?.displayName,
      email: this.userService.userData?.email,
      userLocationId: this.userService.userData?.currentUserLocationId,
    };
    const createdOn = Timestamp.now();

    const transactionsCollectionRef = collection(this.dbModular, 'transaction');
    await addDoc(transactionsCollectionRef, {
      ...transaction,
      createdOn,
      createdBy,
    });
  }

  /**
   * Asynchronously overrides the last payment status of a specific product.
   * Updates the product's payment status within the selected policy and notifies the user of the results.
   *
   * @param productPaymentStatus - The new payment status for the product.
   * @param {string} reason - Reason for the change.
   */
  async overrideProductPaymentStatus(
    productPaymentStatus: ProductPaymentStatus,
    reason: string
  ) {
    try {
      if (
        this.policyService.selectedPolicy &&
        this.policyService.selectedPolicy.id &&
        this.policyService.selectedPolicy.productsPaymentStatus
      ) {
        const oldDoc = JSON.parse(
          JSON.stringify(this.policyService.selectedPolicy)
        );

        let index = -1;

        this.policyService.selectedPolicy.productsPaymentStatus.forEach(
          (status, i) => {
            if (status.id === productPaymentStatus.id) {
              this.policyService.selectedPolicy!.productsPaymentStatus![i] =
                productPaymentStatus;
              index = i;
            }
          }
        );

        await this.policyService.updateLatestProductPaymentStatus(
          this.policyService.selectedPolicy,
          this.policyService.selectedPolicy.productsPaymentStatus
        );

        this.snackBarService.openBlueSnackBar(
          (this.getProduct(productPaymentStatus.id)?.name ?? '') +
            "'S LAST PERIOD PAID UPDATED SUCCESSFULLY"
        );

        this.policyLogService.indexObject = 'policy';
        this.policyLogService.indexType = 'override';
        this.policyLogService.updateLog(
          oldDoc,
          this.policyService.selectedPolicy,
          reason
        );
      }
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar(
        'AN ERROR OCCURRED WHILE UPDATING THE LAST PERIOD PAID'
      );
    }
  }

  willChangeLapsePolicy(productPaymentStatus: ProductPaymentStatus): boolean {
    const THRESHOLD_DAYS_3 = 91;

    const policy = this.policyService.selectedPolicy;
    const currentStatus = policy?.status;
    if (
      currentStatus === PolicyStatus.INACTIVE ||
      currentStatus === PolicyStatus.IN_PROGRESS
    ) {
      return false;
    }

    if (!policy?.planId) {
      return false;
    }

    if (policy.planId !== productPaymentStatus.id) {
      return false;
    }

    let recalculatedStatus = currentStatus;
    const plan = this.planService.getPlanById(policy?.planId);

    const primaryMemberType = plan?.memberType?.find(
      (type: any) => type.primaryMember === true
    );

    if (primaryMemberType) {
      const primaryMember = policy.members?.find(
        (member: any) => member.memberTypeId === primaryMemberType.id
      );

      const planLastPeriodPaidTimestamp = productPaymentStatus.lastPeriodPaid;
      const inceptionDateTimestamp = policy.inceptionDate;

      if (
        primaryMember &&
        planLastPeriodPaidTimestamp &&
        inceptionDateTimestamp
      ) {
        const todayTimestamp = Timestamp.now();
        const todayDate = todayTimestamp.toDate();
        todayDate.setHours(0, 0, 0, 0);

        const oneMonthAndOneDayAgo = new Date(todayDate);
        oneMonthAndOneDayAgo.setMonth(oneMonthAndOneDayAgo.getMonth() - 1);
        oneMonthAndOneDayAgo.setDate(oneMonthAndOneDayAgo.getDate() - 1);
        const oneMonthAndOneDayAgoTimestamp =
          Timestamp.fromDate(oneMonthAndOneDayAgo);

        const lastPaidDate = planLastPeriodPaidTimestamp.toDate();
        lastPaidDate.setHours(0, 0, 0, 0);
        const adjustedLastPaidTimestamp = Timestamp.fromDate(lastPaidDate);

        const inceptionDate = inceptionDateTimestamp.toDate();
        inceptionDate.setHours(0, 0, 0, 0);
        const adjustedInceptionDateTimestamp =
          Timestamp.fromDate(inceptionDate);

        const daysDifference = Math.floor(
          (todayTimestamp.toMillis() - adjustedLastPaidTimestamp.toMillis()) /
            (1000 * 60 * 60 * 24)
        );

        if (
          adjustedLastPaidTimestamp.toMillis() >=
          adjustedInceptionDateTimestamp.toMillis()
        ) {
          {
            if (
              daysDifference >= THRESHOLD_DAYS_3 &&
              currentStatus !== PolicyStatus.LAPSED
            ) {
              recalculatedStatus = PolicyStatus.LAPSED;
            } else if (
              daysDifference < THRESHOLD_DAYS_3 &&
              adjustedLastPaidTimestamp.toMillis() <=
                oneMonthAndOneDayAgoTimestamp.toMillis()
            ) {
              recalculatedStatus = PolicyStatus.ARREARS;
            } else if (
              currentStatus === PolicyStatus.PENDING ||
              currentStatus === PolicyStatus.ARREARS
            ) {
              recalculatedStatus = PolicyStatus.ACTIVE;
            }
          }
        }
      }
    }

    return (
      recalculatedStatus !== currentStatus &&
      recalculatedStatus === PolicyStatus.LAPSED
    );
  }

  async retryNetcashBatch(batch: NetcashBatch) {
    const createdBy = {
      uid: this.userService.userData?.uid,
      displayName: this.userService.userData?.displayName,
      email: this.userService.userData?.email,
      userLocationId: this.userService.userData?.currentUserLocationId,
    };

    const callable = httpsCallable(this.fireFunctions, 'retryNetcashBatch');

    const callable$ = from(
      callable({
        createdBy,
        actionDay: batch.actionDay,
      })
    );

    try {
      const result = await firstValueFrom(callable$);

      const data = result.data as { status: string; message: string };

      if (data.status === 'success') {
        this.snackBarService.openBlueSnackBar(data.message);
      } else {
        this.snackBarService.openRedSnackBar(data.message);
      }
    } catch (err) {
      this.snackBarService.openRedSnackBar(
        'Error processing Netcash batch retry!'
      );
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
      }
    }
  }

  /**
   * Opens a new transaction history tab for the given policyId.
   * If no policyId is provided, the method does nothing.
   *
   * @param {string} [policyId] - The ID of the policy.
   */
  private newTransactionHistoryTab(policyId?: string) {
    if (policyId) {
      const route = ['/transaction-history', policyId];

      const url = this.router.createUrlTree(route).toString();
      window.open(url, '', 'noopener,noreferrer');
    }
  }

  /**
   * Calculates the total premium for all ACTIVE members of the selected policy.
   * If the member is INACTIVE or doesn't have a memberTypeId, the member is excluded from the calculation.
   *
   * @returns {number} The total premium for all active members.
   */
  public calculateMemberPremiums() {
    let memberPremiums = 0;
    const planId = this.policyService.selectedPolicy?.planId;

    this.policyService.selectedPolicy?.members?.forEach((member) => {
      if (
        planId &&
        member.memberTypeId &&
        member.status !== 'INACTIVE' &&
        member.status !== 'CLAIMED'
      ) {
        const memberPremium =
          this.planService.getCurrentMemberStandardPremiumById(
            planId,
            member.memberTypeId
          );

        memberPremiums += memberPremium ?? 0;
      }
    });

    return memberPremiums;
  }

  /**
   * Calculates the current premium for all ACTIVE members of the selected policy, considering waiting periods.
   * The method takes into account the given timestamp and calculates the premium based on the current month.
   *
   * @param {Timestamp} [timestamp] - The current timestamp used for calculations.
   * @returns {number} The total premium for all active members considering the waiting period.
   */
  public calculateCurrentPlanPremium(
    timestamp: Timestamp,
    policyData?: Policy
  ) {
    let planPremium = 0;
    const policy = policyData ?? this.policyService.selectedPolicy;

    if (policy && typeof policy.planId === 'string') {
      const lastPaidMonth = this.getProductPaymentStatusMonth(policy, policy);

      policy?.members?.forEach((member) => {
        if (
          member.memberTypeId &&
          member.status !== 'INACTIVE' &&
          member.status !== 'CLAIMED' &&
          member?.waitingDate &&
          timestamp
        ) {
          const timestampAsDate = timestamp?.toDate();
          timestampAsDate?.setHours(0, 0, 0, 0);

          const lastPaidMonthAsDate = lastPaidMonth.toDate();
          lastPaidMonthAsDate?.setHours(0, 0, 0, 0);

          const firstPeriodPaid = this.getProductFirstPaymentDate(
            member,
            false,
            policyData
          );

          if (
            timestampAsDate &&
            timestampAsDate > lastPaidMonthAsDate &&
            timestampAsDate >= firstPeriodPaid
          ) {
            planPremium +=
              this.getCurrentMemberPremiumById(
                policy ?? '',
                member,
                timestamp
              ) ?? 0;
          }
        }
      });
    }

    return planPremium;
  }

  getCurrentMemberPremiumById(
    policy: Policy,
    member: Member,
    timestamp?: Timestamp
  ) {
    let premium = 0;

    if (!timestamp) timestamp = Timestamp.now();

    const timestampAsDate = timestamp?.toDate();
    timestampAsDate.setHours(0, 0, 0, 0);

    const firstPeriodPaid = this.getProductFirstPaymentDate(
      member,
      false,
      policy
    );

    const memberWaitingPeriodLeft =
      this.policyService.getPolicyMemberWaitingPeriodLeft(
        {
          ...member,
          waitingDate:
            this.dateTimeService.dateToTimestamp(firstPeriodPaid) ?? timestamp,
        },
        timestamp,
        policy
      );

    if (
      memberWaitingPeriodLeft !== undefined &&
      member.memberTypeId &&
      memberWaitingPeriodLeft <= 0
    ) {
      if (
        timestampAsDate &&
        timestampAsDate >= firstPeriodPaid &&
        policy.planId
      ) {
        premium =
          this.planService.getCurrentMemberStandardPremiumById(
            policy.planId,
            member.memberTypeId,
            timestamp
          ) ?? 0;
      }
    } else if (
      memberWaitingPeriodLeft !== undefined &&
      member.memberTypeId &&
      memberWaitingPeriodLeft > 0
    ) {
      if (
        timestampAsDate &&
        timestampAsDate >= firstPeriodPaid &&
        policy.planId
      ) {
        premium =
          this.planService.getCurrentMemberWaitingPremiumById(
            policy.planId,
            member.memberTypeId,
            timestamp
          ) ?? 0;
      }
    }

    return premium;
  }

  /**
   * Calculates the premium for a specific ACTIVE add-on on the selected policy, considering waiting periods.
   * The method takes into account the given timestamp and calculates the premium based on the current month.
   *
   * @param {string} addOnId - The ID of the specific add-on.
   * @param {Timestamp} [timestamp] - The current timestamp used for calculations.
   * @returns {number} The premium for the specific active add-on considering the waiting period.
   */
  public calculateCurrentAddOnPremium(
    addOnId: string,
    timestamp: Timestamp,
    policyData?: Policy
  ) {
    let addOnPremium = 0;
    const policy = policyData ?? this.policyService.selectedPolicy;

    const addOn = policy?.addOns?.find((ao) => ao.addOnId === addOnId);

    if (
      addOn &&
      policy?.planId &&
      addOn.addOnId &&
      addOn.status !== 'INACTIVE' &&
      addOn.waitingDate &&
      timestamp
    ) {
      const lastPaidMonth = this.getProductPaymentStatusMonth(policy, addOn);

      const timestampAsDate = timestamp?.toDate();
      timestampAsDate?.setHours(0, 0, 0, 0);

      const lastPaidMonthAsDate = lastPaidMonth.toDate();
      lastPaidMonthAsDate?.setHours(0, 0, 0, 0);

      const firstPeriodPaid = this.getProductFirstPaymentDate(
        addOn,
        false,
        policy
      );

      if (
        timestampAsDate &&
        timestampAsDate > lastPaidMonthAsDate &&
        timestampAsDate >= firstPeriodPaid
      ) {
        addOnPremium += this.getCurrentAddOnPremiumById(
          policy,
          addOn,
          timestamp
        );
      }
    }

    return addOnPremium;
  }

  getCurrentAddOnPremiumById(
    policy: Policy,
    addOn: PolicyAddOn,
    timestamp?: Timestamp
  ) {
    let premium = 0;

    if (!timestamp) timestamp = Timestamp.now();

    const timestampAsDate = timestamp?.toDate();
    timestampAsDate.setHours(0, 0, 0, 0);

    const firstPeriodPaid = this.getProductFirstPaymentDate(
      addOn,
      false,
      policy
    );

    const addOnWaitingPeriodLeft =
      this.policyService.getPolicyAddOnWaitingPeriodLeft(
        {
          ...addOn,
          waitingDate:
            this.dateTimeService.dateToTimestamp(firstPeriodPaid) ?? timestamp,
        },
        timestamp,
        policy
      );

    if (
      addOnWaitingPeriodLeft !== undefined &&
      addOn.addOnId &&
      addOnWaitingPeriodLeft <= 0
    ) {
      if (timestampAsDate && timestampAsDate >= firstPeriodPaid) {
        premium =
          this.addOnService.getCurrentAddOnPlanStandardPremiumById(
            policy.planId ?? '',
            addOn.addOnId,
            timestamp
          ) ?? 0;
      }
    } else if (
      addOnWaitingPeriodLeft !== undefined &&
      addOn.addOnId &&
      addOnWaitingPeriodLeft > 0
    ) {
      if (timestampAsDate && timestampAsDate >= firstPeriodPaid) {
        premium =
          this.addOnService.getCurrentAddOnPlanWaitingPremiumById(
            policy.planId ?? '',
            addOn.addOnId,
            timestamp
          ) ?? 0;
      }
    }

    return premium;
  }

  getProductFirstPaymentDate(
    product: Member | PolicyAddOn | undefined,
    useInceptionDate = false,
    policyData?: Policy
  ): Date {
    const policy = policyData ?? this.policyService.selectedPolicy;
    let relevantDate = useInceptionDate
      ? this.dateTimeService.verifyTimestamp(product?.inceptionDate)?.toDate()
      : this.dateTimeService.verifyTimestamp(product?.waitingDate)?.toDate();
    relevantDate?.setHours(0, 0, 0, 0);

    let firstPeriodPaid = this.getNextPaymentDate(
      relevantDate,
      0,
      undefined,
      policy
    );

    let oneMonthBeforeFirstPeriodPaid = this.getNextPaymentDate(
      firstPeriodPaid,
      -1,
      undefined,
      policy
    );

    if (
      relevantDate &&
      relevantDate > oneMonthBeforeFirstPeriodPaid &&
      relevantDate < firstPeriodPaid
    ) {
      firstPeriodPaid = oneMonthBeforeFirstPeriodPaid;
    }

    return firstPeriodPaid;
  }

  /**
   * Converts a numeric amount to a string representation in ZAR currency format.
   * If the amount is not provided or is zero, it defaults to 'R0.00'.
   *
   * @param {number | undefined} amount - The numeric value to convert.
   * @returns {string} The amount in ZAR currency format.
   */
  currencyToString(amount: number | undefined): string {
    if (amount === undefined) amount = 0;
    return amount.toLocaleString('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    });
  }

  /**
   * Displays a string representation of paid periods based on Timestamps.
   * If no periods have been paid, it returns 'NO PERIODS PAID'.
   *
   * @param {Timestamp[]} periodPaid - Array of Timestamps representing periods.
   * @returns {string} Formatted string of periods paid.
   */
  displayPeriodPaid(periodPaid: Timestamp[]): string {
    if (!periodPaid || periodPaid.length === 0) {
      return 'NO PERIODS PAID';
    }

    const sortedPeriods = periodPaid
      .map((ts) => this.dateTimeService.timestampToDate(ts))
      .filter((date): date is Date => date !== null)
      .sort((a, b) => a.getTime() - b.getTime());

    if (sortedPeriods.length === 0) {
      return 'NO PERIODS PAID';
    }

    const firstPeriod = this.formatPeriod(sortedPeriods[0]);
    const lastPeriod = this.formatPeriod(
      sortedPeriods[sortedPeriods.length - 1]
    );

    if (sortedPeriods.length === 1 || firstPeriod === lastPeriod) {
      return firstPeriod;
    } else {
      return `${firstPeriod} - ${lastPeriod}`;
    }
  }

  /**
   * Formats the provided date or timestamp into a string with month and year.
   * For example, 'January 2022' or '15 January 2022' if includeDay is true.
   *
   * @param {Date | Timestamp | undefined} date - The date or timestamp to format.
   * @param {boolean} [includeDay=false] - Whether to include the day in the output.
   * @returns {string} The formatted period string.
   */
  formatPeriod(
    date: Date | Timestamp | undefined,
    includeDay: boolean = false
  ): string {
    if (date) {
      if (date instanceof Timestamp) date = date.toDate();

      if (date instanceof Date) {
        let formattedDate =
          date
            .toLocaleString('default', { month: 'long' })
            .toLocaleUpperCase() +
          ' ' +
          date.getFullYear();

        if (includeDay) {
          formattedDate = date.getDate() + ' ' + formattedDate;
        }

        return formattedDate;
      }
    }
    return ``;
  }

  /**
   * Formats a payment method string, and adjusts the method based on the status if provided.
   *
   * @param {string | undefined} method - The payment method to format.
   * @param {string} [status] - The associated status for additional formatting.
   * @returns {string} Formatted payment method.
   */
  formatMethod(method: string | undefined, status?: string) {
    let formattedMethod = method;
    if (formattedMethod) {
      formattedMethod = formattedMethod.replace(
        /^(OFFLINE |ONLINE |SYSTEM )/,
        ''
      );
      if (status) {
        formattedMethod = status.includes('TRANSFERRED')
          ? status.replace('TRANSFERRED', 'TRANSFER')
          : formattedMethod;
      }
      return formattedMethod;
    } else {
      return '-';
    }
  }

  /**
   * Adds a specific number of months to a date.
   *
   * @param {Date} date - The base date.
   * @param {number} months - The number of months to add.
   * @returns {Date} The new date after adding the months.
   */
  addMonths(date: Date, months: number): Date {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + months);
    return newDate;
  }

  /**
   * Checks if an amount is a valid unallocated fund amount.
   * The amount should be between 0 and the unallocated balance in the selected policy.
   *
   * @param {number} amount - The amount to validate.
   * @returns {boolean} True if valid, otherwise false.
   */
  isValidUnallocatedFunds(amount: number) {
    if (
      this.policyService.selectedPolicy?.unallocatedBalance &&
      amount >= 0 &&
      amount <= this.policyService.selectedPolicy?.unallocatedBalance
    ) {
      return true;
    }
    return false;
  }

  /**
   * Determines if a transaction is eligible for reversal based on its status and the associated `periodPaid` dates of its payments.
   *
   * A transaction can be reversed only if:
   * 1. The status is "PAID", "UNIDENTIFIED", "UNALLOCATED", or "TRANSFERRED_IN".
   * 2. The transaction has not already been marked as reversed (`isReversed` is false).
   * 3. If the status is "PAID", it should have payments and a valid `transactionDate`.
   * 4. The transaction's `transactionDate` must be within the last three months.
   *
   * If the transaction meets the above criteria, it is eligible for reversal.
   * Special case: If the status is "UNIDENTIFIED", the transaction can always be reversed.
   *
   * @param {Transaction} transaction - The transaction object to validate.
   * @returns {boolean} True if the transaction can be reversed, otherwise false.
   */
  canReverseTransaction(transaction: Transaction): boolean {
    if (
      transaction.status !== TransactionStatus.PAID &&
      transaction.status !== TransactionStatus.UNIDENTIFIED &&
      transaction.status !== TransactionStatus.UNALLOCATED &&
      transaction.status !== TransactionStatus.TRANSFERRED_IN &&
      transaction.status !== TransactionStatus.ALLOCATED
    ) {
      return false;
    }

    if (transaction.isReversed) {
      return false;
    }

    if (transaction.status === TransactionStatus.UNIDENTIFIED) {
      return true;
    }

    if (
      (!transaction.payments &&
        transaction.status === TransactionStatus.PAID) ||
      !transaction.transactionDate
    ) {
      return false;
    }

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    threeMonthsAgo.setDate(0);

    // if (transaction.transactionDate.toDate() < threeMonthsAgo) {
    //   return false;
    // }

    return true;
  }

  /**
   * Determines if a transaction is eligible for transfer based on its status and the associated `periodPaid` dates of its payments.
   *
   * A transaction can be transferred only if:
   * 1. Its status is "PAID", "UNIDENTIFIED", "UNALLOCATED", or "TRANSFERRED_IN".
   * 2. The transaction has not been marked as reversed (`isReversed` is false).
   * 3. If the status is "PAID", it should have payments and a valid `transactionDate`.
   * 4. If the transaction status is "PAID", the `transactionDate` must be within the last three months.
   *
   * Special case: If the status is "UNIDENTIFIED", the transaction can always be transferred.
   *
   * If any of the above conditions are not met, the transaction cannot be transferred.
   *
   * @param {Transaction} transaction - The transaction object to validate.
   * @returns {boolean} True if the transaction can be transferred, otherwise false.
   */
  canTransferTransaction(transaction: Transaction): boolean {
    if (
      transaction.status !== TransactionStatus.PAID &&
      transaction.status !== TransactionStatus.UNIDENTIFIED &&
      transaction.status !== TransactionStatus.UNALLOCATED &&
      transaction.status !== TransactionStatus.TRANSFERRED_IN
    ) {
      return false;
    }

    if (transaction.isReversed) {
      return false;
    }

    if (transaction.status === TransactionStatus.UNIDENTIFIED) {
      return true;
    }

    if (
      (!transaction.payments &&
        transaction.status === TransactionStatus.PAID) ||
      !transaction.transactionDate
    ) {
      return false;
    }

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    threeMonthsAgo.setDate(0);

    // if (transaction.transactionDate.toDate() < threeMonthsAgo) {
    //   return false;
    // }

    return true;
  }

  /**
   * Computes the next payment date based on the last payment date, the intended day of payment, and the number of months to adjust.
   * If the intended day of payment does not exist in the target month, the date is adjusted to the last day of that month.
   *
   * @param {Date} lastPaymentDate - The date of the last payment.
   * @param {number} monthsToAdd - The number of months to adjust from the last payment date (can be positive or negative).
   * @param {number} [intendedPaymentDay] - The intended day of month for payment. If not provided, defaults to this.policyService.selectedPolicy.intendedPaymentDay.
   * @returns {Date} The computed adjusted payment date.
   */
  private getNextPaymentDate(
    lastPaymentDate: Date | undefined,
    monthsToAdd: number,
    intendedPaymentDay?: number,
    policyData?: Policy
  ): Date {
    const policy = policyData ?? this.policyService.selectedPolicy;
    const day = Number(intendedPaymentDay ?? policy?.intendedPaymentDay);

    if (lastPaymentDate === undefined) {
      throw new Error(
        'Last payment date is not provided and also not found in the selected policy.'
      );
    }

    if (day === undefined) {
      throw new Error(
        'Intended payment day is not provided and also not found in the selected policy.'
      );
    }

    let targetDate = new Date(
      lastPaymentDate.getFullYear(),
      lastPaymentDate.getMonth() + monthsToAdd,
      1
    );

    targetDate.setDate(day);

    const targetDay = targetDate.getDate();
    if (targetDay !== day) {
      targetDate.setDate(0);
    }

    return targetDate;
  }

  applyDailyTransactionFilter(): void {
    this.filteredDailyTransactions = this.dailyTransactions?.filter(
      (transaction) =>
        ((!this.filterService.dailyTransactionStatusFilter &&
          this.isSameDayReversal(transaction)) ||
          (transaction.amount !== undefined
            ? transaction.amount >= 0
            : false)) &&
        transaction.method?.includes(
          this.filterService.dailyTransactionMethodFilter
        )
    );
  }

  isSameDayReversal(transaction: Transaction) {
    let receiptNumber = transaction.receiptNumber;
    if (receiptNumber && receiptNumber.length >= 5) {
      if (receiptNumber.charAt(4) === '1') {
        receiptNumber =
          receiptNumber.slice(0, 4) + '0' + receiptNumber.slice(5);
      } else if (receiptNumber.charAt(4) === '0') {
        return true;
      }
    }
    return this.dailyTransactions.find(
      (t) => t.receiptNumber === receiptNumber
    );
  }

  public refreshAllNetcashBatches() {
    if (this.netcashBatchHistory?.batches) {
      const sortedBatches = this.netcashBatchHistory.batches
        .slice()
        .sort((a, b) => {
          const aCreatedOn = a.createdOn ? a.createdOn.toDate().getTime() : 0;
          const bCreatedOn = b.createdOn ? b.createdOn.toDate().getTime() : 0;
          return bCreatedOn - aCreatedOn;
        });
      this.allNetcashBatches = sortedBatches;
      this.applyNetcashBatchFilter();
    }
  }

  applyNetcashBatchFilter(filterValue?: string): void {
    if (filterValue) {
      this.netcashHistorySearchText = filterValue.trim().toUpperCase();
    }

    this.filteredNetcashBatches =
      this.allNetcashBatches?.filter((batch) =>
        this.doesBatchMatchFilter(batch, this.netcashHistorySearchText)
      ) ?? [];
  }

  private doesBatchMatchFilter(
    batch: NetcashBatch,
    filterValue: string
  ): boolean {
    const typeMatches =
      this.netcashHistoryFilter === 'ALL' ||
      batch.status?.includes(this.netcashHistoryFilter) ||
      false;

    const name = batch.name ? batch.name.toUpperCase() : '';
    const actionDay = batch.actionDay
      ? batch.actionDay.toString().toUpperCase()
      : '';
    const service = batch.service ? batch.service.toUpperCase() : '';
    const volume = batch.volume ? batch.volume.toString().toUpperCase() : '';
    const total = batch.total ? batch.total.toString().toUpperCase() : '';

    const createdBy = batch.createdBy?.displayName
      ? batch.createdBy.displayName.toUpperCase()
      : '';

    const upperCaseFilterValue = filterValue.toUpperCase();

    const textMatches =
      name.includes(upperCaseFilterValue) ||
      actionDay.includes(upperCaseFilterValue) ||
      service.includes(upperCaseFilterValue) ||
      volume.includes(upperCaseFilterValue) ||
      total.includes(upperCaseFilterValue) ||
      createdBy.includes(upperCaseFilterValue);

    return typeMatches && textMatches;
  }

  /**
   * Resets the selected policy transactions to an empty array.
   */
  resetSelectedPolicyTransactions() {
    this.selectedPolicyTransactionsSubject = new BehaviorSubject<Transaction[]>(
      []
    );
    this.selectedPolicyTransactions$ =
      this.selectedPolicyTransactionsSubject.asObservable();
    this.selectedPolicyTransactions = [];
    this.lastSelectedPolicyTransactionDoc = null;
  }

  unsubscribe() {
    if (this.unsubscribeFromTransactionsSnapshot) {
      this.unsubscribeFromTransactionsSnapshot();
      this.unsubscribeFromTransactionsSnapshot = undefined;
      this.lastLoadedTransactionDoc = null;
    }
  }

  /**
   * Cleans up and resets the service properties and subscriptions.
   */
  cleanUp() {
    this.dailyTransactions = [];
    this.selectedDailyTransactionDate = new Date(
      Date.now() - 24 * 60 * 60 * 1000
    );

    this.transactionCount = undefined;

    this.unsubscribe();
    this.destroy$.next();
  }
}
