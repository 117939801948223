import { Injectable } from '@angular/core';
import { TransactionStatus } from '../models/transaction.model';
import { map, firstValueFrom, Observable } from 'rxjs';
import { Log } from '../models/log.model';
import { SnackBarService } from './snack-bar.service';
import { MemberStatus, Policy } from '../models/policy.model';
import { MemberPolicySearch } from '../models/search.model';
import { PlanService } from './plan.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MainService } from './main.service';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private dbModular = getFirestore();
  private fireFunctions = getFunctions(undefined, 'europe-west3');

  constructor(
    private snackBarService: SnackBarService,
    private planService: PlanService,
    private mainService: MainService
  ) {}

  validateIdNumber(id: string): boolean {
    if (id.length !== 13) {
      return false;
    }

    const MM = parseInt(id.slice(2, 4), 10);
    const DD = parseInt(id.slice(4, 6), 10);

    // Check month
    if (MM < 1 || MM > 12) {
      return false;
    }

    // Check day based on month (February has 29 days on a leap year)
    if (
      DD < 1 ||
      DD > 31 ||
      (MM === 2 && DD > 29) ||
      (DD > 30 && [4, 6, 9, 11].includes(MM))
    ) {
      return false;
    }

    // Check citizenship code
    const CC = parseInt(id.slice(10, 11), 10);
    if (CC > 1) {
      return false;
    }

    // Luhn algorithm for check digit
    let sum = 0;
    for (let i = 0; i < 12; i++) {
      let num = parseInt(id.charAt(i), 10);
      if (i % 2 !== 0) {
        num *= 2;
        if (num > 9) {
          num -= 9;
        }
      }
      sum += num;
    }

    const checkDigit = 10 - (sum % 10);
    return checkDigit % 10 === parseInt(id.charAt(12), 10);
  }

  getPotentialAgesFromSAId(idNumber: string): [number, number] {
    const currentYear = new Date().getFullYear();

    const yearFromId = +idNumber.substring(0, 2);

    const ageWith2000s = currentYear - (2000 + yearFromId);
    const ageWith1900s = currentYear - (1900 + yearFromId);

    return [ageWith2000s, ageWith1900s];
  }

  validateIdNumberAge(id: string, minAge: number, maxAge: number): boolean {
    const [age2000s, age1900s] = this.getPotentialAgesFromSAId(id);

    const isAge2000sValid =
      age2000s >= minAge && age2000s <= maxAge && age2000s >= 0;
    const isAge1900sValid =
      age1900s >= minAge && age1900s <= maxAge && age1900s >= 0;

    // If only one of the ages is valid, return true
    return (
      (isAge2000sValid && isAge1900sValid) ||
      (isAge2000sValid && age1900s < 0) ||
      (isAge1900sValid && age2000s < 0)
    );
  }

  async searchDuplicateReceiptReferenceNumber(
    receiptReferenceNumber: string
  ): Promise<boolean> {
    const transactionsCollectionRef = collection(this.dbModular, 'transaction');
    const transactionsQuery = query(
      transactionsCollectionRef,
      where('receiptReferenceNumber', '==', receiptReferenceNumber)
    );

    const observable$ = new Observable<boolean>((observer) => {
      const unsubscribe = onSnapshot(
        transactionsQuery,
        (querySnapshot) => {
          const hasDuplicate = querySnapshot.docs.some((doc) => {
            const data = doc.data();
            return !(
              data['status'] === TransactionStatus.REVERSED ||
              data['isReversed'] === true
            );
          });
          observer.next(hasDuplicate);
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );

      return unsubscribe;
    });

    return await firstValueFrom(observable$);
  }

  /**
   * Check if a reference number exists in the policyLog collection and that its policyId is not the provided one
   * @param referenceNumber The reference number to check
   * @param policyId The policyId to exclude
   * @returns Promise<Log | null> The document data if a document with the reference number exists and its policyId is not the provided one, null otherwise
   */
  async checkReferenceNumberExists(
    referenceNumber: string,
    policyId: string
  ): Promise<Log | null> {
    const logCollectionRef = collection(this.dbModular, 'policyLog');
    const logQuery = query(
      logCollectionRef,
      where('referenceNumbers', 'array-contains', referenceNumber),
      where('policyId', '!=', policyId),
      limit(1)
    );

    const querySnapshot = await getDocs(logQuery);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data() as Log;
    }
    return null;
  }

  async estimateDocumentSize(
    collectionName: string,
    docId: string
  ): Promise<number> {
    if (!collectionName || !docId) {
      this.snackBarService.openRedSnackBar(
        'Invalid collection or document ID!'
      );
      return 0;
    }
    const docRef = doc(this.dbModular, collectionName, docId);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) {
      this.snackBarService.openRedSnackBar('Document does not exist!');
      return 0;
    }

    const docData = docSnapshot.data();
    const sizeInBytes = new Blob([JSON.stringify(docData)]).size;
    const sizeInKB = +(sizeInBytes / 1024).toFixed(2);

    return sizeInKB;
  }

  async checkDocumentSize(collection: string, docId: string) {
    const size = await this.estimateDocumentSize(collection, docId);
    if (size >= 900) {
      alert('Document is approaching the 1MB limit! (bigger than 900KB)');
    }
  }

  async searchDuplicateIdNumber(
    idNumber: string,
    memberTypeId?: string
  ): Promise<MemberPolicySearch[] | undefined> {
    const policyCollectionRef = collection(this.dbModular, 'policy');
    const policyQuery = query(
      policyCollectionRef,
      where('memberIdNumbers', 'array-contains', idNumber)
    );

    const policiesWithMatchingId: Policy[] = await firstValueFrom(
      new Observable<Policy[]>((observer) => {
        const unsubscribe = onSnapshot(
          policyQuery,
          (querySnapshot) => {
            const policies = querySnapshot.docs.map(
              (doc) => doc.data() as Policy
            );
            observer.next(policies);
          },
          (error) => observer.error(error)
        );

        return unsubscribe;
      })
    );

    const memberSearchResults: MemberPolicySearch[] = [];

    for (const policy of policiesWithMatchingId) {
      for (const member of policy.members || []) {
        if (member.idNumber === idNumber) {
          if (
            member.memberTypeId === undefined ||
            member.status === MemberStatus.INACTIVE
          ) {
            continue;
          }

          let planName = '(NOT FOUND)';
          if (policy.planId) {
            const plan = this.planService.getPlanNameById(policy.planId);
            planName = plan?.text || planName;
          }

          memberSearchResults.push({
            policyNumber: policy.policyNumber || '',
            idNumber: member.idNumber,
            firstName: member.firstName || '',
            lastName: member.lastName || '',
            plan: planName,
            memberTypeId: member.memberTypeId || '',
            memberStatus: member.status || '',
            planStatus: policy.status || '',
            waitingDate: member.waitingDate || '',
            policyId: policy.id || '',
          });
        }
      }
    }

    let hasActivePrimary = false;
    let hasActiveNonPrimary = false;

    for (const result of memberSearchResults) {
      if (result.memberTypeId) {
        const memberType = this.planService.getMemberTypeById(
          result.memberTypeId
        );
        if (memberType.primaryMember) {
          if (hasActivePrimary) {
            result.exclude = true;
          } else {
            hasActivePrimary = true;
          }
        } else {
          if (hasActiveNonPrimary) {
            result.exclude = true;
          } else {
            hasActiveNonPrimary = true;
          }
        }
      }
    }

    const validResults =
      !hasActivePrimary || !hasActiveNonPrimary
        ? memberSearchResults.filter((result) => !result.exclude)
        : memberSearchResults;

    if (validResults.length === 0) {
      return undefined;
    }

    if (memberTypeId) {
      const inputMemberType = this.planService.getMemberTypeById(memberTypeId);
      if (
        (inputMemberType.primaryMember && !hasActivePrimary) ||
        (!inputMemberType.primaryMember && !hasActiveNonPrimary)
      ) {
        return undefined;
      }
    }

    return validResults;
  }

  async searchDuplicatePolicyNumber(policyNumber: string): Promise<boolean> {
    const policyCollectionRef = collection(this.dbModular, 'policy');
    const policyQuery = query(
      policyCollectionRef,
      where('policyNumber', '==', policyNumber),
      limit(1)
    );

    const observable$ = new Observable<boolean>((observer) => {
      const unsubscribe = onSnapshot(
        policyQuery,
        (querySnapshot) => {
          const hasDuplicate = querySnapshot.docs.length > 0;
          observer.next(hasDuplicate);
        },
        (error) => observer.error(error)
      );

      return unsubscribe;
    }).pipe(map((hasDuplicate) => hasDuplicate));

    return await firstValueFrom(observable$);
  }

  allowedDaysValidator(allowedDays: number[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (allowedDays.includes(Number(control.value))) {
        return null; // validation passes
      }
      return { invalidDay: { value: control.value } }; // validation fails
    };
  }

  async validateBankAccount(
    accountNumber: string,
    branchCode: string,
    accountType: number
  ): Promise<boolean> {
    this.mainService.setLoading(true);

    const validateBankAccountCallable = httpsCallable(
      this.fireFunctions,
      'validateBankAccount'
    );

    type ValidateBankAccountResponse = {
      ValidateBankAccountResult: number;
    };

    try {
      // Call the function and cast the result to the expected type
      const result = (await validateBankAccountCallable({
        accountNumber,
        branchCode,
        accountType,
      })) as { data: ValidateBankAccountResponse };

      // Safely access the expected field
      const validationCode = Number(result.data.ValidateBankAccountResult);

      switch (validationCode) {
        case 0:
          this.snackBarService.latestError = 'Bank account details valid';
          // Handle valid case
          break;
        case 1:
          this.snackBarService.latestError = 'Invalid branch code';
          // Handle invalid branch code
          break;
        case 2:
          this.snackBarService.latestError =
            'Account number failed check digit validation';
          // Handle account number check digit failure
          break;
        case 3:
          this.snackBarService.latestError = 'Invalid account type';
          // Handle invalid account type
          break;
        case 4:
          this.snackBarService.latestError = 'Input data incorrect';
          // Handle incorrect input data
          break;
        case 100:
          this.snackBarService.latestError = 'Authentication failed';
          // Handle authentication failure
          break;
        case 200:
          this.snackBarService.latestError =
            'Web service error contact support@netcash.co.za';
          // Handle web service error
          break;
        default:
          this.snackBarService.latestError = 'Unknown response code';
        // Handle unknown response
      }

      this.mainService.setLoading(false);
      return validationCode === 0; // Return true if valid, false otherwise
    } catch (error) {
      this.mainService.setLoading(false);
      console.error('Error calling validateBankAccount Cloud Function:', error);
      if (error instanceof Error) {
        this.snackBarService.latestError = error.message;
      }
      this.snackBarService.openRedSnackBar('Error validating bank account.');
      return false;
    }
  }

  // conditionalValidator(validator: ValidatorFn): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     // Check if the control is part of a FormGroup
  //     if (control.parent instanceof FormGroup) {
  //       const formGroup = control.parent;

  //       // Count the non-empty controls
  //       const nonEmptyControlsCount = Object.keys(formGroup.controls).filter(
  //         (key) => {
  //           const siblingControl = formGroup.get(key);
  //           return siblingControl && siblingControl.value;
  //         }
  //       ).length;

  //       // If there is more than one non-empty control, and this control is empty and required, return an error
  //       if (
  //         nonEmptyControlsCount > 1 &&
  //         !control.value &&
  //         validator === Validators.required
  //       ) {
  //         return { required: true };
  //       }
  //     }

  //     // If the control has a value or if it's not the required validator, apply the validator function
  //     if (control.value || validator !== Validators.required) {
  //       return validator(control);
  //     }

  //     // Otherwise, no error
  //     return null;
  //   };
  // }
}
