import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, NgZone, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { PolicyService } from './services/policy.service';
import {
  Observable,
  Subscription,
  fromEvent,
  switchMap,
  interval,
  takeUntil,
  Subject,
} from 'rxjs';
import { SnackBarService } from './services/snack-bar.service';
import { RolesRightsService } from './services/roles-rights.service';
import { MainService } from './services/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate('500ms ease-in-out')),
    ]),
    trigger('fadeOut', [
      state('*', style({ opacity: 1 })),
      transition(
        '* => void',
        animate('500ms ease-in-out', style({ opacity: 0 }))
      ),
    ]),
  ],
})
export class AppComponent implements OnInit {
  isLoading: boolean;
  loadingCursor: boolean;
  isDisabled: boolean;
  loadingInfo: string = '';
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  destroy$ = new Subject<void>();

  constructor(
    public mainService: MainService,
    public userService: UserService, // Add the UserService to be used in app.component.html
    private titleService: Title, // Add the Title service
    public snackBarService: SnackBarService,
    public policyService: PolicyService,
    private zone: NgZone,
    public rolesRightsService: RolesRightsService,
    private router: Router
  ) {
    this.rolesRightsService.connected = navigator.onLine;
    this.mainService.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.mainService.loadingInfo$.subscribe((loadingInfo) => {
      this.loadingInfo = loadingInfo;
    });

    this.mainService.isDisabled$.subscribe((isDisabled) => {
      this.isDisabled = isDisabled;
    });

    this.mainService.loadingCursor$.subscribe((loadingCursor) => {
      this.loadingCursor = loadingCursor;
    });

    this.userService.authState$.subscribe((isLoggedIn) => {
      if (isLoggedIn && this.router.url.startsWith('/login')) {
        this.router.navigate([this.mainService.getPreviousRoute()]);
      }
    });

    this.rolesRightsService.currentRoleUpdated
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (
          this.userService.userData &&
          this.rolesRightsService.currentUserRole
        )
          this.checkAccess();
      });
  }

  async ngOnInit() {
    this.titleService.setTitle(environment.title);

    // Get the online/offline status from browser window
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.rolesRightsService.connected = true;

    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this.zone.run(() => {
          this.snackBarService.openWhiteSnackBar('BACK ONLINE');
          this.rolesRightsService.connected = true;
        });
      })
    );

    this.subscriptions.push(
      this.offlineEvent
        .pipe(switchMap(() => interval(5000).pipe(takeUntil(this.onlineEvent))))
        .subscribe((e) => {
          this.zone.run(() => {
            this.snackBarService.openRedSnackBar(
              'CONNECTION LOST PLEASE CALL SEAN'
            );
            this.rolesRightsService.connected = false;
          });
        })
    );
  }

  private checkAccess() {
    const currentUrl = this.router.url;

    if (
      currentUrl &&
      currentUrl.replace('/', '').length > 0 &&
      !this.rolesRightsService.hasAccess(currentUrl, this.userService.userData)
    ) {
      this.router.navigate(['/home']);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export const appVersion = '2024.11.06';
