import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ReportService } from 'src/app/services/report.service';
import { RolesRightsService } from 'src/app/services/roles-rights.service';

@Component({
  selector: 'report-stepper',
  templateUrl: './report-stepper.component.html',
  styleUrls: ['./report-stepper.component.scss'],
})
export class ReportStepperComponent {
  showStepper = false;
  selectedIndex = 0;

  constructor(
    private router: Router,
    public reportService: ReportService,
    public rolesRightsService: RolesRightsService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.reloadStepperState();
      });
  }

  ngOnInit() {
    this.reloadStepperState();
  }

  reloadStepperState() {
    const currentRoute = this.router.url;
    this.showStepper =
      currentRoute.startsWith('/report-details') ||
      currentRoute.startsWith('/report-fields');

    if (this.showStepper) {
      if (currentRoute.startsWith('/report-details')) {
        this.selectedIndex = 0;
      } else if (currentRoute.startsWith('/report-fields')) {
        this.selectedIndex = 1;
      }
    }
  }

  onRouteChange(selectedIndex: number): void {
    if (this.reportService.selectedReport?.id)
      switch (selectedIndex) {
        case 0:
          this.router.navigate([
            `/report-details`,
            this.reportService.selectedReport?.id ?? 'new',
          ]);
          break;
        case 1:
          this.router.navigate([
            '/report-fields',
            this.reportService.selectedReport?.id ?? '',
          ]);
          break;
      }
  }
}
