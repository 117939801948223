import { Timestamp } from '@firebase/firestore';
import { CreatedBy, UpdatedBy } from './general.model';
export interface AddOn {
  id?: string;
  name?: string;
  status?: string;
  plans?: AddOnPlan[];
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export interface AddOnPlan {
  planId?: string;
  premium?: AddOnPremium[];
  status?: string;
  waitingPeriod?: number;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export interface AddOnPremium {
  standardAmount: number;
  waitingAmount: number;
  startDate: Timestamp;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export enum AddOnStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REQUESTED = 'REQUESTED',
  CLAIMED = 'CLAIMED',
  WAITING_PERIOD = 'WAITING PERIOD',
}
