import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  latestError: string = 'NO FURTHER INFORMATION AVAILABLE';

  constructor(public snackBar: MatSnackBar, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.dismissSnackBar();
      }
    });
  }

  openBlueSnackBar(message: string) {
    this.snackBar.open(message.toUpperCase(), '❤️', {
      duration: 3000,
      panelClass: 'blue-snackbar',
    });
  }

  openRedSnackBar(message: string) {
    let snackBarRef = this.snackBar.open(message.toUpperCase(), '👎', {
      duration: 1000000,
      panelClass: 'red-snackbar',
    });

    const actionSubscription = snackBarRef.onAction().subscribe(() => {
      this.openDetailedErrorSnackBar();
    });

    snackBarRef.afterDismissed().subscribe(() => {
      actionSubscription.unsubscribe();
    });
  }

  private openDetailedErrorSnackBar() {
    let snackBarRef = this.snackBar.open(this.latestError, 'CLOSE', {
      duration: 1000000,
      panelClass: 'red-snackbar',
    });

    const actionSubscription = snackBarRef.onAction().subscribe(() => {
      this.dismissSnackBar;
    });

    snackBarRef.afterDismissed().subscribe(() => {
      actionSubscription.unsubscribe();
      this.latestError = 'NO FURTHER INFORMATION AVAILABLE';
    });
  }

  openWhiteSnackBar(message: string) {
    this.snackBar.open(message.toUpperCase(), '💎', {
      duration: 3000,
      panelClass: 'white-snackbar',
    });
  }

  openLoadingSnackBar(message: string) {
    this.snackBar.open(message.toUpperCase(), '💎', {
      duration: 30000,
      panelClass: 'white-snackbar',
    });
  }

  openPrimaryMemberSnackBar(message: string) {
    this.snackBar.open(message.toUpperCase(), '⭐', {
      duration: 1000000,
      panelClass: 'white-snackbar',
    });
  }

  openWhiteContinueSnackBar(route: string) {
    let snackBarRef = this.snackBar.open(
      'YOU HAVE UNSAVED DATA!',
      'CONTINUE ANYWAY',
      {
        duration: 20000,
        panelClass: ['white-snackbar', 'bold'],
      }
    );

    const actionSubscription = snackBarRef.onAction().subscribe(() => {
      this.router.navigate(['/' + route]), this.dismissSnackBar;
    });

    snackBarRef.afterDismissed().subscribe(() => {
      actionSubscription.unsubscribe();
    });
  }

  dismissSnackBar() {
    if (this.snackBar) {
      this.snackBar.dismiss();
    }
  }
}
