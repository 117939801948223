<ng-container
  *ngIf="
    showStepper &&
    (rolesRightsService.currentUserRole?.policies?.export ||
      rolesRightsService.currentUserRole?.transactions?.export)
  "
>
  <h1 class="pageSideMargins policyNumber">
    {{ reportService.selectedReport?.reportName ?? "&#8203;" }}
  </h1>
  <custom-stepper
    [steps]="[{ label: 'Report Details' }, { label: 'Report Fields' }]"
    [(selectedIndex)]="selectedIndex"
    [enabledSteps]="[true, !!reportService.selectedReport]"
    (routeChange)="onRouteChange($event)"
  ></custom-stepper>
</ng-container>
