import { EventEmitter, Injectable } from '@angular/core';
import { AddOnService } from './add-on.service';
import { DateTimeService } from './date-time.service';
import { PlanService } from './plan.service';
import { SnackBarService } from './snack-bar.service';
import { UserService } from './user.service';
import { uuidv4 } from '@firebase/util';
import {
  BankAccountTypes,
  DebitOrder,
  Member,
  Policy,
  PolicyAddOn,
  PolicyStatus,
} from '../models/policy.model';
import { setDoc, Timestamp } from '@firebase/firestore';
import {
  Log,
  PolicyLogIndex,
  Change,
  PolicyLog,
  EventType,
  PolicyEvent,
  PolicyLogIndexFilters,
  PolicyChange,
  PolicyChangeIndex,
} from '../models/log.model';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SearchService } from './search.service';
import { FileData } from '../models/file.model';
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  Query,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class PolicyLogService {
  private dbModular = getFirestore();

  // Form state
  loading = false;
  success = false;
  claim = false;

  logType: string;
  indexType: string;
  indexObject: string;
  objectInfo: string;

  private _currentRefNumSet: Subject<void> = new Subject<void>();
  public currentRefNumSet$ = this._currentRefNumSet.asObservable();

  private _currentRefNum: string | undefined = undefined;

  searchText: string = '';
  returnRoute: string = '/policy-summary';

  selectedPolicyLog: PolicyLog | undefined;
  allPolicyLogs: PolicyEvent[] | undefined;
  filteredPolicyLogs: PolicyEvent[] = [];

  policyLogDoc: DocumentReference<Log>;
  selectedPolicyLog$: Observable<PolicyLog | undefined>;
  destroy$ = new Subject<void>();

  selectedPolicyLogUpdated = new EventEmitter<void>();

  dataSourcePolicyLogs: MatTableDataSource<any>;

  constructor(
    private addOnService: AddOnService,
    private planService: PlanService,
    private dateTimeService: DateTimeService,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private searchService: SearchService
  ) {
    this.userService.destroy$.pipe().subscribe(() => this.cleanUp());
  }

  public get currentRefNum(): string | undefined {
    return this._currentRefNum;
  }

  public set currentRefNum(value: string | undefined) {
    this._currentRefNum = value;
    this._currentRefNumSet.next();
  }

  /**
   * Sets the selected policy log based on a given policy ID.
   * @param policyId - The ID of the policy to fetch the log for.
   * @returns A promise that resolves when the selected policy log is set.
   */
  async setSelectedPolicyLog(policyId: string): Promise<void> {
    const policyLogRef = collection(this.dbModular, 'policyLog');
    const q = query(policyLogRef, where('policyId', '==', policyId));

    this.selectedPolicyLog$ = new Observable<PolicyLog>((observer) => {
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          if (!querySnapshot.empty) {
            const docSnapshot = querySnapshot.docs[0];
            observer.next({
              ...(docSnapshot.data() as PolicyLog),
              id: docSnapshot.id,
            });
          } else {
            this.snackBarService.openRedSnackBar('ERROR FETCHING POLICY LOGS');
            observer.error(new Error('ERROR FETCHING POLICY LOGS'));
          }
        },
        (error) => {
          observer.error(error);
        }
      );

      return () => unsubscribe();
    });

    return new Promise<void>((resolve) => {
      this.selectedPolicyLog$.pipe(takeUntil(this.destroy$)).subscribe({
        next: (log) => {
          if (log) {
            this.selectedPolicyLog = log;
            this.refreshAllPolicyLogs();
            this.selectedPolicyLogUpdated.emit();
          }
          resolve();
        },
        error: (err) => {
          if (err instanceof Error) {
            this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar('ERROR FETCHING POLICY LOGS');
          }
        },
      });
    });
  }

  /**
   * Creates a new policy log entry for a given policy.
   * @param policy - The policy object to create the log for.
   * @param isImport - Optional flag to indicate if the log is for an import operation.
   */
  async createPolicyLog(policy: Policy, isImport: boolean = false) {
    this.loading = true;
    try {
      const events: never[] = [];
      const referenceNumbers: never[] = [];

      const log = {
        policyId: policy.id,
        events,
        referenceNumbers,
      };

      // Add the modified form values to the 'log' collection in the database
      const policyLogRef = collection(this.dbModular, 'policyLog');
      const transactionLogRef = collection(this.dbModular, 'transactionLog');
      await Promise.all([
        addDoc(policyLogRef, log),
        addDoc(transactionLogRef, log),
      ]);

      // Set the selected log to the new log with the generated ID
      if (policy.id && policy.planId && policy.policyNumber) {
        const changes: Change[] = [];
        const policyData: Policy = {
          planId: policy.planId,
          policyNumber: policy.policyNumber,
          intendedPaymentDay: policy.intendedPaymentDay,
          payAtNumber: policy.payAtNumber,
          inceptionDate: this.dateTimeService.verifyTimestamp(
            policy.inceptionDate
          ),
          status: policy.status,
        };

        (Object.keys(policyData) as Array<keyof Policy>).forEach((key) => {
          const change: Change = { key: key };
          change.after = policyData[key];
          changes.push(change);
        });

        this.indexType = isImport ? 'import' : 'create';

        const types: EventType = {
          object: 'policy',
          type: this.indexType,
        };

        this.indexObject = 'policy';
        await this.updateLogEvents(changes, types, policy);
      }
      this.success = true;
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar(
          `ERROR LOGGING POLICY ${isImport ? 'IMPORT' : 'CREATE'}`
        );
      }
    } finally {
      this.loading = false;
    }
  }

  /**
   * Logs the creation of a new debit order for a policy.
   * @param debitOrder - The debit order object to log.
   * @param policy - The policy associated with the debit order.
   * @param isImport - Optional flag to indicate if the log is for an import operation.
   */
  async newDebitOrderLog(
    debitOrder: DebitOrder,
    policy: Policy,
    isImport: boolean = false
  ) {
    this.loading = true;
    try {
      this.indexType = isImport ? 'import' : 'create';
      const changes = this.getLogNewDebitOrderLog(debitOrder);

      const types: EventType = {
        object: 'debitOrder',
        type: this.indexType,
      };

      this.indexObject = 'debitOrder';
      await this.updateLogEvents(changes, types, policy);
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR LOGGING UPDATE EVENT');
    }
    this.loading = false;
  }

  /**
   * Updates the events in a policy log based on a list of changes.
   * @param changes - An array of Change objects representing the updates.
   * @param type - The type of event being logged.
   * @param policy - The policy associated with the log.
   * @param reason - Optional reason for the log update.
   */
  async updateLogEvents(
    changes: Change[],
    type: EventType,
    policy: Policy,
    reason?: string
  ) {
    this.loading = true;

    if (policy?.id && this.selectedPolicyLog?.policyId !== policy.id) {
      await this.setSelectedPolicyLog(policy.id);
    }

    const policyLogDoc = this.selectedPolicyLog;

    try {
      if (!policyLogDoc?.id || !policy?.id) {
        throw new Error('POLICY AND/OR POLICY LOG DOCUMENT ID NOT PROVIDED!');
      }

      if (!policyLogDoc.events) {
        policyLogDoc.events = [];
      }
      if (!policyLogDoc.referenceNumbers) {
        policyLogDoc.referenceNumbers = [];
      }

      const id = uuidv4();
      const createdBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const createdOn = Timestamp.now();
      policyLogDoc.events.push({
        ...type,
        id,
        changes,
        objectInfo: this.objectInfo ?? '',
        reason: reason ?? '',
        createdOn,
        createdBy,
      });

      policyLogDoc.referenceNumbers.push(this.currentRefNum ?? '');

      const policyLogRef = doc(this.dbModular, 'policyLog', policyLogDoc.id);
      await updateDoc(policyLogRef, {
        events: policyLogDoc.events,
        referenceNumbers: policyLogDoc.referenceNumbers,
        policyNumber: policy.policyNumber,
      });

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const date = Timestamp.fromDate(today);

      const indexObject: EventType = {};

      if (this.indexObject !== undefined) {
        indexObject.object = this.indexObject;
      }
      if (this.indexType !== undefined) {
        indexObject.type = this.indexType;
      }

      const types: EventType[] = [];
      types.push({ object: indexObject.object });
      types.push({ type: indexObject.type });
      types.push({ object: indexObject.object, type: indexObject.type });

      if (this.indexType === 'update') {
        changes.forEach((change) => {
          const type = { ...indexObject, key: change.key };
          if (!types.includes(type)) {
            types.push(type);
          }

          if (!types.includes({ key: change.key })) {
            types.push({ key: change.key });
          }

          if (!types.includes({ type: indexObject.type, key: change.key })) {
            types.push({ type: indexObject.type, key: change.key });
          }
        });
      } else {
        types.push({ ...indexObject });
      }

      await this.updateOrCreatePolicyLogIndex(
        policy.id,
        policyLogDoc.id,
        date,
        types
      );
      this.success = true;

      if (this.claim) {
        this.currentRefNum = undefined;
        this.claim = false;
      }
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('ERROR LOGGING UPDATE EVENT');
      }
    } finally {
      this.loading = false;
    }
  }

  /**
   * Updates the policy log when there is a change in the policy data.
   * @param oldDoc - The original document before the update.
   * @param newDoc - The new document after the update.
   * @param reason - Optional reason for the update.
   */
  async updateLog(oldDoc: any, newDoc: any, reason?: string) {
    try {
      this.objectInfo = '';
      this.logType = this.searchService.overrideMemberId
        ? 'OVERRIDE'
        : 'UPDATE';
      this.indexType = 'update';
      const changes: Change[] = this.compareNestedFields(oldDoc, newDoc);
      if (changes.length > 0) {
        const indexObject: EventType = {};

        if (this.indexObject !== undefined) {
          indexObject.object = this.indexObject;
        }
        if (this.indexType !== undefined) {
          indexObject.type = this.indexType;
        }

        await this.updateLogEvents(changes, indexObject, newDoc, reason);
      }
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR LOGGING UPDATE EVENT');
    }
  }

  /**
   * Creates a new shard for a policy log index entry.
   * @param policyId - The policy ID associated with the log index.
   * @param policyLogId - The policy log ID to be updated or created.
   * @param day - The date associated with the log index entry.
   * @param types - An array of event types to be included in the log index.
   */
  async updateOrCreatePolicyLogIndex(
    policyId: string,
    policyLogId: string,
    day: Timestamp,
    types: EventType[]
  ): Promise<void> {
    try {
      const uid = this.userService.userData?.uid;

      if (uid === undefined || uid === null) {
        throw new Error('USER ID NOT PROVIDED!');
      }

      const shardId = uuidv4();
      const shardDocRef = doc(
        collection(this.dbModular, 'policyLogShards'),
        shardId
      );

      const policyLogData = {
        day,
        policyId,
        policyLogId,
        type: types,
        user: [uid],
        typeUser: types.map((type) => ({ type, uid })),
      };

      await setDoc(shardDocRef, policyLogData);
      console.log(`Shard created for PolicyLogIndex with ID: ${shardId}`);
    } catch (error) {
      console.error('Error creating shard for PolicyLogIndex:', error);
      throw error;
    }
  }

  /**
   * Loads and formats policy event changes based on the provided filters.
   * @param filters - Filters for the policy log index, including event type and user.
   * @returns An array of PolicyChange objects with formatted data fields.
   */
  async loadFilteredPolicyEventChanges(
    filters: PolicyLogIndexFilters
  ): Promise<PolicyChange[]> {
    const policyEvents = await this.loadFilteredPolicyEvents(filters);
    let allSplitPolicyEvents: PolicyChange[] = [];

    for (const event of policyEvents) {
      if (event.changes && event.changes.length > 0) {
        for (const change of event.changes) {
          if (!filters?.type?.key || change.key === filters.type.key) {
            const { changes, ...eventWithoutChanges } = event;
            let splitEvent: PolicyChange = {
              ...eventWithoutChanges,
              ...change,
              key: this.prettifyFieldName(change.key ?? '', event.objectInfo),
            };

            if (change.before)
              splitEvent.before = this.formatTableValue(
                change.key ?? '',
                change.before
              );
            if (change.after)
              splitEvent.after = this.formatTableValue(
                change.key ?? '',
                change.after
              );

            allSplitPolicyEvents.push(splitEvent);
          }
        }
      } else {
        const { changes, ...eventWithoutChanges } = event;
        allSplitPolicyEvents.push(eventWithoutChanges as PolicyChange);
      }
    }

    return allSplitPolicyEvents;
  }

  /**
   * Fetches policy events based on the provided filters.
   * @param filters - Filters for the policy log index, including date range, event type, and user.
   * @returns An array of PolicyEvent objects that match the given filters.
   */
  async loadFilteredPolicyEvents(
    filters: PolicyLogIndexFilters
  ): Promise<PolicyEvent[]> {
    const policyLogIndexes = await this.loadRelevantPolicyLogIndexes(filters);

    let allPolicyEvents: PolicyEvent[] = [];
    for (const index of policyLogIndexes) {
      for (const change of index.relevantPolicies) {
        if (this.isChangeRelevant(change, filters)) {
          const policyLog = await this.fetchPolicyLog(change.policyLogId);
          if (policyLog && policyLog.events) {
            let filteredEvents: PolicyEvent[] = [];

            policyLog.events.forEach((event, i) => {
              if (this.isEventRelevant(event, filters)) {
                const correspondingReferenceNumber = policyLog.referenceNumbers
                  ? policyLog.referenceNumbers[i]
                  : undefined;
                const formattedEvent = {
                  ...event,
                  object: this.formatObjectName(event.object ?? ''),
                  type: event.type?.toUpperCase(),
                  policyNumber: policyLog.policyNumber,
                  referenceNumber: correspondingReferenceNumber,
                };
                filteredEvents.push(formattedEvent);
              }
            });

            allPolicyEvents = allPolicyEvents.concat(filteredEvents);
          }
        }
      }
    }

    return allPolicyEvents;
  }

  /**
   * Determines if a given policy change is relevant based on the provided filters.
   * @param change - The policy change index object to be evaluated.
   * @param filters - Filters for the policy log index, including event type and user.
   * @returns True if the change is relevant based on the filters, otherwise false.
   */
  private isChangeRelevant(
    change: PolicyChangeIndex,
    filters: PolicyLogIndexFilters
  ): boolean {
    // Check if the type in PolicyChange matches the type in filters
    const typeMatch =
      !filters.type ||
      change.type.some(
        (changeType) =>
          filters.type && this.isEventTypeEqual(changeType, filters.type)
      );

    // Check if the user in PolicyChange matches the user in filters
    const userMatch = !filters.user || change.user.includes(filters.user);

    // Combine the checks
    return typeMatch && userMatch;
  }

  /**
   * Determines if a given policy event is relevant based on the provided filters.
   * @param event - The policy event object to be evaluated.
   * @param filters - Filters for the policy log index, including event type and user.
   * @returns True if the event is relevant based on the filters, otherwise false.
   */
  private isEventRelevant(
    event: PolicyEvent,
    filters: PolicyLogIndexFilters
  ): boolean {
    let typeMatches = true;
    if (filters.type) {
      const objectMatch =
        filters.type.object === undefined ||
        event.object === filters.type.object;
      const typeMatch =
        filters.type.type === undefined || event.type === filters.type.type;
      const keyMatch =
        filters.type.key === undefined ||
        event.changes?.some((change) => change.key === filters.type?.key) ||
        event.changes === undefined;

      typeMatches = objectMatch && typeMatch && keyMatch;
    }

    let userMatch = true;
    if (filters.user) {
      userMatch = event.createdBy?.uid === filters.user;
    }

    return typeMatches && userMatch;
  }

  /**
   * Fetches a specific policy log by its ID.
   * @param policyLogId - The ID of the policy log to fetch.
   * @returns The fetched PolicyLog object or null if not found.
   */
  async fetchPolicyLog(policyLogId: string): Promise<PolicyLog | null> {
    try {
      const policyLogRef = doc(this.dbModular, 'policyLog', policyLogId);
      const policyLogDoc = await getDoc(policyLogRef);

      if (policyLogDoc.exists()) {
        return policyLogDoc.data() as PolicyLog;
      } else {
        console.log(`No policy log found with ID: ${policyLogId}`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching PolicyLog with ID ${policyLogId}:`, error);
      throw error;
    }
  }

  /**
   * Loads relevant policy log indexes based on the given filters.
   * @param filters - Filters for the policy log index, including date range, event type, and user.
   * @returns An array of PolicyLogIndex objects matching the filters.
   */
  private async loadRelevantPolicyLogIndexes(
    filters: PolicyLogIndexFilters
  ): Promise<PolicyLogIndex[]> {
    let queryStartDate: Timestamp, queryEndDate: Timestamp;

    if (filters.startDate) {
      const start = new Date(filters.startDate.toDate());
      start.setHours(0, 0, 0, 0);
      queryStartDate = Timestamp.fromDate(start);
    } else {
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      queryStartDate = Timestamp.fromDate(todayStart);
    }

    if (filters.endDate) {
      const end = new Date(filters.endDate.toDate());
      end.setHours(23, 59, 59, 999);
      queryEndDate = Timestamp.fromDate(end);
    } else {
      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      queryEndDate = Timestamp.fromDate(todayEnd);
    }

    const { type, user } = filters;

    try {
      let queryRef;
      if (type && user) {
        queryRef = this.queryByDateTypeUser(
          queryStartDate,
          queryEndDate,
          type,
          user
        );
      } else if (type) {
        queryRef = this.queryByDateType(queryStartDate, queryEndDate, type);
      } else if (user) {
        queryRef = this.queryByDateUser(queryStartDate, queryEndDate, user);
      } else {
        queryRef = this.queryByDate(queryStartDate, queryEndDate);
      }

      const querySnapshot = await getDocs(queryRef);
      return querySnapshot.docs.map((doc) => doc.data() as PolicyLogIndex);
    } catch (error) {
      console.error('Error loading PolicyLogIndexes:', error);
      throw error;
    }
  }

  /**
   * Queries the policy log index based on a given date range.
   * @param startDate - The start date of the range.
   * @param endDate - The end date of the range.
   * @returns An observable array of log indexes within the specified date range.
   */
  private queryByDate(
    startDate: Timestamp,
    endDate: Timestamp
  ): Query<DocumentData> {
    const indexCollectionRef = collection(this.dbModular, 'policyLogIndex');
    return query(
      indexCollectionRef,
      where('day', '>=', startDate),
      where('day', '<=', endDate)
    );
  }

  /**
   * Queries the policy log index based on a date range and event type.
   * @param startDate - The start date of the range.
   * @param endDate - The end date of the range.
   * @param type - The type of event to filter by.
   * @returns An observable array of log indexes matching the specified date range and event type.
   */
  private queryByDateType(
    startDate: Timestamp,
    endDate: Timestamp,
    type: EventType
  ): Query<DocumentData> {
    const indexCollectionRef = collection(this.dbModular, 'policyLogIndex');
    return query(
      indexCollectionRef,
      where('type', 'array-contains', type),
      where('day', '>=', startDate),
      where('day', '<=', endDate)
    );
  }

  /**
   * Queries the policy log index based on a date range and user ID.
   * @param startDate - The start date of the range.
   * @param endDate - The end date of the range.
   * @param uid - The user ID to filter by.
   * @returns An observable array of log indexes matching the specified date range and user ID.
   */
  private queryByDateUser(
    startDate: Timestamp,
    endDate: Timestamp,
    uid: string
  ): Query<DocumentData> {
    const indexCollectionRef = collection(this.dbModular, 'policyLogIndex');
    return query(
      indexCollectionRef,
      where('user', 'array-contains', uid),
      where('day', '>=', startDate),
      where('day', '<=', endDate)
    );
  }

  /**
   * Queries the policy log index based on a date range, event type, and user ID.
   * @param startDate - The start date of the range.
   * @param endDate - The end date of the range.
   * @param type - The type of event to filter by.
   * @param uid - The user ID to filter by.
   * @returns An observable array of log indexes matching the specified date range, event type, and user ID.
   */
  private queryByDateTypeUser(
    startDate: Timestamp,
    endDate: Timestamp,
    type: EventType,
    uid: string
  ): Query<DocumentData> {
    const indexCollectionRef = collection(this.dbModular, 'policyLogIndex');
    return query(
      indexCollectionRef,
      where('typeUser', 'array-contains', { type, uid }),
      where('day', '>=', startDate),
      where('day', '<=', endDate)
    );
  }

  /**
   * Compares two objects and identifies changes between them.
   * @param oldObj - The original object before changes.
   * @param newObj - The new object after changes.
   * @returns An array of Change objects representing the differences between the two objects.
   */
  compareNestedFields(oldObj: any, newObj: any): Change[] {
    const changes: Change[] = [];

    try {
      if (oldObj.planId !== newObj.planId) {
        changes.push({
          key: 'planId',
          before: oldObj.planId,
          after: newObj.planId,
        });
        changes.push({ key: 'status', after: PolicyStatus.INACTIVE });
        this.objectInfo = 'ALL ADD-ONS AND MEMBERS';
      } else {
        const combinedKeys = new Set([
          ...Object.keys(oldObj),
          ...Object.keys(newObj),
        ]);
        for (const key of combinedKeys) {
          if (newObj.hasOwnProperty(key) || oldObj.hasOwnProperty(key)) {
            if (
              key === 'updatedBy' ||
              key === 'updatedOn' ||
              key === 'requestReason' ||
              key === 'memberIdNumbers' ||
              key === 'previousStatus'
            ) {
              continue;
            }

            if (
              typeof newObj[key] === 'object' &&
              newObj[key] !== null &&
              !newObj[key].seconds
            ) {
              if (
                Array.isArray(newObj[key]) &&
                key !== 'productsPaymentStatus'
              ) {
                if (newObj[key].length !== oldObj[key].length) {
                  const difference =
                    newObj[key].length > oldObj[key].length
                      ? newObj[key].find(
                          (item: any) =>
                            !oldObj[key].some(
                              (oldItem: any) => oldItem.id === item.id
                            )
                        )
                      : oldObj[key].find(
                          (item: any) =>
                            !newObj[key].some(
                              (newItem: any) => newItem.id === item.id
                            )
                        );

                  if (difference) {
                    this.indexType =
                      newObj[key].length > oldObj[key].length
                        ? 'create'
                        : 'delete';
                    this.logType =
                      newObj[key].length > oldObj[key].length
                        ? this.searchService.overrideMemberId
                          ? 'OVERRIDE'
                          : 'CREATE'
                        : 'DELETE';
                    if (difference.addOnId) {
                      this.getNewOrDeletedAddOnLog(difference).forEach(
                        (change) => {
                          changes.push(change);
                        }
                      );
                    } else if (difference.memberTypeId) {
                      this.getNewOrDeletedMemberLog(difference).forEach(
                        (change) => {
                          changes.push(change);
                        }
                      );
                    } else if (difference.comment) {
                      this.getNewOrDeletedCommentLog(difference).forEach(
                        (change) => {
                          changes.push(change);
                        }
                      );
                    } else if (difference.url) {
                      this.getNewOrDeletedFileLog(difference).forEach(
                        (change) => {
                          changes.push(change);
                        }
                      );
                    }
                  }
                } else {
                  for (let i = 0; i < newObj[key].length; i++) {
                    const objectInfo = this.getObjectInfo(newObj[key][i]);
                    const nestedChanges = this.compareNestedFields(
                      oldObj[key][i],
                      newObj[key][i]
                    );
                    if (nestedChanges.length > 0 && changes.length === 0)
                      this.objectInfo = objectInfo;
                    changes.push(...nestedChanges);
                  }
                }
              } else {
                const objectInfo = this.getObjectInfo(newObj[key]);
                const nestedChanges = this.compareNestedFields(
                  oldObj[key],
                  newObj[key]
                );
                if (
                  nestedChanges.length > 0 &&
                  changes.length === 0 &&
                  objectInfo
                )
                  this.objectInfo = objectInfo;
                changes.push(...nestedChanges);
              }
            } else if (
              JSON.stringify(newObj[key]) !== JSON.stringify(oldObj[key])
            ) {
              if (
                key === 'inceptionDate' ||
                key === 'waitingDate' ||
                key === 'requestedWaitingDate' ||
                key === 'lastPeriodPaid'
              ) {
                const change: Change = { key: key };

                if (oldObj.hasOwnProperty(key)) {
                  change.before = this.dateTimeService.verifyTimestamp(
                    oldObj[key]
                  );
                }
                if (newObj.hasOwnProperty(key)) {
                  change.after = this.dateTimeService.verifyTimestamp(
                    newObj[key]
                  );
                }

                changes.push(change);
              } else if (!oldObj[key] || oldObj[key] === '') {
                changes.push({
                  key,
                  after: newObj[key],
                });
              } else if (!newObj[key] || newObj[key] === '') {
                changes.push({
                  key,
                  before: oldObj[key],
                });
              } else {
                changes.push({ key, before: oldObj[key], after: newObj[key] });
              }
            }
          }
        }
      }

      return changes;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR LOGGING UPDATE EVENT');
      throw err;
    }
  }

  /**
   * Generates a list of changes for a new debit order.
   * @param debitOrder - The debit order object to generate changes for.
   * @returns An array of Change objects representing the details of the new debit order.
   */
  getLogNewDebitOrderLog(debitOrder: DebitOrder) {
    const changes: Change[] = [];
    const fieldsToExclude = [
      'createdOn',
      'updatedOn',
      'createdBy',
      'updatedBy',
    ];

    (Object.keys(debitOrder) as Array<keyof DebitOrder>).forEach((key) => {
      if (fieldsToExclude.includes(key)) {
        return;
      }

      let value = debitOrder[key];
      if (value !== undefined) {
        if (typeof value === 'object' && 'seconds' in value) {
          value = this.dateTimeService.verifyTimestamp(value);
        }
        const change: Change = { key: key };
        if (this.indexType === 'create') {
          change.after = value;
        } else if (this.indexType === 'delete') {
          change.before = value;
        }
        changes.push(change);
      }
    });

    return changes;
  }

  /**
   * Generates a list of changes for a new or deleted member.
   * @param memberDetails - The details of the member, either added or deleted.
   * @returns An array of Change objects representing the addition or deletion of a member.
   */
  getNewOrDeletedMemberLog(memberDetails: Partial<Member>): Change[] {
    const changes: Change[] = [];
    const fieldsToExclude = [
      'id',
      'createdOn',
      'updatedOn',
      'createdBy',
      'updatedBy',
      'waitingDate',
    ];

    (Object.keys(memberDetails) as Array<keyof Member>).forEach((key) => {
      if (fieldsToExclude.includes(key)) {
        return;
      }

      let value = memberDetails[key];
      if (value !== undefined && value !== null) {
        if (key === 'address' && typeof value === 'object' && value !== null) {
          Object.entries(value).forEach(([addressKey, addressValue]) => {
            if (addressValue !== undefined) {
              const change: Change = { key: addressKey };
              if (this.indexType === 'create') {
                change.after = addressValue;
              } else if (this.indexType === 'delete') {
                change.before = addressValue;
              }
              changes.push(change);
            }
          });
        } else {
          if (
            typeof value === 'object' &&
            value !== null &&
            'seconds' in value
          ) {
            value = this.dateTimeService.verifyTimestamp(value);
          }
          const change: Change = { key };
          if (this.indexType === 'create') {
            change.after = value;
          } else if (this.indexType === 'delete') {
            change.before = value;
          }
          changes.push(change);
        }
      }
    });

    return changes;
  }

  /**
   * Generates a list of changes for a new or deleted add-on.
   * @param difference - The add-on details that have been added or deleted.
   * @returns An array of Change objects representing the addition or deletion of an add-on.
   */
  getNewOrDeletedAddOnLog(difference: any) {
    const changes: Change[] = [];
    const addOn: PolicyAddOn = {
      addOnId: difference.addOnId,
      inceptionDate: this.dateTimeService.verifyTimestamp(
        difference.inceptionDate
      ),
      status: difference.status,
    };

    (Object.keys(addOn) as Array<keyof PolicyAddOn>).forEach((key) => {
      const change: Change = { key: key };
      if (this.indexType === 'create') {
        change.after = addOn[key];
      } else if (this.indexType === 'delete') {
        change.before = addOn[key];
      }
      changes.push(change);
    });

    return changes;
  }

  /**
   * Generates a list of changes for a new or deleted comment.
   * @param difference - The comment details that have been added or deleted.
   * @returns An array of Change objects representing the addition or deletion of a comment.
   */
  getNewOrDeletedCommentLog(difference: any) {
    const changes: Change[] = [];

    if (this.indexType === 'create') {
      changes.push({ after: difference.comment, key: 'comment' });
    } else if (this.indexType === 'delete') {
      changes.push({ before: difference.comment, key: 'comment' });
    }

    return changes;
  }

  /**
   * Generates changes for a new or deleted file log.
   * @param difference - The data representing the new or deleted file.
   * @returns An array of Change objects for the file change log.
   */
  getNewOrDeletedFileLog(difference: any) {
    const changes: Change[] = [];
    const fileData: Partial<FileData> = {
      name: difference.name,
      customName: difference.customName,
    };

    (Object.keys(fileData) as Array<keyof FileData>).forEach((key) => {
      const change: Change = { key: key };
      if (this.indexType === 'create') {
        change.after = fileData[key];
      } else if (this.indexType === 'delete') {
        change.before = fileData[key];
      }
      changes.push(change);
    });

    return changes;
  }

  /**
   * Checks if a given policy event matches a specified filter value.
   * @param item - The policy event to check.
   * @param filterValue - The filter value to compare against.
   * @returns True if the policy event matches the filter value, false otherwise.
   */
  private doesLogMatchFilter(item: PolicyEvent, filterValue: string): boolean {
    filterValue = filterValue.toUpperCase();

    const referenceNumberMatches =
      item.referenceNumber?.toUpperCase().includes(filterValue) ?? false;
    const createdByMatches =
      item.createdBy?.displayName?.toUpperCase().includes(filterValue) ?? false;
    const objectMatches =
      this.formatObjectName(item.object ?? '').includes(filterValue) ?? false;
    const typeMatches = item.type?.toUpperCase().includes(filterValue) ?? false;

    if (
      referenceNumberMatches ||
      createdByMatches ||
      objectMatches ||
      typeMatches
    ) {
      return true;
    }

    if (item.changes && item.changes.length > 0) {
      for (const change of item.changes) {
        const keyMatches =
          this.prettifyFieldName(change.key ?? '', item.objectInfo).includes(
            filterValue
          ) ?? false;
        const beforeMatches =
          this.formatTableValue(change.key ?? '', change.before)
            ?.toUpperCase()
            .includes(filterValue) ?? false;
        const afterMatches =
          this.formatTableValue(change.key ?? '', change.after)
            ?.toUpperCase()
            .includes(filterValue) ?? false;

        if (keyMatches || beforeMatches || afterMatches) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Refreshes the data source for policy logs, sorting them by creation date in descending order.
   * If two logs have the same creation date, the log appearing later in the original list is treated as newer.
   */
  public refreshAllPolicyLogs() {
    if (
      this.selectedPolicyLog?.events &&
      this.selectedPolicyLog?.referenceNumbers
    ) {
      const combinedArray = this.selectedPolicyLog!.events.map(
        (eventObj, index) => ({
          ...eventObj,
          referenceNumber:
            this.selectedPolicyLog!.referenceNumbers![index] || '',
          originalIndex: index,
        })
      );

      combinedArray.sort((a, b) => {
        const aCreatedOn = a.createdOn?.toDate().getTime() || 0;
        const bCreatedOn = b.createdOn?.toDate().getTime() || 0;
        if (bCreatedOn === aCreatedOn) {
          return b.originalIndex - a.originalIndex;
        }
        return bCreatedOn - aCreatedOn;
      });

      this.allPolicyLogs = combinedArray;

      this.applyFilter();
    }
  }

  isEventTypeEqual(eventType1: EventType, eventType2: EventType): boolean {
    const objectMatch = eventType1.object === eventType2.object;
    const typeMatch = eventType1.type === eventType2.type;
    const keyMatch =
      eventType1.key === eventType2.key || (!eventType1.key && !eventType2.key);

    return objectMatch && typeMatch && keyMatch;
  }

  /**
   * Updates the data source for the current policy logs.
   * @param events - An array of PolicyEvent objects to update the data source with.
   */
  updateCurrentPolicyLogs(events: PolicyEvent[]) {
    if (!this.dataSourcePolicyLogs) {
      this.dataSourcePolicyLogs = new MatTableDataSource<Log>(events);
    } else {
      this.dataSourcePolicyLogs.data = events;
      this.dataSourcePolicyLogs._updateChangeSubscription();
    }
  }

  /**
   * Applies a text filter to the policy logs.
   * @param filterValue - The text value to filter the policy logs by.
   */
  applyFilter(filterValue?: string): void {
    if (filterValue !== undefined)
      this.searchText = filterValue.trim().toUpperCase();

    this.filteredPolicyLogs =
      this.allPolicyLogs?.filter((item) =>
        this.doesLogMatchFilter(item, this.searchText)
      ) ?? [];
  }

  /**
   * Retrieves object information based on the provided object.
   * @param object - The object to retrieve information from.
   * @returns A string representing the object information.
   */
  getObjectInfo(object: any): string {
    if (object.memberTypeId) {
      return `${object.firstName} ${object.lastName}`;
    } else if (object.addOnId) {
      return `${this.addOnService.getAddOnById(object.addOnId)?.name}`;
    } else if (object.url) {
      return `${object.name}`;
    } else if (object.lastPeriodPaid) {
      const plan = this.planService.allPlans.find(
        (plan) => plan.id === object.id
      );
      const addOn = this.addOnService.allAddOns.find(
        (addOn) => addOn.id === object.id
      );
      return `${plan?.name ?? addOn?.name ?? ''}`;
    }

    return '';
  }

  /**
   * Formats the object name for display purposes.
   * @param value - The object name to format.
   * @returns The formatted object name.
   */
  formatObjectName(value: string) {
    if (value === 'addOn') {
      return 'ADD-ON';
    }

    return this.prettifyFieldName(value);
  }

  /**
   * Formats the value of a table cell based on the given key and value.
   * @param key - The key associated with the value.
   * @param value - The value to be formatted.
   * @returns The formatted value as a string.
   */
  formatTableValue(key: string, value: any): string {
    if (key === undefined || value === undefined) return '-';

    try {
      switch (key) {
        case 'planId':
          return this.planService.getPlanById(value)?.name ?? '';
        case 'memberTypeId':
          return this.planService.getMemberTypeById(value).name ?? '';
        case 'addOnId':
          return this.addOnService.getAddOnById(value)?.name ?? '';
        case 'accountType':
          return BankAccountTypes[value - 1];
        case 'changeImmediately':
          return value ? 'YES' : 'NO';
        case 'lastPeriodPaid':
          return this.dateTimeService.formatDate(value, false);
        default: {
          if (typeof value === 'object' && 'seconds' in value) {
            return this.dateTimeService.secondsToDateFormat(value.seconds);
          } else if (typeof value === 'object') {
            return JSON.stringify(value);
          }
          return String(value);
        }
      }
    } catch (error) {
      console.error(`Error in formatTableValue for key ${key}:`, error);
      return '-';
    }
  }

  /**
   * Converts a field name to a prettier, human-readable format.
   * Excludes objectInfo for specified field names.
   * @param fieldName - The field name to prettify.
   * @param objectInfo - Optional additional information related to the object.
   * @returns The prettified field name.
   */
  prettifyFieldName(fieldName: string, objectInfo?: string): string {
    const fieldMapping: { [key: string]: string } = {
      idNumber: 'ID Number',
      inceptionDate: 'Inception Date',
      planId: 'Plan',
      addOnId: 'Add-On',
      memberTypeId: 'Member Type',
      customName: 'File Title',
    };

    // Fields to exclude objectInfo
    const excludeObjectInfoFields = ['planId'];

    if (fieldMapping.hasOwnProperty(fieldName)) {
      // Check if fieldName is in the list to exclude objectInfo
      if (excludeObjectInfoFields.includes(fieldName)) {
        return fieldMapping[fieldName].toUpperCase();
      }
      return (
        fieldMapping[fieldName].toUpperCase() +
        (objectInfo ? ` OF ${objectInfo}` : '')
      );
    }

    const prettierName = fieldName.replace(/([a-z])([A-Z])/g, '$1 $2');
    // Check if fieldName is in the list to exclude objectInfo before appending
    if (excludeObjectInfoFields.includes(fieldName)) {
      return (
        prettierName.charAt(0).toUpperCase() +
        prettierName.slice(1).toUpperCase()
      );
    }
    return (
      (
        prettierName.charAt(0).toUpperCase() + prettierName.slice(1)
      ).toUpperCase() + (objectInfo ? ` OF ${objectInfo}` : '')
    );
  }

  /**
   * Cleans up resources when the service is destroyed.
   */
  cleanUp() {
    this.destroy$.next();
  }
}
